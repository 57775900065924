import { useEffect, useState } from 'react';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { useTranslation } from '../../../hooks/useTranslation';

import CustomMessageForm from './CustomMessageForm';
import PrepareQuestionFormSummary from './PrepareQuestionFormSummary';
import QuestionFormSuppliers from './QuestionFormSuppliers';

const PrepareQuestionForm = () => {
	const { t } = useTranslation();
	const [screen, setScreen] = useState(0);

	const { setBreadcrumbs } = useApp();

	useEffect(() => {
		setBreadcrumbs([
			{
				text: t('Mesurer la maturité fournisseurs'),
				link: `${FRONT_PATH_NAMES.questionForms}/general`,
			},
			{
				text: t('Préparer un envoi'),
			},
		]);
	}, [t, setBreadcrumbs]);

	if (screen === 1) return <QuestionFormSuppliers setScreen={setScreen} />;
	if (screen === 2) return <CustomMessageForm setScreen={setScreen} />;
	return <PrepareQuestionFormSummary setScreen={setScreen} />;
};

export default PrepareQuestionForm;
