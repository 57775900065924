import { useCallback, useEffect, useState } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { SimulationFormInput } from '@carbonmaps/shared/validations/simulation.validations';
import { getProductOptionsAutocompleteDataAction } from '@carbonmaps/ui/actions/product.actions';
import {
	createSimulationAction,
	getProductSimulationDataAction,
	resetScenarioAction,
} from '@carbonmaps/ui/actions/simulation.actions';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { useRowTableSelection } from '@carbonmaps/ui/hooks/useRowTableSelection';

import Container from '../../../components/layout/list/Container';
import CreateScenarioModal from '../../../components/simulation/CreateScenarioModal';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { CARBON_INDICATOR } from '../../../utils/constants';

import EcoConceptionFilter from './EcoConceptionFilter';
import EcoConceptionHeader from './EcoConceptionHeader';
import EcoConceptionTable from './EcoConceptionTable';

const EcoConception = (/* props: Props */) => {
	const [searchQueryParams] = useSearchQueryParams();
	const [openModal, setOpenModal] = useState(false);
	const [isNew, setIsNew] = useState(true);
	const [options, setOptions] = useState([]);
	const [message, setMessage] = useState('');
	const [search, setSearch] = useState<any>();
	const { indicator, setIndicator, setBreadcrumbs } = useApp();
	const [paramsTable, setTableParams] = useState<any>(searchQueryParams);
	const [selectedOption, setSelectedOption] = useState({}) as any;
	const [selectedSimulation, setSelectedSimulation] = useState({}) as any;

	const navigate = useNavigate();

	const analytics = useJuneTrack();
	const trackEvent = useCallback(
		(name: string, options?: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Simulate' },
			});
		},
		[analytics],
	);

	// translation
	const { t } = useTranslation();

	const queryClient = useQueryClient();
	// ---- breadCrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([{ text: t('Éco-conception'), isBeta: false }]);

		trackEvent('Simulate Eco Design Land Scenario List');

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);

	// ---- invalidateQueries getProductSimulationDataAction at first  ---- //
	useEffect(() => {
		return () => {
			queryClient.invalidateQueries({ queryKey: ['getProductSimulationDataAction'] });
		};
	}, []);

	// ---- init switch mode with carbon ---- //
	useEffect(() => {
		if (indicator) return;
		setIndicator && setIndicator(CARBON_INDICATOR);
	}, [setIndicator, indicator]);

	const { selectedYear } = useYearSelection();

	//----- fetch data for autocomplete ----//
	const {
		data: dataOptions,
		isLoading: isLoadingOption,
		isSuccess: isSuccessOptions,
	} = useQuery({
		queryKey: [
			'getOptionsAutocompleteData',
			{
				...paramsTable,
				input: search?.input ?? '',
				period: selectedYear,
			},
		] as any,
		queryFn: getProductOptionsAutocompleteDataAction,
	});

	// ---- fetch data simulation to put in autocomplete---- //

	const {
		data: dataSimulation,
		isLoading: isLoadingDataOption,
		isSuccess: isSuccessOptionsSimulation,
	} = useQuery({
		queryKey: [
			'getProductSimulationDataAction',
			{
				...paramsTable,
				input: search?.input ?? '',
				viewMode: indicator,
			},
		],
		queryFn: getProductSimulationDataAction,
	} as any);

	useEffect(() => {
		const options: any = [];

		if (isSuccessOptions && dataOptions?.length) {
			options.push(...dataOptions);
		}

		if (isSuccessOptionsSimulation && (dataSimulation as any)?.data) {
			options.push(...(dataSimulation as any).data);
		}

		const optionsUniq = _.uniqBy(options, 'uid');
		setOptions(optionsUniq as any);
	}, [
		isSuccessOptions,
		isSuccessOptionsSimulation,
		dataOptions,
		dataSimulation,
		search,
		setSelectedOption,
		selectedOption,
	]);

	const {
		mutate: createSimulation,
		isError,
		isSuccess,
		isLoading: isLoadingCreateSimulation,
	} = useMutation({
		mutationKey: ['createSimulation'],
		mutationFn: createSimulationAction,
		onSuccess: async (value: any) => {
			// setMessage('Informations mises à jour');
			//	if (isNew) resetScenario({ simulationId: value.objectId });
			queryClient.invalidateQueries({ queryKey: ['getProductSimulationDataAction'] });
			setOpenModal(false);
			navigate(`${FRONT_PATH_NAMES.simulation}/${value.objectId}`);
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const onSubmit: SubmitHandler<SimulationFormInput> = async (values) => {
		createSimulation({
			...values,
			productId: selectedOption?.objectId,
			selectedSimulationId: selectedSimulation?.objectId ?? null,
		} as any);
	};

	return (
		<Container
			header={<EcoConceptionHeader />}
			actionFilter={
				<EcoConceptionFilter
					selectedOption={selectedOption}
					setSearch={setSearch}
					options={options}
					isLoadingDataOption={isLoadingDataOption}
					setOpenModal={setOpenModal}
					setSelectedOption={setSelectedOption}
					setIsNew={setIsNew}
				/>
			}
			seoText={`${t('Éco-conception')} - Carbon Maps`}
		>
			<EcoConceptionTable
				setIsNew={setIsNew}
				setOpenModal={setOpenModal}
				setSelectedOption={setSelectedOption}
				setSelectedSimulation={setSelectedSimulation}
			/>

			<CreateScenarioModal
				title={isNew === true ? t('create-scenario-form-title') : t('duplicate-scenario-form-title')}
				open={openModal}
				onClose={() => {
					return setOpenModal(false);
				}}
				isLoading={isLoadingCreateSimulation}
				isLoadingOption={isLoadingOption}
				onSubmit={onSubmit}
				options={options}
				selectedOption={selectedOption}
				setSelectedOption={setSelectedOption}
				message={message}
				isError={isError}
				setSearch={setSearch}
				// isSuccess={isSuccess}
			/>
		</Container>
	);
};

export default EcoConception;
