import { z } from 'zod';
import { DEFAULT_CUSTOM_MESSAGE_OPTIONS } from '../../../apps/front/src/utils/constants';


const noForbiddenWordsValidator = (value: string, lang: string) => {
	// Vérifie si la chaîne contient des mots interdits
	for (const word of DEFAULT_CUSTOM_MESSAGE_OPTIONS[lang].forbiddenWords) {
		if (value.includes(word)) {
			return `Remplacer '${word}' par la valeur correspondante`;
		}
	}
	return true; // Indique que la validation est réussie
};

const MessageSchema = {
	fr: z.string()
		.min(1, 'Message cannot be empty')
		.refine(value => noForbiddenWordsValidator(value, 'fr') === true, {
			message: 'Remplacer les mots entre [...] par une valeur correspondante',
		}),
	en: z.string()
		.min(1, 'Message cannot be empty')
		.refine(value => noForbiddenWordsValidator(value, 'en') === true, {
			message: 'Remplacer les mots entre [...] par une valeur correspondante',
		}),

	it: z.string()
		.min(1, 'Message cannot be empty')
		.refine(value => noForbiddenWordsValidator(value, 'it') === true, {
			message: 'Remplacer les mots entre [...] par une valeur correspondante',
		}),
	es: z.string()
		.min(1, 'Message cannot be empty')
		.refine(value => noForbiddenWordsValidator(value, 'es') === true, {
			message: 'Remplacer les mots entre [...] par une valeur correspondante',
		}),
}


const MessagesSchema = z.object({
	fr: MessageSchema.fr,
	en: MessageSchema.en,
	it: MessageSchema.it,
	es: MessageSchema.es,
});

export const CustomMessageValidationSchema = z.object({
	messages: MessagesSchema,
});

export type CustomMessageFormInput = z.infer<typeof CustomMessageValidationSchema>;
