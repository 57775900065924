import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useTheme } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Building2, Moon, Trash2 } from 'lucide-react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
// import { GetUsersParams, getUsers } from '@carbonmaps/ui/actions/getUsers';
import {
	deleteSelectedRowAction,
	sendEmailSelectedRowAction,
	updateEmailSelectedRowAction,
	updateRoleSelectedRowAction,
} from '@carbonmaps/ui/actions/userRow';
import EditableEmailInput from '@carbonmaps/ui/components/saas/Table/EditableEmailInput';
import RoleCellNew from '@carbonmaps/ui/components/saas/Table/RoleCellNew';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import { updateSupplierUserAction } from '@carbonmaps/ui/actions/user.actions';
import { css, cx } from '@emotion/css';
import { useToggle } from 'react-use';
import FlashMessage from '../../../components/bo/FlashMessage';
import LoadingDialog from '../../../components/dialogs/LoadingDialog';
import TableHeaderCell from '../../../components/table/TableHeaderCell';
import AlertStatic from '../../../components/Toast/AlertStatic';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { useTranslation } from '../../../hooks/useTranslation';
import { useLoginAsMutation } from '../../../lib/react-query/features/auth/auth.hooks';
import { useFindSuppliersListOptions } from '../../../lib/react-query/features/supplier/supplier.hooks';
import { FindUsersActionParams } from '../../../lib/react-query/features/user/user.actions';
import { useFindUsers } from '../../../lib/react-query/features/user/user.hooks';

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
			// width: '100%'
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			// background: theme.palette.common.white,
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
	};
};

type DataCell = { value: Record<string, any>; row: Record<string, any>; callback: (...params: any[]) => void };

type TableProps = {
	filterOptions?: any;
	manualSortBy?: boolean;
	resetPage?: number;
	onSelectedRow?: any; // use to update row selected in export data
	isOpenRowAdd?: boolean;
	isCompanyArchived?: boolean;
};

const UsersTable = ({ filterOptions, manualSortBy, onSelectedRow, isOpenRowAdd, isCompanyArchived }: TableProps) => {
	// const { syncUserState } = useAuth();
	const queryParams = useQueryParams();
	const companyId = queryParams?.companyId ? queryParams?.companyId[0] : '';

	// translation
	const { t, i18n } = useTranslation();
	const languageCode = getIsoCountryCode(i18n.language);
	const navigate = useNavigate();
	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState<FindUsersActionParams>({
		input: '',
		page: 1,
		limit: 100,
		companyId,
		...filterOptions,
	});

	const [message, setMessage] = useState('');

	const queryClient = useQueryClient();
	// state to edit an company
	const [userToEdit, setUserToEdit] = useState({});
	// state to toggle to edit user supplier
	const [isDialogOpen, toggleDialog] = useToggle(false);
	const [openAlert, setOpenAlert] = useToggle(false);
	// theme
	const theme = useTheme();
	// styles
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	// --- ref for fetch data table
	const fetchIdRef = useRef(0);

	// ---- fetch data --- //
	const {
		result: { data: tableResultData, isLoading: isTableLoading },
		key: findUsersQueryKey,
	} = useFindUsers({
		params: { ...paramsTable, companyId },
	});

	// for list supplier
	const {
		result: { data: dataSuppliers, isLoading },
	} = useFindSuppliersListOptions({
		paramsTable: {
			page: 1,
			size: 10_000,
			companyId,
		},
	});

	// state to force to first page
	const [resetPage, setResetPage] = useState(0);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
				pageSize: paramsTable.size,
				pageIndex: (paramsTable.size ?? 1) - 1,
			});
		},
		[paramsTable, updateTableParams],
	);

	// const location = useLocation();

	// ---- mutation login as ---- //
	const {
		result: { mutate: logInAs },
	} = useLoginAsMutation({
		options: {
			onSuccess(data, variables, context) {
				navigate(FRONT_PATH_NAMES.home /* , { replace: true, state: { from: location } } */);
			},
		},
	});

	useEffect(() => {
		setTableParams((prev: any) => {
			return { ...prev, ...filterOptions };
		});
		setResetPage((prev) => {
			return prev + 1;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterOptions.input]);

	// mutate action
	const confirmDelete = async (id: any) => {
		const key = '_User';

		if (key && id) {
			await deleteSelectedRow({ ids: [id], companyId } as any);
		}
	};

	const {
		mutate: deleteSelectedRow,
		isLoading: isLoadingDelete,
		// isError,
		// isSuccess,
	} = useMutation({
		mutationKey: ['deleteSelectedRow'],
		mutationFn: deleteSelectedRowAction,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [findUsersQueryKey[0]] });
			setMessage('');
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const {
		mutate: updateRoleSelectedRow,
		isLoading: isLoadingUpdateRole,
		// isError,
		isSuccess,
	} = useMutation({
		mutationKey: ['updateRoleSelectedRow'],
		mutationFn: updateRoleSelectedRowAction,
		onSuccess: () => {
			return queryClient.invalidateQueries({ queryKey: [findUsersQueryKey[0]] });
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const updateRole = async (ev: any) => {
		const { key, user } = ev.currentTarget.dataset;

		if (key && user) {
			await updateRoleSelectedRow({ ids: [user], roleCode: key, companyId });
		}
	};

	const {
		mutate: updateEmailSelectedRow,
		isLoading: isLoadingEmail,
		// isError,
		isSuccess: isSuccessEmail,
	} = useMutation({
		mutationKey: ['updateEmailSelectedRow'],
		mutationFn: updateEmailSelectedRowAction,
		onSuccess: () => {
			return queryClient.invalidateQueries({ queryKey: [findUsersQueryKey[0]] });
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const {
		mutate: sendEmailSelectedRow,
		isLoading: isLoadingSendEmail,
		// isError,
		//	isSuccess,
	} = useMutation({
		mutationKey: ['sendEmailSelectedRow'],
		mutationFn: sendEmailSelectedRowAction,
		onSuccess: () => {
			return queryClient.invalidateQueries({ queryKey: ['users'] });
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const updateEmail = async (values: any) => {
		const { currentEmail, email, type } = values;

		switch (type) {
			case 'sendEmailNewAccount':
				return await sendEmailSelectedRow({ email: currentEmail, type, languageCode });
			case 'sendEmailNewEmail':
				return await sendEmailSelectedRow({ email: currentEmail, newEmail: email, type, languageCode });
			case 'changeEmail':
				return await updateEmailSelectedRow({ email: currentEmail, newEmail: email, type, languageCode });
		}

		return;
	};

	const [searchParams] = useSearchParams();

	const {
		mutate: updateSupplierUser,
		isError,
		// isSuccess,
		isLoading: isLoadingUpdateSupplier,
	} = useMutation({
		mutationKey: ['updateSupplierUser'],
		mutationFn: updateSupplierUserAction,
		onSuccess: async () => {
			setMessage('Informations mises à jour');
			queryClient.invalidateQueries({ queryKey: ['findUserSuppliers'] });
			// toggle();
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const handleClickEdit = useCallback((values: any) => {
		updateSupplierUser(values);
	}, []);

	// ---- columns table definition ---- //
	const tableColumns = useMemo(() => {
		return [
			// {
			// 	Header: (props: any) => {
			// 		return <TableHeaderCell valueType="string" column={props.column} label="Organisation" />;
			// 	},
			// 	accessor: 'companyName',
			// 	editable: true,
			// 	sortDescFirst: true,
			// 	component: 'TagLabel',
			// 	type: 'string',
			// 	icon: <Building2 color={theme.palette.grey[700]} />,
			// },
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('input-mail-label')} />;
				},
				accessor: 'email',
				editable: true,
				sortDescFirst: true,
				Cell: ({ value, row, callback }: DataCell) => {
					return <EditableEmailInput value={value} callback={callback} />;
				},
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('last-name-label')} />;
				},
				accessor: 'fullName',
				editable: false,
				component: 'SimpleCell',
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('role-label')} />;
				},

				accessor: 'role',
				editable: true,
				sortDescFirst: true,
				Cell: ({ value, row, callback }: DataCell) => {
					return <RoleCellNew row={row} value={value} callback={callback} />;
				},
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('supplier-label')} />;
				},

				accessor: 'id',
				editable: true,
				sortDescFirst: true,
				component: 'EditSupplierUser',
				props: {
					t,
					supplierOptions: dataSuppliers,
					updateMyData: handleClickEdit,
					companyId,
					isLoading: isLoadingUpdateSupplier,
				},
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('actions-column')} />;
				},
				accessor: 'objectId',
				editable: true,
				component: 'Action',
				type: 'action',
				icon: <Trash2 color={theme.palette.grey[700]} />,
				withConfirm: true,
				callback: (value: any) => {
					confirmDelete(value);
				},
				callbackLoginAs: (value: any) => {
					if (isCompanyArchived) {
						setOpenAlert();
						return;
					}
					logInAs({ userId: value || '', companyId: searchParams.get('companyId') || '' });
				},
				disableSortBy: true,
			},
		];
	}, [theme, dataSuppliers]);
	// ---- skeleton ---- //
	const { skeleton } = useSkeleton({ condition: isTableLoading || isLoadingEmail || isLoadingSendEmail });

	return (
		<>
			<div>
				<FlashMessage open={openAlert} onClose={setOpenAlert} icon={<Moon />} message={t('disabled-message-company')} />
			</div>
			<TableComponent
				tableName={'user'}
				skeleton={skeleton}
				loading={false}
				fetchData={updateTableParams}
				pageCount={tableResultData?.meta?.last_page || 0}
				noDataMessage={t('empty-data-users-table')}
				pageSize={paramsTable.size}
				columns={tableColumns}
				resetPage={resetPage}
				data={(tableResultData?.users || []) as any}
				setSelectedRow={onSelectedRow}
				onSortingColumn={() => {
					return 1;
				}}
				addStyles={stylesTable}
				manualSortBy={manualSortBy}
				updateRole={updateRole}
				updateEmail={updateEmail}
				// isOpenRowAdd={isOpenRowAdd}
				confirmDelete={confirmDelete}
			/>
			<LoadingDialog open={isLoadingDelete} content={`${t('Suppression en cours')}...`} />

			{message ? <AlertStatic icon={undefined} type={'error'} text={message} /> : null}
		</>
	);
};

export default UsersTable;
