import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';
import { css, cx } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';

import Diff from '../../../../../apps/front/src/components/sales/Diff';
import Difference from '../../../../../apps/front/src/containers/scenario/Difference';
import { translation } from '../../../i18n/translation';

import Lining from '../../../../../apps/front/src/components/sales/Lining';
import Unit from '../../../../../apps/front/src/components/units/Unit';
import { cn, siteColors } from '../../../../../apps/front/src/lib/colors';
import { useApp } from '../../../hooks/useApp';
import BPopover from '../BPopover';

type Props = {
	value: any;
	row?: any;
	column: any;
	updateMyData?: (...params: any[]) => void;
};

const styles = {
	content: css({
		gap: 8,
		color: siteColors.text,
		fontSize: 14,
		fontFamily: 'Inter',
	}),
	innerContent: css({
		width: 16,
		height: 16,
		borderRadius: 16,
		background: 'var(--background-round)',
	}),
	popoverContainer: css({
		padding: 24,
	}),
	popoverContent: css({
		'& p': {
			color: siteColors.text,
			fontSize: 14,
			fontFamily: 'Inter',
			lineHeight: '25px',
			margin: 0,
		},
	}),
	iconContainer: css({
		gap: 8,
		// display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		width: 40,
	}),
	width10: css({
		width: 10,
	}),
	width20: css({
		width: 20,
	}),
	nowrap: css({
		whiteSpace: 'nowrap',
	}),
	intensityContent: css({
		alignContent: 'center',
		alignItems: 'center',
	}),
	diffContent: css({
		gap: '8px',
	}),
	fitContent: css({
		width: 'fit-content',
	}),
};

const VsMarket = ({
	value,
	row,
	column,
	updateMyData, // This is a custom function that we supplied to our table instance
}: Props) => {
	const t = column?.props?.t || translation;
	const { indicator } = useApp();

	if (row?.original?.isFromQuote && column.props?.page === 'report-client') {
		return <Lining />;
	}

	if (column?.props?.showPopover) {
		return (
			<div className="flexRow width100 justifyCenter">
				<BPopover
					anchorOriginHorizontal={'right'}
					transformOriginVertical={'top'}
					transformOriginHorizontal={'right'}
					anchorOriginVertical={'bottom'}
					modeHover
					content={(onClose) => {
						if (column?.props?.page === 'view-modelization') {
							return (
								<div className={styles.popoverContainer}>
									<div className={styles.popoverContent}>
										<p className="fontWeight700">{t('Differentiel')}</p>
										<div className="flexRow alignCenter gap8">
											<Diff
												withEquals={column?.props?.withEquals}
												showZero={true}
												noPopover={true}
												data={row?.original}
												value={value}
												indicator={indicator}
											/>
											<div className={cx('fontWeight400 fontSize16 alignCenter', styles.intensityContent)}>
												{formatNumber(row?.original?.VSReference, '', 2)}{' '}
												<Unit measure={(indicator + 'IntensityKg') as any} />
											</div>
										</div>
										<p className="fontWeight700"> {t('Source externe')} </p>
										<p>{row?.original?.bddRefPortfolio}</p>
									</div>
								</div>
							);
						}
						return (
							<div className={styles.popoverContainer}>
								<div className={styles.popoverContent}>
									<p className="fontWeight700">{t('reference')}</p>
									<p>{t(row?.original.labelPortfolioCmaps)}</p>
									<p className="fontWeight700">Source </p>
									<p>{row?.original.bddRefPortfolio}</p>
								</div>
							</div>
						);
					}}
				>
					<Diff
						withEquals={column?.props?.withEquals}
						withPercent={column?.props?.withPercent}
						data={row?.original}
						value={value}
						indicator={indicator}
					/>
				</BPopover>
			</div>
		);
	}
	return (
		<Diff
			withPercent={column?.props?.withPercent}
			noPopover={true}
			data={row?.original}
			value={value}
			indicator={indicator}
		/>
	);
};

export default VsMarket;
