import { ReactNode } from 'react';

import { css, cx } from '@emotion/css';
import { styled, type CSSObject, type Theme } from '@mui/material';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import useTheme from '@mui/material/styles/useTheme';
import type { SxProps } from '@mui/system';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Link } from 'react-router-dom';

import { MOD_DASHBOARD } from '@carbonmaps/shared/utils/module';
import BPopover from '@carbonmaps/ui/components/saas/BPopover';

import { useTranslation } from '../hooks/useTranslation';
import { SIDEBAR_WIDTH } from '../utils/constants';

import { cn, siteColors } from '../lib/colors';
import LightTooltip from './LightTooltip';
import Lock from './Lock';
import Popover from './Popover';

type Props = {
	header: ReactNode;
	content: ReactNode;
	footer: ReactNode;
	open: boolean;
};

const sideBarNavStyles = {
	drawerContainer: css({
		width: SIDEBAR_WIDTH,
		'& > .MuiPaper-root': {
			width: SIDEBAR_WIDTH,
			borderRight: cn('1px solid', siteColors.grey400),
			// backgroundColor: siteColors.grey100,
			display: 'flex',
			flexDirection: 'column',
			overflowX: 'hidden',
		},
		'& .MuiDrawer-paper': { boxSizing: 'border-box', width: SIDEBAR_WIDTH },
	}),

	content: css({
		width: '6px',
		height: '100%',
		zIndex: 2,
	}),
};

const openedMixin = (theme: Theme): CSSObject => {
	return {
		width: SIDEBAR_WIDTH,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflowX: 'hidden',
	};
};

const closedMixin = (theme: Theme): CSSObject => {
	return {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: 0,
		[theme.breakpoints.up('sm')]: {
			width: 0,
		},
	};
};

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => {
		return prop !== 'open';
	},
})(({ theme, open }) => {
	return {
		width: SIDEBAR_WIDTH,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	};
});

const SidebarNav = ({ header, content, footer, open }: Props) => {
	return (
		<Drawer variant="permanent" open={open} className={sideBarNavStyles.drawerContainer}>
			{/* <div className={cx('fixed bgMain', sideBarNavStyles.content)} /> */}

			{/* Header */}
			<Box>{header}</Box>

			{/* main content */}
			<Box flexGrow={1}>{content}</Box>

			{/* Footer */}
			<Box>{footer}</Box>
		</Drawer>
	);
};

type SidebarNavListProps = {
	children: ReactNode;
	title: string;
};

const sidebarNavListStyles = {
	container: css({
		marginBottom: 4,
	}),
	subheaderContainer: css({
		fontWeight: 600,
		textTransform: 'uppercase !important',
		paddingLeft: '35px !important',
		lineHeight: '2rem !important',
		fontFamily: 'Inter, Helvetica, Arial,sans-serif !important',
		fontSize: '12px !importanta',
	} as any),
};

export const SidebarNavList = ({ children, title }: SidebarNavListProps) => {
	return (
		<List
			className={sidebarNavListStyles.container}
			subheader={
				<ListSubheader
					component="div"
					disableSticky
					className={cx('mainColor', sidebarNavListStyles.subheaderContainer)}
				>
					{title}
				</ListSubheader>
			}
		>
			{children}
		</List>
	);
};

type ItemButtonProps = {
	icon: ReactNode;
	text: string;
	active?: boolean;
	link?: string;
	external?: boolean;
	comingSoon?: boolean;
	setFillPathOnHover?: boolean;
	onClick?: () => void;
	collapsible?: boolean;
	open: boolean;
	isEmpty?: boolean;
	isContributor?: boolean;
	code?: string;
};

const itemButtonStyles = {
	item: css({
		// paddingLeft: '35px !important',
		// paddingRight: '8px !important',
		// padding: '10px 32px !important',
		padding: '8px 16px 8px 16px !important',
		borderRadius: '.5rem !important',
		transition: 'unset !important',
		marginBottom: '4px !important',
		fontSize: '14px !important',
		marginLeft: '8px !important',
		marginRight: '8px !important',
	}),

	comingSoon: css({
		pointerEvents: 'none',
		color: siteColors.grey700,
	}),

	isEmpty: css({
		// pointerEvents: 'none',
		color: siteColors.grey700,
		// pointer: 'cursor',
		cursor: 'not-allowed!important',
	}),

	itemTextContainer: css({
		marginLeft: '8px !important',
		marginTop: '0 !important',
		marginBottom: '0 !important',
	}),

	comingSoonTextContainer: css({
		padding: '0px 8px',
		alignItems: 'center',
		borderRadius: '8px',
		border: '1px solid rgba(0, 0, 0, 0.10)',
		height: '22px',
		fontSize: '12px',
		fontFamily: 'Inter',
	}),

	popoverContainer: css({
		width: 220,
		marginLeft: 20,
		marginTop: 40,
		marginBottom: 32,
		cursor: 'pointer',
		borderRadius: 6,
		padding: '16px',
		'&:hover': {
			background: siteColors.grey300,
		},
	}),
};

export const ItemButton = ({
	icon,
	text,
	link,
	active = false,
	external = false,
	comingSoon,
	isEmpty,
	setFillPathOnHover,
	onClick,
	collapsible,
	open,
	isContributor,
	code,
}: ItemButtonProps) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const activeStyle: SxProps = {
		bgcolor: siteColors.primary10,
		'& svg': {
			// fill: theme.palette.primary.main,
			color: theme.palette.primary.main,
		},
		...(setFillPathOnHover && {
			'& svg > path:first-child': {
				// fill: theme.palette.primary.main,
				fill: theme.palette.primary.main,
			},
		}),
		'& .MuiListItemText-root > .MuiTypography-root': {
			color: theme.palette.primary.main,
		},
	};

	return (
		<>
			<ListItemButton
				disableRipple
				onClick={onClick}
				{...(link && {
					LinkComponent: Link,
					to: link,

					...(external && { target: '_blank' }),
				})}
				className={cx(
					itemButtonStyles.item,
					comingSoon ? itemButtonStyles.comingSoon : '',
					isEmpty ? itemButtonStyles.isEmpty : '',
				)}
				sx={{
					...(!active
						? {
								'& .MuiListItemText-root > .MuiTypography-root': {
									color: theme.palette.common.black,
								},
								'& svg': {
									color: theme.palette.grey['700'],
								},
						  }
						: activeStyle),
					'&:hover': activeStyle,
				}}
			>
				<ListItemIcon
					sx={{
						minWidth: 'unset',
					}}
				>
					{icon}
				</ListItemIcon>
				<ListItemText
					primary={
						<>
							{!isEmpty && (
								<div className="flexRow alignCenter nowrap">
									{text}
									{comingSoon && (
										<>
											&nbsp;&nbsp;
											<div className={cx('flexRow alignCenter', itemButtonStyles.comingSoonTextContainer)}>bientôt</div>
										</>
									)}

									{isContributor && code !== MOD_DASHBOARD && (
										<>
											&nbsp;&nbsp;
											<Lock />
										</>
									)}
								</div>
							)}
							{isEmpty && (
								<LightTooltip title={t('Pas de catégorie paramétrée')} className="flexRow alignCenter nowrap">
									<div className="flexRow alignCenter nowrap">{text}</div>
								</LightTooltip>
							)}
						</>
					}
					className={itemButtonStyles.itemTextContainer}
					sx={{
						'& > .MuiTypography-root': {
							fontWeight: 500,
							lineHeight: 'normal',
							...(isEmpty && {
								//  pointerEvents: 'none',
								color: `${theme.palette.grey[700]} !important`,
								cursor: 'not-allowed',
							}),
							...(comingSoon && { pointerEvents: 'none', color: `${theme.palette.grey[700]} !important` }),
						},
					}}
				/>

				{collapsible && !isEmpty && (open ? <ChevronUp size={20} /> : <ChevronDown size={20} />)}
			</ListItemButton>
		</>
	);
};

export default SidebarNav;
