import { isWhitespace } from '@carbonmaps/shared/utils/utils';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';
import { Image, Page, Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import { cn, siteColors } from '../../../../lib/colors';
import { stripEnd } from './FacetsPage';
import { PageFooter } from './GroupPage';
import { FONT_FAMILY_STYLE, pdfStyles } from './pdfStyles';
import { UseQuoteReturn } from './useQuote';

const SummaryPage = (props: UseQuoteReturn) => {
	const {
		summaryPage: { title, summaryTableData, sender, receiver, totalImpactQuote, customMessage },
		color500,
		Unit1,
		color400,
		color100,
		t,
	} = props;

	return (
		<Page size="A4" style={pdfStyles.body}>
			<View style={{ display: 'flex', flexDirection: 'row', marginBottom: 21 }}>
				<View style={{ flexGrow: 1 }}>
					<Text
						style={{
							textTransform: 'uppercase',
							color: color500,
							fontSize: 10,
							fontWeight: 'light',
							...FONT_FAMILY_STYLE,
						}}
					>
						{t('Devis carbone')}
					</Text>
					<Text style={{ fontSize: 24, ...FONT_FAMILY_STYLE }}>{title}</Text>
				</View>

				<View>
					<Text style={{ fontSize: 8, ...FONT_FAMILY_STYLE, color: siteColors.grey900 }}>
						{t('Devis carbone généré avec')}
					</Text>
					<Image style={{ width: 100, marginTop: 6 }} src="/carbonmaps-logo-pdf.png" />
				</View>
			</View>

			<View style={{ display: 'flex', flexDirection: 'row', marginBottom: 21 }}>
				<View style={{ minWidth: 146 }}>
					<Text style={{ fontSize: 10, marginBottom: 8, ...FONT_FAMILY_STYLE }}>{sender.companyName}</Text>
					<View style={{ fontSize: 8, color: siteColors.grey700, ...FONT_FAMILY_STYLE }}>
						<Text>{sender.name}</Text>
						<Text>{sender.email}</Text>
						<Text>{sender.tel}</Text>
					</View>
				</View>

				<View>
					<Text style={{ fontSize: 10, marginBottom: 8, ...FONT_FAMILY_STYLE }}>{receiver.clientName}</Text>
					<View style={{ fontSize: 8, color: siteColors.grey700 }}>
						<Text>{receiver.email}</Text>
					</View>
				</View>
			</View>

			{customMessage && !isWhitespace(customMessage) ? (
				<View
					style={{
						paddingTop: 16,
						borderTop: cn('1px solid', siteColors.grey500),
						marginBottom: 21,
					}}
				>
					{/* <FacetsCounts counts={facetsCounts} /> */}
					<View style={{ fontSize: 8, color: siteColors.grey700, ...FONT_FAMILY_STYLE }}>
						<Text>{customMessage}</Text>
					</View>
				</View>
			) : null}

			<View
				style={{
					marginBottom: 21,
				}}
			>
				<ImpactKPICard
					value={totalImpactQuote}
					color500={color500}
					Unit1={Unit1}
					text={t('Impact carbone de la prestation')}
				/>
			</View>

			<View>
				<View style={{ paddingBottom: 11, borderBottom: cn('1px solid', siteColors.grey500), marginBottom: 11 }}>
					<Text style={{ fontSize: 10, marginBottom: 6, ...FONT_FAMILY_STYLE }}>{t('Émissions dans le détails')}</Text>
					<Text style={{ fontSize: 10, color: siteColors.grey700, ...FONT_FAMILY_STYLE }}>
						{t('Mieux comprendre cette prestation')}
					</Text>
				</View>

				<View>
					{summaryTableData.map((data, index) => {
						const isLastItem = index === summaryTableData.length - 1;

						return (
							<View
								key={index}
								wrap={false}
								style={{
									display: 'flex',
									flexDirection: 'row',
									gap: 11,
									marginBottom: 8,
									fontSize: 8,
									...FONT_FAMILY_STYLE,
								}}
							>
								<Text style={{ color: siteColors.grey700, width: 84 }}>
									{!isWhitespace(data.name) ? data.name : t('Sans nom')}
								</Text>
								<View style={{ flexGrow: 1, height: 8 }}>
									{isLastItem ? (
										<View
											style={{
												width: `${data.percent}%`,
												backgroundColor: color100,
												border: cn('1px dashed', color500),
												height: '100%',
											}}
										></View>
									) : (
										<View style={{ width: `${data.percent}%`, backgroundColor: color400, height: '100%' }}></View>
									)}
								</View>
								<Text style={{ color: siteColors.grey900, width: 70, textAlign: 'right' }}>
									{stripEnd(displayValue(data.value))}{' '}
									<Unit1 style={{ color: siteColors.grey600, marginLeft: 3 }} subFontSize={4} />
								</Text>
							</View>
						);
					})}
				</View>
			</View>

			<PageFooter t={t} />
		</Page>
	);
};

export default SummaryPage;

export const ImpactKPICard = ({
	color500,
	Unit1,
	value,
	// t,
	text,
}: {
	value: number;
	color500: string;
	Unit1: FC<any>;
	text: string;
	// t: TFunction;
}) => {
	return (
		<View style={{ padding: 16, backgroundColor: color500, width: 262, color: 'white' }}>
			<View style={{ display: 'flex', flexDirection: 'row', marginBottom: 8 }}>
				<Text style={{ fontSize: 20, fontWeight: 'medium', ...FONT_FAMILY_STYLE }}>
					{stripEnd(displayValue(value))} <Unit1 style={{ fontSize: 10, marginLeft: 4 }} subFontSize={6} />
				</Text>
			</View>
			<Text style={{ fontSize: 8, fontWeight: 'light', ...FONT_FAMILY_STYLE }}>
				{/* {t('Impact carbone de la prestation')} */}
				{text}
			</Text>
		</View>
	);
};

export const PRODUCT_TABLE_PRODUCT_LABEL_MAX_LENGTH = 100;
export const SUBCATEGORY_LABEL_MAX_LENGTH = 36;

export const truncateText = (text: string = '', maxLength: number = 100) => {
	if (text.length > maxLength) {
		return text.substring(0, maxLength) + '...';
	}
	return text;
};
