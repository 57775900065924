import { isWhitespace } from '@carbonmaps/shared/utils/utils';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';
import { Image, Page, Text, View } from '@react-pdf/renderer';
import _ from 'lodash';
import { cn, siteColors } from '../../../../lib/colors';
import { PageFooter } from './GroupPage';
import { FONT_FAMILY_STYLE, pdfStyles } from './pdfStyles';
import { SUBCATEGORY_LABEL_MAX_LENGTH, truncateText } from './SummaryPage';
import { UseQuoteReturn } from './useQuote';

export const stripEnd = (value: string) => {
	// const _stripEnd = [',0', ',00'];

	// if (value.endsWith(_stripEnd[0])) {
	// 	return value.replace(_stripEnd[0], '');
	// }
	// if (value.endsWith(_stripEnd[1])) {
	// 	return value.replace(_stripEnd[1], '');
	// }

	return value;
};

const FacetsPage = (props: UseQuoteReturn) => {
	const {
		Unit1,
		Unit3,
		color100,
		color400,
		color500,
		facetsPage,
		orderedFacetsColors,
		t,
		summaryPage: { customMessage },
	} = props;

	const gridGraphData =
		facetsPage.preferredFacetSummary?.categories.map((category, index) => {
			return {
				...category,
				color: orderedFacetsColors[index] || orderedFacetsColors[orderedFacetsColors.length - 1],
			};
		}) || [];

	const horizontalGraphData: GraphItem[] =
		facetsPage.preferredFacetSummary?.categories.map((categoryItem, index) => {
			return {
				name: categoryItem.name,
				percent: categoryItem.percent,
				color: orderedFacetsColors[index] || orderedFacetsColors[orderedFacetsColors.length - 1],
			};
		}) || [];

	return (
		<Page size="A4" style={pdfStyles.body}>
			<View>
				<Text style={{ marginBottom: 22, fontWeight: 'light', fontSize: 20, ...FONT_FAMILY_STYLE }}>
					{t('Détails de votre prestation')}
				</Text>

				{customMessage && !isWhitespace(customMessage) ? (
					<View
						style={{
							paddingTop: 16,
							borderTop: cn('1px solid', siteColors.grey500),
							marginBottom: 21,
						}}
					>
						{/* <FacetsCounts counts={facetsCounts} /> */}
						<View style={{ fontSize: 8, color: siteColors.grey700, ...FONT_FAMILY_STYLE }}>
							<Text>{customMessage}</Text>
						</View>
					</View>
				) : null}
			</View>

			<View
				style={{
					borderTop: cn('1px solid', siteColors.grey500),
					paddingTop: 12,
					marginBottom: 22,
				}}
			>
				<SectionHead text1={t('Par') + ' ' + facetsPage.preferredFacetSummary?.name} text2={t('Impact Total')} />

				<View
					style={{
						marginBottom: 12,
					}}
				>
					{_.chunk(gridGraphData, 3).map((chunk, index1) => {
						return (
							<View
								key={_.toString(index1)}
								style={{
									display: 'flex',
									flexDirection: 'row',
									gap: 1,
									marginBottom: 1,
								}}
							>
								{chunk.map((category, index2: number) => {
									return (
										<View
											key={_.toString(index1) + _.toString(index2)}
											style={{ width: `${_.divide(100, 3)}%`, padding: 16, backgroundColor: category.color.bg }}
										>
											<Text style={{ fontSize: 8, color: category.color.text, ...FONT_FAMILY_STYLE, marginBottom: 8 }}>
												{category.name}
											</Text>
											<Text style={{ fontSize: 16, color: category.color.text, ...FONT_FAMILY_STYLE }}>
												{stripEnd(displayValue(category.totalImpact, undefined))}{' '}
												<Unit1 style={{ fontSize: 10 }} subFontSize={6} />
											</Text>
										</View>
									);
								})}
							</View>
						);
					})}
				</View>

				<PDFHorizontalGraph graphData={horizontalGraphData} />
			</View>

			<View>
				{facetsPage.otherFacetSummary.map((facetItem) => {
					return (
						<View
							style={{
								borderTop: cn('1px solid', siteColors.grey500),
								paddingTop: 12,
								marginBottom: 22,
							}}
						>
							<SectionHead text1={t('Par') + ' ' + facetItem.name} text2={t('Impact Total')} />

							<View>
								{_.orderBy(facetItem.subCategories, ['percent'], ['desc']).map((data, index) => {
									return (
										<View
											key={index}
											wrap={false}
											style={{
												display: 'flex',
												flexDirection: 'row',
												gap: 11,
												marginBottom: 8,
												fontSize: 8,
												...FONT_FAMILY_STYLE,
											}}
										>
											<Text style={{ color: siteColors.grey700, width: 84 }}>
												{truncateText(_.toString(data.name), SUBCATEGORY_LABEL_MAX_LENGTH)}
											</Text>
											<View style={{ flexGrow: 1, height: 8 }}>
												<View style={{ width: `${data.percent}%`, backgroundColor: color400, height: '100%' }}></View>
											</View>
											<Text style={{ color: siteColors.grey900, width: 100, textAlign: 'right' }}>
												{stripEnd(displayValue(data.intensity))}{' '}
												<Unit3 style={{ color: siteColors.grey600, marginLeft: 3 }} subFontSize={4} t={t} />
											</Text>
											<Text style={{ color: siteColors.grey900, width: 70, textAlign: 'right' }}>
												{stripEnd(displayValue(data.totalImpact))}{' '}
												<Unit1 style={{ color: siteColors.grey600, marginLeft: 3 }} subFontSize={4} />
											</Text>
										</View>
									);
								})}
							</View>
						</View>
					);
				})}
			</View>

			{/* CYCLE DE VIE */}
			<View
				style={{
					borderTop: cn('1px solid', siteColors.grey500),
					paddingTop: 12,
					marginBottom: 22,
				}}
			>
				<SectionHead
					text1={t('Analyse du cycle de vie de la prestation')}
					text2={t(
						'Vous permet de comprendre à quelle étape de la vie des produits de votre prestation créent des émissions carbone',
					)}
				/>

				<View>
					{facetsPage.lifeCycleData.map((item, index) => {
						return (
							<View
								key={index}
								wrap={false}
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									fontSize: 8,
									...FONT_FAMILY_STYLE,
									marginBottom: 8,
									gap: 15,
								}}
							>
								<View
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
									}}
								>
									<Image src={item.icon} style={{ width: 11, height: 11, marginRight: 8 }} />
									<Text style={{ color: siteColors.grey700, width: 65 }}>{t(item.key)}</Text>
								</View>
								<View style={{ flexGrow: 1, height: 8 }}>
									<View style={{ width: `${item.percentValue}%`, backgroundColor: color400, height: '100%' }} />
								</View>
								<Text style={{ width: 30, textAlign: 'right' }}>{stripEnd(displayValue(item.percentValue))} %</Text>
							</View>
						);
					})}
				</View>
			</View>

			<PageFooter t={t} />
		</Page>
	);
};

export default FacetsPage;

export const SectionHead = ({ text1, text2 }: { text1: string; text2: string | undefined }) => {
	return (
		<View style={{ marginBottom: 12 }} wrap={false}>
			<Text style={{ fontSize: 10, ...FONT_FAMILY_STYLE, marginBottom: 8 }}>{text1}</Text>
			{text2 ? <Text style={{ fontSize: 8, color: siteColors.grey700, ...FONT_FAMILY_STYLE }}>{text2}</Text> : null}
		</View>
	);
};

export type GraphItem = {
	name: string;
	percent: number;
	color: {
		text: string;
		bg: string;
	};
};

export const PDFHorizontalGraph = ({ graphData = [] }: { graphData: GraphItem[] }) => {
	return (
		<View>
			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					height: 15,
					gap: 2,
					marginBottom: 6,
				}}
			>
				{graphData?.map((facet, index) => {
					return (
						<View
							key={index}
							style={{ height: '100%', width: `${facet.percent}%`, backgroundColor: facet.color.text }}
						></View>
					);
				})}
			</View>

			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					gap: 6,
					marginBottom: 6,
				}}
			>
				{graphData.map((facet, index) => {
					return (
						<View key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<View style={{ height: 6, width: 6, marginRight: 3, backgroundColor: facet.color.text }} />
							<Text style={{ fontSize: 8, color: siteColors.grey700, ...FONT_FAMILY_STYLE }}>
								{facet.name} <Text style={{ color: siteColors.grey800 }}>{stripEnd(displayValue(facet.percent))}%</Text>
							</Text>
						</View>
					);
				})}
			</View>
		</View>
	);
};
