import { useCallback, useEffect, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { createColumnHelper } from '@tanstack/react-table';
import { type TFunction } from 'i18next';
import { Box, Minus, Plus } from 'lucide-react';

import BButton from '@carbonmaps/ui/components/saas/BButton';

import IntensityCell from '../../../components/_comp/IntensityCell';
import Table from '../../../components/_comp/Table';
import TableHeadCell from '../../../components/_comp/TableHeadCell';
import LabelLogo from '../../../components/question/LabelLogo';
import { useTranslation } from '../../../hooks/useTranslation';
import { useAddProductDevis, useFindProductsForDevisInfinite, useRemoveProductDevis } from '../hooks';

import { Indicator } from '@carbonmaps/ui/contexts/AppProvider';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';
import { CircularProgress, LinearProgress } from '@mui/material';
import _ from 'lodash';
import { cn, siteColors } from '../../../lib/colors';
import { useQuoteSheet } from '../quote.hooks';

const tableStyles = {
	main: css({
		width: '100%',
		borderCollapse: 'collapse',
		'& tr': {
			height: '60px',
			alignSelf: 'stretch',
		},
		'& tbody tr': {
			borderTop: cn('1px solid', siteColors.grey500),
		},
		'.labelLogoText': {
			maxWidth: '600px',
		},
		'& tr > th:nth-of-type(1), & tr > td:nth-of-type(1)': {
			width: 300,
		},
		'& tr > th:not(:nth-of-type(1)) .alignRight': {
			display: 'flex!important',
			justifyContent: 'flex-end',
		},

		// ===
		// background: 'red',
		// paddingLeft: 0
	}),

	paddingStart: css({
		paddingLeft: 54,
		// marginLeft: 100,
		// transform: 'translateX(50px)',
	}),
};

export type ProductRowData = {
	id: string;
	uid: string;
	label: string;

	tagAdvanced?: boolean;

	intensity?: number;
	intensityPercent?: number;

	isAdded?: boolean;
};

const defaultData: ProductRowData[] = [
	{
		id: '1',
		uid: '1',
		label: 'Product 1',
		intensity: 100,
		intensityPercent: 100,
	},
	{
		id: '2',
		uid: '2',
		label: 'Product 2',
		intensity: 200,
		intensityPercent: 200,
	},
	{
		id: '3',
		uid: '3',
		label: 'Product 3',
		intensity: 300,
		intensityPercent: 300,
	},
];

const columnHelper = createColumnHelper<ProductRowData>();

type GetColumnsProps = {
	quoteId?: string;
	groupId?: string;
	groupProductIds?: string[];
	indicator: Indicator;
	t: TFunction;
};

const getColumns = ({ t, groupId, quoteId, groupProductIds, indicator }: GetColumnsProps) => {
	return [
		columnHelper.accessor('label', {
			header: (info) => {
				return <TableHeadCell info={info} label={t('product')} valueType="string" />;
				// return <div>AAAAAA</div>;
			},
			cell: (info) => {
				return <LabelLogo label={info.row.original.label} uid={info.row.original.uid} icon={<Box size={20} />} />;
			},
		}),
		columnHelper.accessor('intensity', {
			header: (info) => {
				return (
					<TableHeadCell
						info={info}
						label={t('Par kilo')}
						valueType="number"
						// unit={<Unit measure={indicator === WATER_INDICATOR ? 'waterIntensity' : 'carbonIntensityKg'} />}
						variant="measure"
						measure={indicator === WATER_INDICATOR ? 'waterIntensity' : 'carbonIntensityKg'}
					/>
				);
			},
			cell: (info) => {
				return <IntensityCell value={info.getValue()} percentage={info.row.original.intensity} indicator={indicator} />;
			},
		}),
		columnHelper.display({
			id: 'actions',
			// header: (info) => {
			// 	return <TableHeadCell info={info} label={t('supplier')} />;
			// },
			cell: (info) => {
				return (
					<div className={cx('flexRow alignCenter justifyCenter')}>
						{/* <div className={cx('', tableStyles.paddingStart)}> */}
						{(groupProductIds || [])?.includes(info.row.original.id) ? (
							<RemoveProductDevisButton groupId={groupId} productId={info.row.original.id} quoteId={quoteId} />
						) : (
							<AddProductDevisButton groupId={groupId} productId={info.row.original.id} quoteId={quoteId} />
						)}
						{/* </div> */}
					</div>
				);
			},
		}),
	];
};

const AddProductDevisButton = ({
	quoteId,
	groupId,
	productId,
}: {
	quoteId?: string;
	groupId?: string;
	productId?: string;
}) => {
	const { t } = useTranslation();
	const { indicator } = useApp();
	const [isActionRunning, setIsActionRunning] = useState(false);
	const { mutate: addProduct /* , isLoading: isLoadingUpdate */ } = useAddProductDevis();
	const { isFetching: isFetchingGetQuoteSheet } = useQuoteSheet({ quoteId: _.toString(quoteId), viewMode: indicator });

	// console.log();
	useEffect(() => {
		if (isActionRunning && !isFetchingGetQuoteSheet) {
			setIsActionRunning(false);
		}
	}, []);

	return (
		<BButton
			variant="tertiary"
			iconLeft={isActionRunning ? <CircularProgress size={20} /> : <Plus size={20} />}
			onClick={() => {
				// console.log('Add', { groupId, productId, quoteId });
				// return;
				setIsActionRunning(true);
				addProduct({ groupId, productId, quoteId });
			}}
			// label={isLoadingUpdate ? 'Adding...' : 'Add'}
			label={/* isLoadingUpdate ? <CircularProgress size={20} /> : */ t('Ajouter')}
			addStyles={{ background: 'transparent', '&:hover': { background: 'transparent' } }}
		/>
	);
};

const RemoveProductDevisButton = ({
	quoteId,
	groupId,
	productId,
}: {
	quoteId?: string;
	groupId?: string;
	productId?: string;
}) => {
	const { t } = useTranslation();
	const { indicator } = useApp();
	const [isActionRunning, setIsActionRunning] = useState(false);
	const { mutate: removeProduct /* , isLoading: isLoadingUpdate */ } = useRemoveProductDevis();
	const { isFetching: isFetchingGetQuoteSheet } = useQuoteSheet({ quoteId: _.toString(quoteId), viewMode: indicator });

	useEffect(() => {
		if (isActionRunning && !isFetchingGetQuoteSheet) {
			setIsActionRunning(false);
		}
	}, []);

	return (
		<BButton
			iconLeft={isActionRunning ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <Minus size={20} />}
			variant="primary"
			onClick={() => {
				// console.log('Remove');
				setIsActionRunning(true);
				removeProduct({ groupId, productId, quoteId });
			}}
			// label={isLoadingUpdate ? 'Adding...' : 'Add'}
			label={/* isLoadingUpdate ? <CircularProgress size={20} /> :  */ t('Retirer')}
		/>
	);
};

type Props = {
	quoteId?: string;
	groupId?: string;
	groupProductIds?: string[];
	// open: boolean;
	// onClose?: ComponentProps<typeof ProductsModalSelect>['onClose'];
};

const ProductsTableSelect = ({ quoteId, groupId, groupProductIds /* , open, onClose */ }: Props) => {
	// const [data, setdata] = useState(() => {
	// 	return [...defaultData];
	// });

	const { indicator } = useApp();
	const { t } = useTranslation();

	const columns = useMemo(() => {
		return getColumns({ t: t as never, quoteId, groupId, groupProductIds, indicator });
	}, [groupId, quoteId, t, groupProductIds, indicator]);

	const {
		data,
		fetchNextPage /* , fetchPreviousPage */,
		isFetchingNextPage /* , isFetchingPreviousPage */,
		// isInitialLoading,
		isFetching,
		isLoading,
	} = useFindProductsForDevisInfinite({ enabled: true });

	//flatten the array of arrays from the useInfiniteQuery hook
	const flatData = useMemo(() => {
		return (
			data?.pages?.flatMap((page) => {
				// return page.data;
				return page.products;
			}) ?? []
		);
	}, [data]);

	const totalDBRowCount = data?.pages?.[0]?.meta?.totalCount ?? 0;
	const totalFetched = flatData.length;
	// const totalDBRowCount = 1000;

	//called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
	const fetchMoreOnBottomReached = useCallback(
		(containerRefElement?: HTMLDivElement | null) => {
			if (containerRefElement) {
				const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

				//once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
				if (scrollHeight - scrollTop - clientHeight < 500 && !isFetching && totalFetched < totalDBRowCount) {
					fetchNextPage();
				}
			}
		},
		[fetchNextPage, isFetching, totalFetched, totalDBRowCount],
	);

	return (
		<div
			css={{ /* background: 'red', */ maxHeight: '600px', overflowY: 'scroll', padding: '0 32px' }}
			className={cx('width100')}
			onScroll={(e) => {
				// console.log('lol');
				return fetchMoreOnBottomReached(e.target as HTMLDivElement);
			}}
		>
			{/* // <ProductsModalSelect
		// 	open={open}
		// 	onClose={onClose}
		// 	onScroll={(e) => {
		// 		console.log('lol');
		// 		// return fetchMoreOnBottomReached(e.target as HTMLDivElement);
		// 	}}
		// > */}
			<Table
				columns={columns}
				data={flatData}
				tableProps={{ className: cx('', tableStyles.main /* , isTableLoading ? tableStyles.loading : '' */) }}
				// isLoading={isLoading}
				isLoading={isFetching && !isFetchingNextPage}
			/>
			{isFetchingNextPage ? <LinearProgress /> : null}
			{/* // </ProductsModalSelect> */}
		</div>
	);
};

export default ProductsTableSelect;
