import { css, cx } from '@emotion/css';
import { useTheme } from '@mui/material';
import _ from 'lodash';
import { HelpCircle } from 'lucide-react';
import Typography from '../../../../../apps/front/src/components/Typography';
import { useTranslation } from '../../../../../apps/front/src/hooks/useTranslation';
import { displayValue } from '../../../utils/numberFormat';
import BPopover from '../BPopover';

const styles = {
	container: css({
		gap: 2,
	}),
	line: css({
		width: 'var(--width)',
		height: 8,
		borderRadius: 2,
		background: 'var(--background)',
		'.MuiButtonBase-root': {
			width: '100% !important',
		},
	}),
	popoverContent: css({
		padding: '16px',
		width: '220px !important',
	}),
	popoverContentValue: css({
		padding: '16px',
		minWidth: '120px !important',
	}),
	label: css({
		fontWeight: '600 !important',
		lineHeight: '1 !important',
		fontSize: '14px !important',
	}),
	value: css({
		lineHeight: '1 !important',
	}),
};

type Props = {
	value: Array<any>;
	row: any;
	column: any;
};

const BarField = ({ value, row, column }: Props) => {
	const theme = useTheme();

	const { t } = useTranslation();
	return (
		<div className={cx('width100 flexRow nowrap alignCenter', styles.container)}>
			{!value?.length ? (
				<BPopover
					modeHover
					content={() => {
						return (
							<div className={styles.popoverContent}>
								<p>{t('Vous devez passer à la nouvelle version pour avoir cette information')}</p>
							</div>
						);
					}}
					transformOriginHorizontal={'left'}
					transformOriginVertical={'top'}
					anchorOriginHorizontal={'left'}
					anchorOriginVertical={'bottom'}
				>
					<HelpCircle color={theme.palette.grey[600]} />
				</BPopover>
			) : (
				value.map((item: { value: number; label: string; color: string; type?: string }, key: number) => {
					return (
						<BPopover
							modeHover
							content={() => {
								return (
									<div className={cx('flexColumn  nowrap gap8', styles.popoverContentValue)}>
										<Typography className={styles.label}>{t(item?.type || item?.label)}</Typography>
										<Typography className={cx(styles.value, 'number')}>
											{_.isString(item?.value) ? item?.value : `${displayValue(item?.value, undefined, 2)} %`}
										</Typography>
									</div>
								);
							}}
							transformOriginHorizontal={'left'}
							transformOriginVertical={'top'}
							anchorOriginHorizontal={'left'}
							anchorOriginVertical={'bottom'}
							containerClass={styles.line}
							style={{ ['--width' as any]: `${item.value}%`, ['--background' as any]: item.color }}
						>
							<div
							// className={styles.line}
							// style={{ ['--width' as any]: `${item.value}%`, ['--background' as any]: item.color }}
							></div>
						</BPopover>
					);
				})
			)}
		</div>
	);
};

export default BarField;
