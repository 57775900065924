import { Fragment, SyntheticEvent, useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import SEO from '../../components/SEO';
import TabsBar, { TabItem } from '../../components/TabsBar';
import { useGlossary } from '../../hooks/useGlossary';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useQueryParams } from '../../hooks/useSearchParams';
import { useTranslation } from '../../hooks/useTranslation';

import { useGetOneSupplier } from '@carbonmaps/ui/hooks/useGetSupplierSheetTimePeriod';
import SupplierHeader from './SupplierHeader';
import Achats from './tabs/achats/Achats';
import RseDetails from './tabs/rse/RseDetails';
import Supply from './tabs/supply/Supply';
import Synthesis from './tabs/synthesis/Synthesis';

const tabsItems = [
	{
		id: 'synthesis',
		title: 'synthesis-tab',
		element: <Synthesis />,
		disabled: false,
		tabName: 'Summary',
	},
	{
		id: 'approvisionnements',
		title: 'approvisionnement-tab',
		element: <Supply />,
		glossary: 'approvisionnements',
		tabName: 'Supplies',
	},
	{
		id: 'achat-du-fournisseur',
		title: 'achat-supplier-tab',
		element: <Achats />,
		glossary: 'achat-du-fournisseur',
		tabName: 'Purchases',
	},
	{
		id: 'rse-performance',
		title: 'rse-performance-tab',
		element: <RseDetails />,
		tabName: 'CSR Performance',
	},
];

const Supplier = () => {
	const params = useParams();
	const navigate = useNavigate();
	const { setBreadcrumbs } = useApp();

	// translation
	const { t } = useTranslation();
	// glossary
	const { g } = useGlossary();

	const queryParams = useQueryParams();
	const searchParams = createSearchParams(queryParams as any)?.toString();

	const analytics = useJuneTrack();
	const trackEvent = useCallback(
		(name: string, options: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	//--------------------------------------------------------------------------------------//
	//                                    Supplier info                                     //
	//--------------------------------------------------------------------------------------//
	// use query
	const [notFound, setNotFound] = useState(false);

	const { data: supplierInfo, error, isLoading, isSuccess } = useGetOneSupplier({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		objectId: params.supplierId!,
	});


	const handleTabChange = (_: SyntheticEvent, value: string) => {
		navigate(`${FRONT_PATH_NAMES.suppliers.details}/${params.supplierId}/${value}?${searchParams}`);

		const currentTab = tabsItems?.find((item) => {
			return item.id === value;
		});

		trackEvent('Explore Supplier Switch Tab', { SupplierTabTarget: (currentTab as any)?.tabName });
	};

	useEffect(() => {
		setBreadcrumbs([
			{ text: t('suppliers'), link: `${FRONT_PATH_NAMES.suppliers.general}?${searchParams}` },
			{ text: supplierInfo?.name },
		]);
	}, [supplierInfo, setBreadcrumbs, searchParams, t]);

	if (notFound) {
		return <h2>Le fournisseur: {params.supplierId}, n'existe pas.</h2>;
	}

	return (
		<Box>
			<SEO title={isLoading ? 'CarbonMaps' : `${supplierInfo?.name || '?'} - Carbon Maps`} />
			<Box width="90%" mx="auto">
				<SupplierHeader />
			</Box>
			{isLoading ? (
				<Skeleton variant="rectangular" height="62px" sx={{ borderRadius: '16px', width: '90%', mx: 'auto' }} />
			) : (
				<TabsBar
					value={params.tab}
					onChange={handleTabChange}
					sticky
					sx={{
						mb: pxToRem(16),
						width: '90%',
						mx: 'auto',
						top: 62,
						'&.sticky': { width: '100%', borderRadius: '16px' },
					}}
				>
					{tabsItems.map((item: any) => {
						return (
							<TabItem
								key={item.id}
								label={t(item.title)}
								value={item.id}
								disabled={item.disabled}
								glossary={item?.glossary}
								g={g}
							/>
						);
					})}
				</TabsBar>
			)}
			<Box width="90%" mx="auto">
				{tabsItems.map((item) => {
					return !isLoading && item.id === params.tab && <Fragment key={item.id}>{item.element}</Fragment>;
				})}
			</Box>
		</Box>
	);
};

export default Supplier;
