import { useCallback, useEffect, useMemo, useState } from 'react';

import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css, cx } from '@emotion/css';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { ChevronDown, CopyPlus, Edit2, Plus, Trash2 } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { deleteGroupAction, duplicateGroupAction } from '@carbonmaps/ui/actions/quote.actions';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import DeleteConfirm from '@carbonmaps/ui/components/saas/DeleteConfirm';
import { useDebounce } from '@carbonmaps/ui/hooks/useDebounce';

import Popover from '../../components/Popover';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useTranslation } from '../../hooks/useTranslation';
import QuoteModel from '../../models/Quote.model';
import { CARBON_INDICATOR, WATER_INDICATOR } from '../../utils/constants';

import { isWhitespace } from '@carbonmaps/shared/utils/utils';
import { cn, siteColors } from '../../lib/colors';
import QuoteGroup from './QuoteGroup';
import { useAddGroupQuote, useQuoteStore, useReorderGroupQuote } from './quote.hooks';

const styles = {
	addButton: css({
		color: cn(siteColors.grey700, '!important'),
	}),
	footer: css({
		padding: '32px 24px',
	}),

	hidden: css({
		display: 'none',
	}),

	textLeft: css({
		textAlign: 'left',
		width: '100%',
	}),

	textField: css({
		'& .MuiInputBase-root': {
			height: '42px',
			borderRadius: '8px',
		},
		'& fieldset': {
			borderColor: cn(siteColors.grey700, '!important'),
			borderWidth: '1px !important',
		},
	}),
};

const QuoteSheetContent = () => {
	const { quote } = useQuoteStore();
	const [items, setItems] = useState([]);
	const params = useParams();

	const { mutate: saveQuoteGroup } = useAddGroupQuote();

	const { mutate: reorderQuoteGroup } = useReorderGroupQuote();

	const groups = useMemo(() => {
		return (quote as any)?.getGroup() || [];
	}, [quote]);

	const analytics = useJuneTrack();
	// ---- track event ---- //
	const trackEvent = useCallback(
		(eventName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(eventName, options, {
				context: { category: 'Simulate' },
			});
		},
		[analytics],
	);

	const addGroup = useCallback(() => {
		saveQuoteGroup({
			quoteId: params?.quoteId,
			group: { objectId: null, name: ' ' },
			estimatedImpactCarbon: (quote as QuoteModel | null)?.getEstimatedImpact(CARBON_INDICATOR),
			estimatedImpactWater: (quote as QuoteModel | null)?.getEstimatedImpact(WATER_INDICATOR),
			averageCarbonIntensity: (quote as QuoteModel | null)?.getAverageIntensity(CARBON_INDICATOR),
			averageWaterIntensity: (quote as QuoteModel | null)?.getAverageIntensity(WATER_INDICATOR),
		});
		trackEvent('Simulate Carbon Quote Create Tab', {
			QuoteName: (quote as any)?.quote?.name,
		});
	}, [params?.quoteId, quote, saveQuoteGroup, trackEvent]);

	// add empty group when exists
	useEffect(() => {
		if (!quote) return;

		const groups = (quote as any)?.getGroup();

		if (!groups?.length) {
			// create empty group when not exists
			saveQuoteGroup({
				quoteId: params?.quoteId,
				group: { objectId: null, name: ' ' },
				estimatedImpactCarbon: (quote as QuoteModel | null)?.getEstimatedImpact(CARBON_INDICATOR),
				estimatedImpactWater: (quote as QuoteModel | null)?.getEstimatedImpact(WATER_INDICATOR),
			});
		}
	}, [params?.quoteId, quote, saveQuoteGroup]);

	const [quoteGroupId, setQuoteGroupId] = useQueryParam('quoteGroupId', StringParam);

	useEffect(() => {
		if (!quoteGroupId) {
			setQuoteGroupId(groups[0]?.objectId);
			setTabIndex(0);
		} else {
			const foundIndex = (groups as any[])?.findIndex((group) => {
				return group?.objectId === quoteGroupId;
			});

			if (!foundIndex || foundIndex === -1) {
				setQuoteGroupId(groups[0]?.objectId);
				setTabIndex(0);
			} else {
				setTabIndex((prev) => {
					return foundIndex;
				});
			}
		}
	}, [groups, quoteGroupId, setQuoteGroupId]);

	const [tabIndex, setTabIndex] = useState(() => {
		const foundIndex = (groups as any[])?.findIndex((group) => {
			return group?.objectId === quoteGroupId;
		});

		if (!foundIndex || foundIndex === -1) {
			return 0;
		}

		return foundIndex;
	});

	const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
		setQuoteGroupId(items[tabIndex]);
	};

	useEffect(() => {
		setItems(_.map((quote as any)?.getGroup(), 'objectId') as any);
	}, [quote]);

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				delay: 140,
				tolerance: 5,
			},
		}),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);

	const handleDragEnd = (event: any) => {
		const { active, over } = event;

		if (over?.id && active.id !== over.id) {
			setItems((items: any) => {
				const oldIndex = items.indexOf(active.id);
				const newIndex = items.indexOf(over.id);
				setTabIndex(newIndex);
				reorderQuoteGroup({ groupsIds: arrayMove(items, oldIndex, newIndex), quoteId: params?.quoteId });
				return arrayMove(items, oldIndex, newIndex);
			});
			setQuoteGroupId(active.id);
		} else {
			setQuoteGroupId(active.id);
		}
	};

	const [step, setStep] = useState(1);

	return (
		<div>
			<div
				className={cx('flexRow alignCenter')}
				css={{
					background: 'white',
					position: 'sticky',
					top: 0,
					zIndex: 2,
				}}
			>
				<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
					<SortableContext items={items}>
						<Tabs
							value={tabIndex}
							onChange={handleChange}
							variant="scrollable"
							scrollButtons="auto"
							aria-label="scrollable auto tabs example"
							sx={{
								maxWidth: 'calc(100% - 64px)!important',
								'.MuiTabs-scrollButtons.Mui-disabled': {
									opacity: '0.3',
								},
								//disable default indicator mui
								'& .MuiTabs-indicator': {
									display: 'none',
								},
							}}
						>
							{items?.map((item: any, index: number) => {
								return (
									<SortableItem
										group={groups.find((g: any) => {
											return g.objectId === item;
										})}
										key={item}
										id={item}
										name={item}
										icon={
											item === quoteGroupId ? (
												<ChevronDown size={20} />
											) : (
												<ChevronDown className={styles.hidden} size={20} />
											)
										}
										variant={item === quoteGroupId ? 'quoteActive' : 'quoteNonActive'}
										iconPosition="end"
										isPopover={item === quoteGroupId}
										renderPopoverContent={(close: any) => {
											return (
												<PopoverTabContent
													step={step}
													setStep={setStep}
													group={groups.find((g: any) => {
														return g.objectId === item;
													})}
													quoteId={params?.quoteId}
													closePopover={close}
												/>
											);
										}}
										onClose={() => {
											setStep(1);
										}}
									/>
								);
							})}
						</Tabs>
					</SortableContext>
				</DndContext>
				<BButton
					label=""
					iconLeft={<Plus />}
					variant="tertiary"
					onClick={addGroup}
					className={cx('width100--', styles.addButton)}
				/>
			</div>

			{/* ========= */}
			<div>
				{items?.map((item: any, index: number) => {
					const groupElement = (
						<QuoteGroup
							key={index}
							group={groups.find((g: any) => {
								return g.objectId === quoteGroupId;
							})}
						/>
					);

					return (
						<div key={index} className={cx(quoteGroupId !== item ? styles.hidden : '')}>
							{groupElement}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default QuoteSheetContent;

const myTabStyles = {
	common: css({
		whiteSpace: 'nowrap!important',
		height: '58px',
		minHeight: '58px!important',
	} as any),
	quote: css({
		maxWidth: '120px!important',
	}),
	scenario: css({
		// whiteSpace: 'nowrap',
	}),

	quoteActive: css({
		maxWidth: 'unset!important',
	}),

	label: {
		quote: css({
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		}),
		quoteActive: css({
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			color: siteColors.primary,
		}),
		quoteNonActive: css({
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			color: '#00000099',
		}),
		common: css({
			textTransform: 'none',
		}),
	},
	borderActive: css({
		borderBottom: cn('2px solid', siteColors.primary, '!important'),
	}),
	disabled: css({
		cursor: 'not-allowed',
	}),
};

export const SortableItem = ({
	id,
	name,
	group,
	variant = 'quote',
	className,
	isPopover,
	renderPopoverContent,
	onClose,
	...tabProps
}: any) => {
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	const tabContent = (
		<Tab
			ref={setNodeRef}
			{...attributes}
			{...listeners}
			disableRipple
			label={
				<>
					<div className={cx('width100 capitalize', myTabStyles.label.common, (myTabStyles.label as any)[variant])}>
						{!_.isNil(group?.name) && !isWhitespace(group?.name) ? group?.name : 'Sans nom'}
					</div>
				</>
			}
			className={cx('', className, myTabStyles.common, (myTabStyles as any)[variant])}
			{...tabProps}
			style={style}
		/>
	);

	if (isPopover) {
		return (
			<div css={{ display: 'flex' }} className={cx(myTabStyles.borderActive)}>
				<Popover
					trigger={tabContent}
					renderContent={({ close }) => {
						return renderPopoverContent ? renderPopoverContent(close) : (null as any);
					}}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					onClose={onClose}
				/>
				<Divider
					orientation="vertical"
					color={siteColors.grey500}
					flexItem
					sx={{ height: '32px', placeSelf: 'center' }}
				/>
			</div>
		);
	}

	return (
		<div
			css={{
				display: 'flex',
			}}
			className={cx(tabProps.disabled ? myTabStyles.disabled : '')}
		>
			{tabContent}
			<Divider
				orientation="vertical"
				color={siteColors.grey500}
				flexItem
				sx={{ height: '32px', placeSelf: 'center' }}
			/>
		</div>
	);
};

const PopoverTabContent = ({ group, quoteId, closePopover, setStep, step }: any) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	// ------------------------------------------------------ //
	// ----------------- duplicate group -------------------- //
	// ------------------------------------------------------ //

	const { mutate: duplicateGroup, isLoading: isLoadingUpdate } = useMutation({
		mutationKey: ['duplicateGroup'],
		mutationFn: duplicateGroupAction,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
		},
		onError: (error: any) => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
		},
	});

	// ------------------------------------------------------ //
	// ----------------- delete group ----------------------- //
	// ------------------------------------------------------ //
	const navigate = useNavigate();

	const {
		mutate: deleteGroup,
		isLoading: isLoadingDelete,
		// isError,
		// isSuccess,
	} = useMutation({
		mutationKey: ['deleteGroup'],
		mutationFn: deleteGroupAction,
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
			setTimeout(() => {
				navigate(`${FRONT_PATH_NAMES.quote}/${quoteId}`);
			}, 700);
		},
		onError: (error: any) => {
			queryClient.invalidateQueries({ queryKey: ['getQuoteSheet'] });
		},
	});

	const handleClickDelete = useCallback(async (value: any) => {
		await deleteGroup({ objectId: value } as any);
	}, []);

	const [groupName, setGroupName] = useState(group?.name);
	const [isChanged, setIsChanged] = useState(false);

	const { quote } = useQuoteStore();
	const { mutate: saveQuoteGroup } = useAddGroupQuote();

	const debounceValue = useDebounce(groupName, 150);

	// ----- add group ------ //
	useEffect(() => {
		// update group Name
		if (!groupName) return;

		if (!isChanged) return;

		saveQuoteGroup({
			quoteId,
			group: { objectId: group?.objectId, name: groupName },
			estimatedImpactCarbon: (quote as QuoteModel | null)?.getEstimatedImpact(CARBON_INDICATOR),
			estimatedImpactWater: (quote as QuoteModel | null)?.getEstimatedImpact(WATER_INDICATOR),
			averageCarbonIntensity: (quote as QuoteModel | null)?.getAverageIntensity(CARBON_INDICATOR),
			averageWaterIntensity: (quote as QuoteModel | null)?.getAverageIntensity(WATER_INDICATOR),
		});
	}, [debounceValue]);

	const handleChange = (e: any) => {
		setGroupName(e.target.value);
	};

	const onKeyUp = (evt: any) => {
		setIsChanged(true);
		setGroupName(evt.target.value);
	};

	return (
		<>
			{step === 2 ? (
				<div
					className="flexRow"
					css={{
						padding: '24px',
						minWidth: '300px',
					}}
				>
					<TextField
						className={cx('flex1', styles.textField)}
						value={!isWhitespace(groupName || '') ? groupName : ''}
						defaultValue={groupName || t('Sans nom')}
						onChange={handleChange}
						placeholder={t('Sous-partie du devis')}
						onKeyUp={onKeyUp}
					/>
				</div>
			) : (
				<div
					css={{
						minWidth: '200px',
					}}
				>
					<div>
						<MyButton
							icon={<Edit2 color={siteColors.grey700} />}
							label={t('Renommer')}
							onClick={() => {
								setStep?.(2);
							}}
						/>
					</div>

					<div>
						<MyButton
							icon={<CopyPlus color={siteColors.grey700} />}
							label={t('duplicate-button')}
							onClick={() => {
								duplicateGroup({ quoteId, groupId: group?.objectId });
								setTimeout(() => {
									closePopover?.();
								}, 500);
							}}
						/>
					</div>

					<div>
						<DeleteConfirm
							onClick={(closeDeleteConfirm) => {
								handleClickDelete(group?.objectId);
								setTimeout(() => {
									closeDeleteConfirm?.();
									closePopover?.();
								}, 500);
							}}
							title={t('delete-quote-product-title')}
							subMessageConfirmDelete={t('delete-quote-product-subtitle')}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
						>
							<MenuItem>
								<Trash2 color={siteColors.grey700} size={20} />
								<Typography sx={{ paddingLeft: '10px', paddingY: '4px', textTransform: 'none', fontWeight: 600 }}>
									{t('delete-button')}
								</Typography>
							</MenuItem>
						</DeleteConfirm>
					</div>
				</div>
			)}
		</>
	);
};

const MyButton = ({ icon, label, onClick }: any) => {
	return (
		<BButton
			label={
				<>
					{icon}
					<div className={cx(styles.textLeft)}>{label}</div>
				</>
			}
			variant="tertiary"
			onClick={onClick}
			addStyles={{
				width: '100%',
				borderRadius: '0px',
				color: siteColors.text,
			}}
		/>
	);
};
