import { type QueryFunctionContext } from '@tanstack/react-query';

import { functionName } from '@carbonmaps/shared/utils/constants';

export const findIngredientFamilyAction = async (context: QueryFunctionContext<readonly ['findIngredientFamily', Record<string, any>]>) => {
	try {
		const params = context.queryKey[1];

		const data = await Parse.Cloud.run(functionName.temporality.findIngredient, { view: 'general-view-if-ingredients->ingredients-table', ...params }) as {
			ingredients: any[];
			meta: {
				last_page: number;
				count: number;
			}
		};
		return data;
	} catch (error) {
		console.log('---- findIngredientFamilyAction-----------', error);
		return Promise.reject(error);
	}
};

export const findIngredientFamilyTradeoffAction = async (context: QueryFunctionContext<readonly ['findIngredientFamily', Record<string, any>]>) => {
	try {
		const params = context.queryKey[1];

		const data = await Parse.Cloud.run(functionName.temporality.findIngredientFamilyTradeoff, { view: 'general-view-if-ingredients->ingredients-tradeoff', ...params }) as any[];
		return data;
	} catch (error) {
		console.log('---- findIngredientFamilyTradeoffAction-----------', error);
		return Promise.reject(error);
	}
};
