import { type QueryFunctionContext } from '@tanstack/react-query';

import { functionName } from '@carbonmaps/shared/utils/constants';

type getProductSimulationDataType = {
	input?: string;
	facetFilters?: string;
	page: number;
	size: number;
};

export const getQuoteDataAction = async (
	context: QueryFunctionContext<readonly ['getQuoteDataAction', getProductSimulationDataType]>,
) => {
	try {
		const {
			queryKey: {
				1: { size, page, ...rest },
			},
		} = context;

		const { data, count } = await Parse.Cloud.run('findDevis', {
			limit: size,
			skip: (page - 1) * size,
			...rest,
		});

		return {
			data,
			meta: {
				last_page: count ? Math.ceil(count / size) : 1,
				count,
			},
		};

		// return data;
	} catch (error) {
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                                      Mutations create devis                          //
//--------------------------------------------------------------------------------------//
type createQuoteInput = {
	name: string;
	clientId: string;
	toDuplicateQuoteId?: string;
};

export const createQuoteAction = async ({ name, clientId, toDuplicateQuoteId }: createQuoteInput): Promise<void> => {
	try {
		const res = await Parse.Cloud.run(functionName.createQuote, { name, clientId, toDuplicateQuoteId });
		return res;
	} catch (error) {
		return Promise.reject(error);
	}
};

//--------------------------------------------------------------------------------------//
//                                      Mutations delete                                //
//--------------------------------------------------------------------------------------//
type deleteDevisInput = {
	ids?: string[];
	objectId?: string;
};

export const deleteDevisAction = async ({ ids, objectId }: deleteDevisInput): Promise<void> => {
	try {
		await Parse.Cloud.run(functionName.deleteQuote, { objectId, ids });
	} catch (error) {
		return Promise.reject(error);
	}
};

type getQuoteType = {
	quoteId: string;
	viewMode?: string;
};

// ---------------------------------------------------- //
// --------------- get quote -------------------------- //
// ---------------------------------------------------- //
export const getQuoteSheetAction = async (context: QueryFunctionContext<readonly ['getQuoteSheet', getQuoteType]>) => {
	try {
		const {
			queryKey: {
				1: { quoteId, viewMode },
			},
		} = context;

		// run parse function to get quote by id
		const result = await Parse.Cloud.run('getQuoteSheet', {
			quoteId,
			viewMode,
		});

		return result;

		// return data;
	} catch (error) {
		return Promise.reject(error);
	}
};

// -------------------------------------------------- //
// --------------- saved quote ---------------------- //
// -------------------------------------------------- //
export const saveQuoteGroupAction = async ({
	quoteId,
	group,
	estimatedImpactCarbon,
	estimatedImpactWater,
	averageCarbonIntensity,
	averageWaterIntensity,
}: any) => {
	try {
		return Parse.Cloud.run('saveQuoteGroup', {
			quoteId,
			group,
			estimatedImpactCarbon,
			estimatedImpactWater,
			averageCarbonIntensity,
			averageWaterIntensity,
		});
	} catch (error) {
		return Promise.reject(error);
	}
};

export const duplicateGroupAction = async ({ quoteId, groupId }: any) => {
	try {
		return Parse.Cloud.run('duplicateGroup', { quoteId, groupId });
	} catch (error) {
		return Promise.reject(error);
	}
};

// -------------------------------------------------- //
// --------------- delete quote ---------------------- //
// -------------------------------------------------- //

export const deleteGroupAction = async ({ objectId }: any) => {
	try {
		return Parse.Cloud.run('deleteGroup', { objectId });
	} catch (error) {
		return Promise.reject(error);
	}
};

type addProductType = {
	quoteId?: string;
	groupId?: string;
	productId?: string;
};

// ----------------------------------------------------- //
// -------------------- add  product into group -------- //
// ----------------------------------------------------- //
export const addProductAction = async ({ quoteId, groupId, productId }: addProductType) => {
	try {
		return Parse.Cloud.run('addProduct', { quoteId, groupId, productId });
	} catch (error) {
		return Promise.reject(error);
	}
};

// ----------------------------------------------------- //
// -------------------- update quote and client -------- //
// ----------------------------------------------------- //

export const updateQuoteClientAction = async (data: any) => {
	try {
		return Parse.Cloud.run('updateQuoteClient', data);
	} catch (error) {
		return Promise.reject(error);
	}
};

// ----------------------------------------------------- //
// -------------------- update all quantity quote ------ //
// ----------------------------------------------------- //

export const updateAllQuantityAction = async (data: any) => {
	try {
		return Parse.Cloud.run('updateAllQuantity', data);
	} catch (error) {
		return Promise.reject(error);
	}
};

// ----------------------------------------------------- //
// -------------------- delete one product for quote --- //
// ----------------------------------------------------- //
export const deleteProductGroupAction = async ({ quoteId, groupId, productId }: any) => {
	try {
		return Parse.Cloud.run('deleteProductGroupQuote', { quoteId, groupId, productId });
	} catch (error) {
		return Promise.reject(error);
	}
};

// -------------------------------------------------- //
// ------------- reorder group quote ---------------- //
// -------------------------------------------------- //
export const reorderQuoteGroupAction = async ({ groupsIds, quoteId }: { groupsIds?: string[]; quoteId?: string }) => {
	try {
		if (!groupsIds || !groupsIds.length) return;
		if (!quoteId) return;
		return Parse.Cloud.run('reorderQuoteGroup', { groupsIds, quoteId });
	} catch (error) {
		return Promise.reject(error);
	}
};
