import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import {
	Box,
	Collapse,
	Divider,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Link as MUILink,
	Skeleton,
	Typography,
	lighten,
	useTheme,
} from '@mui/material';
import _ from 'lodash';
import { BookOpenText, Building, GraduationCap, Send } from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import { ReactComponent as EnIcon } from '@carbonmaps/media/icons/en.svg';
import { ReactComponent as FrIcon } from '@carbonmaps/media/icons/fr.svg';
import { ReactComponent as LogoutIcon } from '@carbonmaps/media/icons/log-out.svg';
import { ReactComponent as End } from '@carbonmaps/media/icons/name=end.svg';
import { ReactComponent as First } from '@carbonmaps/media/icons/name=first.svg';
import { ReactComponent as Middle } from '@carbonmaps/media/icons/name=middle.svg';
import { ReactComponent as OnlyOne } from '@carbonmaps/media/icons/name=one and only.svg';
import defaultLogo from '@carbonmaps/media/images/default-logo.png';
import { ReactComponent as CarbonMapsLogo } from '@carbonmaps/media/other/carbonmaps-logo.svg';
import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

import Popover from '../../components/Popover';
import SidebarNav, { ItemButton } from '../../components/SidebarNav';
import useHasRoles from '../../hooks/useHasRoles';
import { useTranslation } from '../../hooks/useTranslation';
import { useGetClientAuthQuery, useLogOutMutation } from '../../lib/react-query/features/auth/auth.hooks';
import { useGetImgProxy } from '../../lib/react-query/features/img/img.hooks';

type NavSubLisListItem = { label: string; link: string; isActive?: boolean; external?: boolean };

type NavListItem = {
	icon: ReactNode;
	text: string;
	link?: string;
	external?: boolean;
	comingSoon?: boolean;
	initialCollapseOpen?: boolean;
	setFillPathOnHover?: boolean;
	subItems?: NavSubLisListItem[];
	isActive?: boolean;
};

const footerMenu = [
	{
		text: 'about-text',
		link: 'https://carbonmaps.io/en/home-en',
		external: true,
	},
	{
		text: 'blog-text',
		link: 'https://carbonmaps.io/blog',
		external: true,
	},
	{
		text: 'linkedIn-text',
		link: 'https://www.linkedin.com/company/carbon-maps/',
		external: true,
	},
];

const getBranch = (index: number, length: number) => {
	if (length > 1) {
		if (index === 0) {
			return <First css={{ position: 'absolute' }} />;
		}

		if (index === length - 1) {
			return <End css={{ position: 'absolute', top: '-14px' }} />;
		}

		return <Middle css={{ position: 'absolute', top: '-14px' }} />;
	}

	return <OnlyOne css={{ position: 'absolute' }} />;
};

type LayoutSideBarSuperAdminProps = {
	open: boolean;
};

const LayoutSideBarSuperAdmin = ({ open }: LayoutSideBarSuperAdminProps) => {
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const theme = useTheme();
	const navListItems: NavListItem[] = useMemo(() => {
		return [
			// {
			// 	text: 'Se logger en tant que',
			// 	icon: <Home size={20} />,
			// 	link: FRONT_PATH_NAMES.logInAs,
			// 	isActive: pathname === FRONT_PATH_NAMES.logInAs,
			// },
			{
				text: t('organisation'),
				icon: <Building color={theme.palette.grey[700]} />,
				link: FRONT_PATH_NAMES.companies,
				isActive: pathname === FRONT_PATH_NAMES.companies,
			},
			{
				text: t('glossary'),
				icon: <BookOpenText color={theme.palette.grey[700]} />,
				link: FRONT_PATH_NAMES.boGlossary,
				isActive: pathname === FRONT_PATH_NAMES.boGlossary,
			},
			// {
			// 	text: t('Fiches filières'),
			// 	icon: <GraduationCap color={theme.palette.grey[700]} />,
			// 	link: `${FRONT_PATH_NAMES.superAdminSettings}-a/sector-sheet`,
			// 	isActive: pathname.startsWith(`${FRONT_PATH_NAMES.superAdminSettings}-a/sector-sheet`),
			// },
			{
				text: t('Questionnaires'),
				icon: <Send color={theme.palette.grey[700]} />,
				link: `${FRONT_PATH_NAMES.superAdminSettings}-a/question-forms`,
				isActive: pathname.startsWith(`${FRONT_PATH_NAMES.superAdminSettings}-a/question-forms`),
			},
		];
	}, [pathname, theme.palette.grey, t]);

	return (
		<SidebarNav
			header={<Header />}
			footer={<Footer />}
			content={navListItems.map((item, index) => {
				return <NavList key={index} {...item} />;
			})}
			open={open}
		/>
	);
};

export default LayoutSideBarSuperAdmin;

const styles = {
	enIcon: css({
		width: '24px',
		height: '16px',
		'& svg': {
			height: '100%',
			width: '100%',
		},
	}),
	frIcon: css({
		width: '24px',
		height: '16px',
	}),
};

const FlagFr = () => {
	return (
		<div className={styles.frIcon}>
			<FrIcon />
		</div>
	);
};

const FlagEn = () => {
	return (
		<div className={styles.enIcon}>
			<EnIcon />
		</div>
	);
};

//--------------------------------------------------------------------------------------//
//                                        Header                                        //
//--------------------------------------------------------------------------------------//

const Header = () => {
	const theme = useTheme();
	const hasRoles = useHasRoles();
	const { t, i18n } = useTranslation();

	const {
		result: { data: authData, isLoading: isGetClientAuthLoading },
	} = useGetClientAuthQuery();

	const navigate = useNavigate();
	const [, setOpen] = useState(false);

	const onNavigate = (url: string) => {
		setOpen(false);
		navigate(url);
	};

	const {
		result: { mutate: logOut },
	} = useLogOutMutation();

	const listActions = [
		...(hasRoles(['administrateur'])
			? [
					{
						icon: <Building color={theme.palette.grey[700]} />,
						title: t('entreprise'),
						onClick: () => {
							return onNavigate(`${FRONT_PATH_NAMES.managerSettings}`);
						},
					},
			  ]
			: []),
		{
			icon: <LogoutIcon />,
			title: t('logout-text'),
			onClick: () => {
				return logOut();
			},
		},

		{
			icon: i18n?.language === 'en' ? <FlagEn /> : <FlagFr />,
			title: 'Changer de langue',
			onClick: () => {
				// do nothing
			},
			subMenu: [
				{
					label: 'Français',
					icon: <FlagFr />,
					onClick: () => {
						console.log('fr');
						i18n.changeLanguage('fr');
					},
				},
				{
					label: 'English',
					icon: <FlagEn />,
					onClick: () => {
						i18n.changeLanguage('en');
					},
				},
			],
		},
	];

	const {
		result: { data: logoUrl },
	} = useGetImgProxy({
		params: { filename: authData?.session.company?.logo || '' },
	});

	return (
		<Popover
			trigger={
				<Box
					ml={pxToRem(24 /*  - 16 */)}
					mt={pxToRem(24)}
					mb={pxToRem(16)}
					display="flex"
					alignItems="center"
					sx={{
						cursor: 'pointer',
					}}
					borderRadius={pxToRem(6)}
				>
					<Box>
						<CarbonMapsLogo
							css={{
								width: pxToRem(150),
								height: pxToRem(35),
								marginTop: pxToRem(8),
								'& path': { fill: theme.palette.primary.main },
							}}
						/>
						<Typography
							variant="body2"
							css={{ letterSpacing: '1px', marginBottom: '1rem', color: theme.palette.primary.main }}
						>
							SUPERADMIN
						</Typography>
						{!isGetClientAuthLoading ? (
							<Box
								css={{
									borderTop: '1px solid lightgrey',
									padding: '16px 0',
									width: '230px',
									borderBottom: '1px solid lightgrey',
								}}
							>
								<Typography variant="h3" textTransform="none" textAlign="left" noWrap>
									{authData?.user.username}
								</Typography>
								<Typography variant="caption" display="block" color={theme.palette.grey[900]} noWrap>
									{authData?.user.email ?? ''}
								</Typography>
							</Box>
						) : (
							<>
								<Skeleton variant="text" width={pxToRem(90)} height={pxToRem(16)} />
								<Skeleton variant="text" width={pxToRem(100)} height={pxToRem(12)} />
							</>
						)}
					</Box>
				</Box>
			}
			renderContent={({ close }) => {
				return (
					<List css={{ width: 250 }}>
						{listActions.map((action, index) => {
							return (
								<Fragment key={action.title + index}>
									{action.subMenu ? (
										<Popover
											anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
											trigger={
												<ListItemButton
													onClick={() => {
														action.onClick();
														close();
													}}
												>
													<ListItemIcon>{action.icon}</ListItemIcon>
													<ListItemText primary={action.title} />
												</ListItemButton>
											}
											content={action.subMenu.map((e, index) => {
												return (
													<ListItemButton
														key={index}
														onClick={() => {
															// action.onClick();
															e.onClick();
															close();
														}}
													>
														{/* <ListItemIcon>{action.icon}</ListItemIcon> */}
														{e.icon}
														<ListItemText sx={{ marginLeft: '8px' }} primary={e.label} />
													</ListItemButton>
												);
											})}
										></Popover>
									) : (
										<ListItemButton
											onClick={() => {
												action.onClick();
												close();
											}}
										>
											<ListItemIcon>{action.icon}</ListItemIcon>
											<ListItemText primary={action.title} />
										</ListItemButton>
									)}
									{index < listActions.length - 1 && <Divider />}
								</Fragment>
							);
						})}
					</List>
				);
			}}
		/>
	);
};

//--------------------------------------------------------------------------------------//
//                                        Footer                                        //
//--------------------------------------------------------------------------------------//

const Footer = () => {
	const theme = useTheme();

	const { t } = useTranslation();

	return (
		<Box
			sx={{
				marginTop: pxToRem(28),
				marginBottom: pxToRem(16),
				marginLeft: pxToRem(35),
			}}
		></Box>
	);
};

// NavList

const NavList = ({
	text,
	icon,
	initialCollapseOpen,
	subItems,
	comingSoon,
	external,
	link,
	setFillPathOnHover,
	isActive,
}: NavListItem) => {
	const findOut = useCallback(() => {
		const ok = subItems?.some((e) => {
			return e.isActive;
		});

		return initialCollapseOpen ?? Boolean(ok);
	}, [initialCollapseOpen, subItems]);

	const [openCollapse, toggleCollapse] = useToggle(findOut());

	const handleClick = () => {
		if (subItems) {
			toggleCollapse();
		}
	};

	useEffect(() => {
		toggleCollapse(findOut());
	}, [findOut, toggleCollapse]);

	return (
		<>
			<ItemButton
				key={text}
				icon={icon}
				text={text}
				active={isActive ?? false}
				link={link}
				onClick={handleClick}
				external={external}
				comingSoon={comingSoon}
				setFillPathOnHover={setFillPathOnHover}
				collapsible={subItems && !_.isEmpty(subItems)}
				open={openCollapse}
			/>
			{subItems ? (
				<Collapse in={openCollapse} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						{subItems.map((e, index, array) => {
							return (
								<ListItem
									key={index}
									link={e.link}
									index={index}
									arrayLength={array.length}
									label={e.label}
									toggleCollapse={toggleCollapse}
									openCollapse={openCollapse}
									isActive={e.isActive ?? false}
								/>
							);
						})}
					</List>
				</Collapse>
			) : null}
		</>
	);
};

// ListItem
type ListItemProps = {
	link: string;
	index: number;
	arrayLength: number;
	label: string;
	toggleCollapse: () => void;
	openCollapse: boolean;
	isActive: boolean;
};

const ListItem = ({ link, index, arrayLength, label, toggleCollapse, openCollapse, isActive }: ListItemProps) => {
	const theme = useTheme();
	const branch = getBranch(index, arrayLength);

	return (
		<ListItemButton
			key={index}
			{...(link && {
				LinkComponent: Link,
				to: link,
			})}
			sx={{
				pl: '42px',
				py: 0,
				position: 'relative',
				'&:hover': {
					bgcolor: 'unset',
					'.branch-label': {
						bgcolor: theme.palette.grey[200],
					},
				},
				'& .branch-label': {
					bgcolor: isActive ? lighten(theme.palette.primary.main, 0.9) : 'none',
					color: isActive ? theme.palette.primary.main : 'auto',
				},
			}}
			disableRipple
			disableTouchRipple
		>
			{branch}
			<Typography className="branch-label" ml="16px" py="4px" px="8px" borderRadius="12px">
				{label}
			</Typography>
		</ListItemButton>
	);
};
