import { useCallback, useEffect, useTransition } from 'react';

import { css, cx } from '@emotion/css';
import { IconButton, Skeleton } from '@mui/material';
import Button from '@mui/material/Button';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MoreVertical, UserSquare2 } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';

import { FRONT_PATH_NAMES, classNames } from '@carbonmaps/shared/utils/constants';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import DeleteConfirm from '@carbonmaps/ui/components/saas/DeleteConfirm';
import { formatNumber } from '@carbonmaps/ui/utils/numberFormat';

import Popover from '../../../components/Popover';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useSearchQueryParams } from '../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';

const styles = {
	listContainer: css({
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr',
		gap: '24px',
	}),
};

const ClientList = () => {
	const [searchQueryParams] = useSearchQueryParams();

	const queryClient = useQueryClient();
	// ---- invalidateQueries findDevisClient at first  ---- //
	useEffect(() => {
		return () => {
			queryClient.invalidateQueries({ queryKey: ['findDevisClient'] });
		};
	}, []);

	const { data: clientsData, isLoading } = useQuery({
		queryKey: ['findDevisClient', { input: searchQueryParams.input }] as const,
		queryFn: async ({ queryKey }) => {
			const [key, { input }] = queryKey;

			const clients = await Parse.Cloud.run('findClient', { input });

			return clients;
		},
	});

	const analytics = useJuneTrack();
	const trackEvent = useCallback(
		(name: string, options?: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Simulate' },
			});
		},
		[analytics],
	);

	useEffect(() => {
		trackEvent('Simulate Carbon Quote Land Quotes List');
	}, [trackEvent]);

	if (isLoading) {
		return (
			<div className={cx('', styles.listContainer)}>
				{Array.from({ length: 6 }).map((_, index) => {
					return (
						<Skeleton variant="rectangular" width="100%" sx={{ borderRadius: '16px' }}>
							<ClientCard
								key={index}
								client={{
									name: 'loading...',
									quotes: [],
									city: 'loading...',
									address: 'loading...',
								}}
							/>
						</Skeleton>
					);
				})}{' '}
			</div>
		);
	}

	return (
		<div className={cx('', styles.listContainer)}>
			{clientsData?.map((client: any, index: any) => {
				return <ClientCard key={index} client={client} />;
			})}
		</div>
	);
};

export default ClientList;

type ClientCardProps = {
	client: any;
};

const cardStyles = {
	container: css({
		minWidth: '341px',
		maxWidth: '500px',
		borderRadius: '16px',
		border: cn('1px solid', siteColors.grey500),
		overflow: 'hidden',
	}),
	iconContainer: css({
		height: '36px',
		width: '36px',
		background: siteColors.grey300,
		borderRadius: '8px',
		marginRight: '10px',
	}),
	text1: css({
		color: siteColors.text,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: 'normal',

		marginBottom: '8px',
	}),
	text2: css({
		color: siteColors.grey700,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '12px',
	}),
	text3: css({
		color: siteColors.grey900,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: 'normal',
	}),
	button: css({
		// unset: 'all',
		border: 'none',
		display: 'flex',
		padding: '8px',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '8px',
		borderRadius: '8px',
		background: siteColors.grey400,
		cursor: 'pointer',
	}),
};

const ClientCard = ({ client }: ClientCardProps) => {
	const queryClient = useQueryClient();
	const { t } = useTranslation();

	const { mutateAsync: deleteClient } = useMutation({
		mutationKey: ['deleteClient', { clientId: client.objectId }] as const,
		mutationFn: async (payload: any) => {
			try {
				const { clientId: objectId } = payload;
				const pClient = await new Parse.Query(classNames.CLIENT).get(objectId);
				const promise2 = Promise.all(
					(pClient.get('quotes') || []).map(async (pQuote: Parse.Object) => {
						const exists = await pQuote.exists();

						if (exists) {
							await pQuote.destroy();
						}
					}),
				);
				await promise2;

				// for report client
				const reportClient = await new Parse.Query(classNames.CACHE_REPORT_CLIENT).equalTo('originalClientId', objectId).first();
				if (reportClient) {
					await reportClient?.destroy({});
				}
				const promise1 = pClient.destroy({});
				await promise1;

			} catch (error) {
				console.log('---------- deleteClient error ------------', error);
				return Promise.reject(error);
			}
		},
		onSuccess(/* data, variables, context */) {
			queryClient.invalidateQueries({ queryKey: ['findDevisClient'] });
			queryClient.invalidateQueries({ queryKey: ['findReportsClientTable'] });
		},
	});

	return (
		<div className={cx('', cardStyles.container)} css={{ position: 'relative' }}>
			<div
				css={{
					position: 'absolute',
					top: '16px',
					right: '16px',
				}}
			>
				<Popover
					trigger={
						<IconButton
							disableRipple
							sx={{
								borderRadius: 'unset',
								padding: '2.5px 4px',
								marginRight: '-4px',
							}}
						>
							<MoreVertical size={20} color={siteColors.grey700} />
						</IconButton>
					}
					renderContent={({ close }) => {
						return (
							<div>
								<DeleteConfirm
									title={t('Supprimer ce client?')}
									onClick={() => {
										deleteClient({ clientId: client.objectId });
									}}
									anchorOrigin={{
										horizontal: 'right',
										vertical: 'bottom',
									}}
								>
									<div>
										<BButton
											onClick={() => {
												// close();
											}}
											label="Supprimer"
											variant="tertiary"
											addStyles={{ color: 'black' }}
										/>
									</div>
								</DeleteConfirm>
							</div>
						);
					}}
				/>
			</div>

			<Link to={`${FRONT_PATH_NAMES.quote}/client/${client.objectId}`} css={{ textDecoration: 'none' }}>
				<div
					className={cx('flexColumn')}
					css={{
						padding: '16px',
						alignItems: 'flex-start',
						gap: '16px',
						flexShrink: 0,
						background: siteColors.common.white,
						'&:hover': {
							background: siteColors.grey200,
						},
					}}
				>
					<div className={cx('flexRow width100 justifyBetween ')}>
						<div className={cx('flexRow')}>
							<div className={cx('flexCenter', cardStyles.iconContainer)}>
								<UserSquare2 size={20} color={siteColors.grey700} />
							</div>

							<div>
								<div className={cx('', cardStyles.text1)}>{client.name}</div>
								<div className={cx('', cardStyles.text2)}>
									{formatNumber(client.quotes?.length || 0, undefined, 0)} prestations
								</div>
							</div>
						</div>
					</div>

					<div className={cx('', cardStyles.text3)}>
						{client.city}
						<br />
						{client.address}
					</div>

					<button className={cx('', cardStyles.button)}>{t('Voir les prestations')}</button>
				</div>
			</Link>
		</div>
	);
};
