import { css } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { ClipboardCheck } from 'lucide-react';

import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import IconContainer from '../../../components/layout/list/IconContainer';
import ListHeader from '../../../components/layout/list/ListHeader';
import LightTooltipGlossary from '../../../components/LightTooltipGlossary';
import { useGlossary } from '../../../hooks/useGlossary';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useTranslation } from '../../../hooks/useTranslation';
import { cn, siteColors } from '../../../lib/colors';
import { useCountModelization } from '../../../lib/react-query/features/modelization/modelization.hooks';

const styles = {
	intro: css({
		padding: '32px 32px 8px 32px',
	}),

	bg: css({
		// background: cn(siteColors.blueGray10, '!important'),
	}),
};

const ModelizationHeader = () => {
	const theme = useTheme();
	const { t } = useTranslation();
	//glossary
	const { g } = useGlossary();
	const { selectedYear } = useYearSelection();

	// ---- fetch count comparisons ---- //		// ---- fetch data --- //
	const {
		key,
		result: { data: modelizationCount },
	} = useCountModelization({
		params: { period: selectedYear },
	});

	return (
		<>
			<ListHeader
				title={t('modelizations-title-module')}
				subtitle={`${modelizationCount ?? ''} ${toLowerCase(t('products'))}`}
				icon={
					<IconContainer
						element={<ClipboardCheck size={32} color={theme.palette.common.white} />}
						//color={theme.palette.grey[800]}
						color={siteColors.blueGray500}
					/>
				}
				className={styles.bg}
			/>
			<>
				<div className={styles.intro}>
					{t('Cet écran vous permet de connaître la')}{' '}
					<LightTooltipGlossary title={g('methode-de-modelisation-produit-')}>
						<span className="underline">{toLowerCase(t('Modélisation'))}</span>
					</LightTooltipGlossary>{' '}
					{t('utilisée pour un produit dans les autres modules et sur votre dashboard', {
						product_lowercase: toLowerCase(t('product')),
					})}
					.<br />
					{t('En cas de')}{' '}
					<LightTooltipGlossary title={g('modelization-custom')}>
						<span className="underline">{toLowerCase(t('Modélisation personnalisée'))}</span>
					</LightTooltipGlossary>{' '}
					{t('il vous permet également de comparer l’impact de la personnalisation sur le scoring de votre produit', {
						product_lowercase: toLowerCase(t('product')),
					})}
					.
				</div>
			</>
		</>
	);
};

export default ModelizationHeader;
