import { productCompanyCount } from '@carbonmaps/ui/actions/product.actions';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useYearSelection } from '../../../../../hooks/useImpactYear';
import { useSearchQueryParams } from '../../../../../hooks/useSearchQueryParams';
import { MAX_NUMBER_OF_PRODUCTS_TO_DISPLAY } from '../../../../../utils/constants';
import ProductsFilter from './ProductsFilter';
import ProductsTable from './ProductsTable';
import ProductTradeoff from './ProductTradeoff';

const ProductsList = () => {
	const [searchQueryParams] = useSearchQueryParams();
	const { selectedYear } = useYearSelection();

	//for Remove Products graph for dataset of more than 10k products
	const { data: productCount, isLoading } = useQuery({
		queryKey: ['getProductCompanyCount', { period: selectedYear }],
		queryFn: productCompanyCount,
	});

	return (
		<div
			css={{
				'.filterContainer-wrapper': {
					paddingLeft: 0,
					paddingRight: 0,
					top: `${_.toString(58 * 2 + 3)}px`,
				},
			}}
		>
			<ProductsFilter />
			<div className="flexColumn width100 gap24">
				{!isLoading && productCount < MAX_NUMBER_OF_PRODUCTS_TO_DISPLAY ? (
					<ProductTradeoff filterOptions={searchQueryParams} />
				) : null}
				<ProductsTable filterOptions={searchQueryParams} manualSortBy />
			</div>
		</div>
	);
};

export default ProductsList;
