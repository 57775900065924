import { isWhitespace } from '@carbonmaps/shared/utils/utils';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BInput from '@carbonmaps/ui/components/saas/BInput';
import { css, cx } from '@emotion/css';
import { zodResolver } from '@hookform/resolvers/zod';
import { Autocomplete, Button, IconButton, TextField } from '@mui/material';
import { CellContext } from '@tanstack/react-table';
import _ from 'lodash';
import { Check } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import z from 'zod';
import {
	getContactStringValue,
	SupplierContactSubRowData,
} from '../../containers/questionForms/prepare/QuestionFormSuppliersTable';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';

const contactSchema = z.object({
	contactFirstName: z.string().min(1),
	contactLastName: z.string().min(1),
	contactEmail: z.string().email(),
	contactLanguage: z.string().min(1),
});

const styles = {
	input: css({
		'.MuiInputBase-root': {
			borderRadius: '4px!important',
		},
	}),
	inputDisabled: css({
		'.MuiInputBase-root': {
			borderRadius: '4px!important',
			overflow: 'hidden',
		},
		'& .MuiInputBase-input': {
			padding: '10.5px 10px',
			background: '#F4F2F7!important',
		},
		fieldset: {
			borderRadius: '4px!important',
			border: '1px solid #F4F2F7!important',
		},
		'& .MuiInputBase-input.Mui-disabled': {
			WebkitTextFillColor: '#474154',
		},
	}),

	rowFromContainer: css({
		gap: 8,
	}),
	select: css({
		fieldset: {
			border: '1px solid #9587AE',
			borderRadius: '4px!important',
			// background: 'red',
		},
		'.MuiOutlinedInput-root': {
			padding: '3px 22px 3px 12px !important',
		},
	}),
	selectDisabled: css({
		pointerEvents: 'none',
		'.MuiAutocomplete-inputRoot': {
			background: '#F4F2F7!important',
			borderRadius: '4px!important',
		},
		fieldset: {
			border: '1px solid #F4F2F7',
			borderRadius: '4px!important',
		},
		'.MuiOutlinedInput-root': {
			padding: '3px 22px 3px 12px !important',
		},
	}),

	flexGrow: css({
		flexGrow: 1,
	}),
	emailInputMaxWidth: css({
		// width: 300,
		maxWidth: 430,
		minWidth: 300,
	}),

	noPointerEvents: css({
		pointerEvents: 'none',
	}),
};

type Props = {
	// info: CellContext<SupplierContactSubRowData, any>;
	data: SupplierContactSubRowData;
	onSubmit?: (data: Record<string, any>) => void;
};

const SupplierContactRowForm = ({ /* info, */ data, onSubmit }: Props) => {
	const { t } = useTranslation();

	const [editMode, setEditMode] = useState(() => {
		return Boolean(data.isEdited);
	});

	// const value = getContactStringValue(data);
	const labels = {
		en: t('Anglais'),
		fr: t('Français'),
		it: t('Italien'),
		es: t('Espagnol'),
	};

	const form = useForm({
		resolver: zodResolver(contactSchema),
		defaultValues: {
			..._.pick(data, ['contactFirstName', 'contactLastName', 'contactEmail', 'contactLanguage']),
			contactLanguage: data.contactLanguage || 'en',
		},
	});

	const _onSubmit = form.handleSubmit(
		async (values) => {
			onSubmit?.(values);
			setEditMode(false);
		},
		async (values) => {
			console.log('error', values);
		},
	);

	// if (isWhitespace(value) && !editMode) {
	// 	return (
	// 		<div
	// 			className={cx('pointer')}
	// 			onClick={() => {
	// 				setEditMode(true);
	// 			}}
	// 		>
	// 			+ {t('Ajouter les informations du contact')}
	// 		</div>
	// 	);
	// }

	return (
		<div className={cx('flexRow alignCenter', styles.rowFromContainer)}>
			<BInput
				disabled={!editMode}
				placeholder={t('first-name-label')}
				{...form.register('contactFirstName')}
				className={cx('', !editMode ? styles.inputDisabled : styles.input)}
			/>
			<BInput
				disabled={!editMode}
				placeholder={t('last-name-label')}
				{...form.register('contactLastName')}
				className={cx('', !editMode ? styles.inputDisabled : styles.input)}
			/>
			<BInput
				disabled={!editMode}
				placeholder={`${t('input-email-label')} (${t('obligatoire')})`}
				{...form.register('contactEmail')}
				className={cx('', !editMode ? styles.inputDisabled : styles.input, styles.flexGrow, styles.emailInputMaxWidth)}
			/>

			<Autocomplete
				sx={{ width: '120px' }}
				className={cx('', editMode ? styles.select : styles.selectDisabled)}
				options={['en', 'fr', 'es', 'it']}
				{...form.register('contactLanguage')}
				defaultValue={form.control._defaultValues.contactLanguage || 'en'}
				disableClearable
				onChange={(_event: any, newValue: string | null) => {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					form.setValue('contactLanguage', newValue!);
				}}
				getOptionLabel={(option) => {
					return ((labels as any)[option as any] as any) || labels['en'];
				}}
				renderInput={(params) => {
					return <TextField {...params} fullWidth />;
				}}
			/>
			<IconButton
				disableRipple
				disableTouchRipple
				sx={{
					backgroundColor: siteColors.primary,
					color: 'white',
					borderRadius: '4px',
					'&:hover': { backgroundColor: siteColors.primaryHover },
				}}
				className={cx(editMode ? '' : 'hidden', editMode ? '' : styles.noPointerEvents)}
				onClick={() => {
					_onSubmit?.();
				}}
			>
				<Check />
			</IconButton>
		</div>
	);
};

export default SupplierContactRowForm;
