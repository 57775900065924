import { StyleSheet } from '@react-pdf/renderer';

export const FONT_FAMILY_STYLE = {
	fontFamily: 'Inter',
};

export const pdfStyles = StyleSheet.create({
	body: {
		paddingTop: 35,
		paddingLeft: 21,
		paddingRight: 21,
		paddingBottom: 35,
	},
});
