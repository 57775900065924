import { css, cx } from '@emotion/css';
import { Tab } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import CardTitle from '../../../../../components/dashboard/v2/CardTitle';
import Skeleton from '../../../../../components/Skeleton';
import CardSupplier from '../../../../../components/supplier/CardSupplier';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../lib/colors';
import { useRseQFormTabQueryParam } from '../../../../suppliers/tabs/rse/hooks/rse.hooks';
import { useGetInfoNoteData } from '../hooks/rse.hooks';

// type Props = {};

const styles = {
	tabs: css({
		'.MuiTabs-flexContainer': {
			borderBottom: cn('1px solid', siteColors.grey500),
			padding: '24 0',
			display: 'flex',
			gap: 16,
		},

		'.MuiButtonBase-root': {
			textTransform: 'none',
			fontSize: '14px !important',
			fontWeight: '400',
			color: cn(siteColors.grey700, '!important'),
			paddingTop: '22px',
			paddingBottom: '20px',
			paddingLeft: '0px !important',
			paddingRight: '0px !important',
			minWidth: '0px !important',
		},
		'.Mui-selected': {
			color: cn(siteColors.primary, '!important'),
		},
		'.MuiTab-textColorPrimary': {},
		'.MuiTabs-indicator': {
			backgroundColor: siteColors.primary,
		},
	}),

	tabsSelected: css({
		'.MuiTabs-indicator': {
			backgroundColor: `${siteColors.primary} !important`,
		},
	}),

	card: css({
		borderRadius: '16px',
		border: cn('1px solid', siteColors.grey500),
		padding: '8px 24px',
		minHeight: '179px',
		marginBottom: '54px',
	}),

	rowContainer: css({
		minHeight: 68,
		paddingTop: 20,
		paddingBottom: 20,

		flexWrap: 'nowrap !important',
	} as any),
	rowBorderBottom: css({
		borderBottom: cn('1px solid', siteColors.grey500),
	}),

	textAnswer: css({
		color: siteColors.text,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: '150%',
		// flexGrow: 1,
	}),

	textItalic: css({
		fontStyle: 'italic',
	}),
};

// const tabsData = {
// 	fakeId1: {
// 		title: 'Tab 1',
// 		couplets: [
// 			{
// 				questionLabel: 'Question 1',
// 				answerLabel: 'Answer 1',
// 			},
// 			{
// 				questionLabel: 'Question 2',
// 				answerLabel: 'Answer 2',
// 			},
// 			{
// 				questionLabel: 'Question 3',
// 				answerLabel: 'Answer 3',
// 			},
// 		],
// 	},
// 	fakeId2: {
// 		title: 'Tab 2',
// 		couplets: [
// 			{
// 				questionLabel: 'Question 4',
// 				answerLabel: 'Answer 4',
// 			},
// 			{
// 				questionLabel: 'Question 5',
// 				answerLabel: 'Answer 5',
// 			},
// 			{
// 				questionLabel: 'Question 6',
// 				answerLabel: 'Answer 6',
// 			},
// 			{
// 				questionLabel: 'Question 7',
// 				answerLabel: 'Answer 7',
// 			},
// 		],
// 	},
// };

const SupplierAnswers = (/* props: Props */) => {
	const { t } = useTranslation();
	const [questionFormId] = useRseQFormTabQueryParam();
	const { data: rseObject, isLoading } = useGetInfoNoteData({ questionFormId: _.toString(questionFormId) });

	// console.log('*****************', rseObject);

	const tabsData = _.get(rseObject, 'answerTabsData', {});
	const tabsEntries = _.entries(tabsData || {});
	const [tabValue, setTabValue] = useState(_.get(tabsEntries, '0.0'));

	useEffect(() => {
		if (!rseObject) {
			return;
		}

		// console.log('*****************', rseObject);

		setTabValue(_.get(tabsEntries, '0.0'));
	}, [rseObject]);

	const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
		setTabValue(newValue as any);
	};

	const answersData = _.get(tabsData, `${tabValue}.couplets`, []);

	if (isLoading) {
		return <Skeleton sx={{ mb: '54px', borderRadius: '16px', height: '350px' }} />;
	}

	return (
		<CardSupplier title={<CardTitle title={t('section-supplier-answers-card-title')} />} className={cx('flex1')}>
			<div className={cx('width100', styles.card)}>
				<Tabs value={tabValue} onChange={handleChange} className={cx(styles.tabs, styles.tabsSelected)}>
					{tabsEntries.map(([key, value]) => {
						return <Tab key={nanoid()} value={key} label={_.get(value, 'title')} sx={{ padding: '24px 0px' }} />;
					})}
				</Tabs>

				<div>
					{_.isEmpty(answersData) ? (
						<div className={cx('flexRow alignCenter justifyCenter', styles.rowContainer)}>
							{t('section-supplier-answers-no-data')}
							{/* Pas de données pour cette section du questionnaire */}
						</div>
					) : (
						answersData.map((couplet: any, index: number) => {
							const isLastIndex = index === _.get(tabsData, `${tabValue}.couplets`, []).length - 1;
							return (
								<AnswerRow
									key={index}
									questionLabel={couplet.questionLabel}
									answerLabel={couplet.answerLabel}
									isLast={isLastIndex}
								/>
							);
						})
					)}
				</div>
			</div>
		</CardSupplier>
	);
};

export default SupplierAnswers;

const AnswerRow = ({
	questionLabel,
	answerLabel,
	isLast,
}: {
	questionLabel: string;
	answerLabel: string;
	isLast: boolean;
}) => {
	const { t } = useTranslation();

	return (
		<div className={cx('flexRow flexRow alignCenter', styles.rowContainer, isLast ? '' : styles.rowBorderBottom)}>
			<div css={{ width: '50%', paddingRight: 32 }}>{questionLabel}</div>
			<div
				className={cx('', styles.textAnswer, answerLabel ? '' : styles.textItalic)}
				css={{ maxWidth: '50%', /* whiteSpace: 'nowrap', */ wordWrap: 'break-word', overflowWrap: 'break-word' }}
			>
				{answerLabel || t('section-supplier-answers-no-answer')}
			</div>
		</div>
	);
};
