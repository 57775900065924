import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { css } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { createSearchParams } from 'react-router-dom';

import { ALL_YEAR_OPTION_VALUE, FRONT_PATH_NAMES, WORDING_TERMS } from '@carbonmaps/shared/utils/constants';
import { TableComponent, getStickyHeaderStyle } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { capitalizeFirstLetter, toLowerCase } from '@carbonmaps/ui/utils/utils';

import { HelpCircle } from 'lucide-react';
import LightTooltipGlossary from '../../../components/LightTooltipGlossary';
import TableHeaderCell from '../../../components/table/TableHeaderCell';
import ImpactCarbonePopover from '../../../components/table/popover/ImpactCarbonePopover';
import WaterImpactPopover from '../../../components/table/popover/WaterImpactPopover';
import { useGlossary } from '../../../hooks/useGlossary';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useJuneTrack } from '../../../hooks/useJuneTrack';
import { useQueryParams } from '../../../hooks/useSearchParams';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';
import { useFindReportsClientTable } from '../../../lib/react-query/features/sales/sales.hooks';
import { CARBON_INDICATOR } from '../../../utils/constants';

const stickyHeaderStyle = getStickyHeaderStyle(/* 1499 */);

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
			// width: '100%'
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			// background: theme.palette.common.white,
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
		'.bordered tr th:not(:nth-of-type(1))  div': {
			justifyContent: 'flex-end!important',
			paddingRight: '0!important',
			alignItems: 'right',
		},
		'.bordered tr td:not(:nth-of-type(1)) div,.bordered tr td:not(:nth-of-type(1)) button': {
			justifyContent: 'flex-end!important',
			paddingRight: '0!important',
			alignItems: 'right',
		},
		'.bordered tr td:nth-of-type(1)': {
			padding: '0!important',
		},
	};
};

const formatThenSetData = (res: any, paramsUrlString: any, t: any, indicator: 'carbon' | 'water') => {
	if (!res?.length) return [];
	return res.map((item: any) => {
		return {
			...item,
			link: `${FRONT_PATH_NAMES.sales}/${item.objectId}/synthesis${
				paramsUrlString ? `${paramsUrlString}&uid=${item.uid}` : `?uid=${item.uid}`
			}`,
			reference: item.isFromQuote ? t('Prospect') : `${item.nbReferences} ${t('references')}`,
			percentageN1: item[`betterQualityProduct${capitalizeFirstLetter(indicator)}`],
			percentageN2: 100 - item[`betterQualityProduct${capitalizeFirstLetter(indicator)}`],
		};
	});
};

type ClientReportsTableProps = {
	filterOptions?: any;
	manualSortBy?: boolean;
	resetPage?: number;
	// use to update row selected in export data
};

const styles = {
	popoverImpactStyle: css({ paddingRight: 0 }),
	percent: css({
		justifyContent: 'flex-end',
	}),
};

const ClientReportsTable = ({ filterOptions, manualSortBy }: ClientReportsTableProps) => {
	const analytics = useJuneTrack();
	// ---- hooks ---- //
	// get current switch mode value ( carbon or water )
	const { indicator } = useApp();
	// theme
	const theme = useTheme();
	// styles
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	// translation

	const { t } = useTranslation();
	// glossary
	const { g } = useGlossary();
	// query params
	const queryParams = useQueryParams();
	const searchParams = createSearchParams(_.omit(queryParams, ['uid']) as any)?.toString();

	const { selectedYear } = useYearSelection();

	// --- ref for fetch data table
	const fetchIdRef = useRef(0);

	//---- track event page ---- //
	const trackEvent = useCallback(
		(evenName: string, options?: any) => {
			if (!analytics) return;
			analytics.track(evenName, options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	// ---- track next page ---- //
	const trackNextPage = useCallback(() => {
		trackEvent('Explore Reports Client Next Page');
	}, [trackEvent]);

	// ---- columns table definition ---- //
	const tableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('Clients')} />;
				},
				accessor: 'label',
				editable: true,
				sortDescFirst: true,
				component: 'TagLabel',
				type: 'string',
				props: {
					style: {
						color: siteColors.text,
						fontWeight: '600 !important',
						fontSize: '14px !important',
						marginBottom: '0px !important',
					},
					onClick: (p: any) => {
						trackEvent('Explore Click Clients', {
							ProductLabel: p?.label,
						});
					},
				},
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={
								selectedYear === ALL_YEAR_OPTION_VALUE
									? t(WORDING_TERMS.IMPACT_ALL_YEAR)
									: t(WORDING_TERMS.IMPACT, { period: selectedYear })
							}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonImpactTon' : 'waterImpact'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'carbonImpactPercent' : 'waterImpactPercent',
				editable: true,
				sortDescFirst: true,
				component: 'PercentField',
				type: 'percent',
				props: {
					...(indicator === CARBON_INDICATOR
						? {
								popoverComponent: ImpactCarbonePopover,
								popoverClassName: styles.popoverImpactStyle,
								className: styles.percent,
								emptyValue: <HelpCircle color={siteColors.carbon500} />,
						  }
						: {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
								popoverComponent: WaterImpactPopover,
								popoverClassName: styles.popoverImpactStyle,
								className: styles.percent,
								emptyValue: <HelpCircle color={siteColors.water500} />,
						  }),
					decimal: 2,
				},
			},
			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={t(WORDING_TERMS.INTENSITY)}
							variant="measure"
							measure={indicator === CARBON_INDICATOR ? 'carbonIntensityKg' : 'waterIntensity'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'carbonIntensity' : 'waterIntensity',
				editable: true,
				sortDescFirst: true,
				component: 'Intensity',
				type: 'number',
				props: {
					page: 'report-client',
					...(indicator !== CARBON_INDICATOR
						? {
								color: {
									primary: siteColors.water500,
									secondary: siteColors.grey500,
								},
						  }
						: null),
					t,
				},
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							column={props.column}
							label={
								<LightTooltipGlossary title={g('g-better-quality-product')}>
									<span className={'underline'}>
										{t('better-quality-product', {
											product: t('product'),
										})}
									</span>
								</LightTooltipGlossary>
							}
							variant="measure"
							measure={'percent'}
						/>
					);
				},
				accessor: indicator === CARBON_INDICATOR ? 'betterQualityProductCarbon' : 'betterQualityProductWater',
				editable: true,
				component: 'LineBar',
				sortDescFirst: true,
				type: 'string',
				props: {
					trans: t,
					page: 'report-client',
				},
			},

			{
				Header: (props: any) => {
					return (
						<TableHeaderCell
							sx={{
								'& .table-header-component-label': { whiteSpace: 'nowrap' },
							}}
							column={props.column}
							label={
								<LightTooltipGlossary title={g('g-vs-Market-Average')}>
									<span className={'underline'}>{t('vs-ref-average')}</span>
								</LightTooltipGlossary>
							}
							variant="measure"
							measure={'percent'}
						/>
					);
				},
				accessor: `vsMarketAverage${capitalizeFirstLetter(indicator)}Percentage`,
				editable: true,
				sortDescFirst: true,
				component: 'VsMarket',
				type: 'number',
				props: {
					t,
					page: 'report-client',
				},
				disableSortBy: filterOptions?.facetFilters?.length || filterOptions?.input?.length,
			},

			{
				Header: (props: any) => {
					return <TableHeaderCell column={props.column} label={t('Volume')} variant="measure" measure={'soldUnity'} />;
				},
				accessor: 'soldItems',
				editable: true,
				sortDescFirst: true,
				type: 'number',
				component: 'Ingredient',
				props: {
					t,
					page: 'report-client',
				},
			},
		];
	}, [
		indicator,
		siteColors.carbon500,
		siteColors.water500,
		t,
		g,
		filterOptions?.facetFilters,
		filterOptions?.input,
		selectedYear,
	]);

	// -------------------------------------------------------------------------------------- //
	// --------------------------------- State definition ----------------------------------- //
	// -------------------------------------------------------------------------------------- //

	// ---- table params ( pagination, sort , size ) ---- //
	const [paramsTable, setTableParams] = useState(
		/* <GetProductTableQueryParams> */ {
			input: '',
			page: 1,
			size: 100,
			facetFilters: [],
			supplierIds: [], // Suppliers filter
			direction: 0,
			...(filterOptions?.orderBy && {
				sortingBy: {
					id: filterOptions?.orderBy,
					desc: filterOptions?.direction === 'desc',
				},
			}),
			...filterOptions,
		},
	);
	const queryClient = useQueryClient();

	// ---- invalidateQueries findReportsClientTable at first  ---- //
	useEffect(() => {
		return () => {
			queryClient.invalidateQueries({ queryKey: ['findReportsClientTable'] });
		};
	}, []);

	// ---- fetch data --- //
	const {
		key,
		result: { data: tableResultData, isLoading: isTableLoading },
	} = useFindReportsClientTable({
		params: {
			...paramsTable,
			indicator,
		},
	});

	// state to force to first page
	const [resetPage, setResetPage] = useState(0);

	// ----  when change params react table ---- //
	const updateTableParams = useCallback(
		(data: any) => {
			const { pageIndex, pageSize, ...rest } = data;
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setTableParams({
					...paramsTable,
					page: pageIndex + 1,
					size: pageSize,
					...rest,
				});
			}
		},
		[paramsTable],
	);

	// ---- handle sorting  table ---- //
	const handleSortingTable = useCallback(
		(value: any) => {
			return updateTableParams({
				...paramsTable,
				sortingBy: value,
				pageSize: paramsTable.size,
				pageIndex: paramsTable.size - 1,
			});
		},
		[paramsTable, updateTableParams],
	);

	useEffect(() => {
		setTableParams((prev: any) => {
			return { ...prev, ...filterOptions };
		});
		setResetPage((prev) => {
			return prev + 1;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterOptions]);

	let paramsUrlString = '';
	if (searchParams) paramsUrlString = `?${searchParams}`;

	return (
		<TableComponent
			isSelectable={false}
			skeleton={isTableLoading}
			loading={false}
			fetchData={updateTableParams}
			pageCount={tableResultData?.meta?.last_page || 0}
			noDataMessage={t('report-client-table-empty-data')}
			pageSize={paramsTable.size}
			columns={tableColumns}
			resetPage={resetPage}
			data={formatThenSetData(tableResultData?.data || [], paramsUrlString, t, indicator)}
			onSortingColumn={handleSortingTable}
			addStyles={stylesTable}
			manualSortBy={manualSortBy}
			stickyHeader
			stickyHeaderStyles={stickyHeaderStyle}
			transFunc={t}
			onNextClick={trackNextPage}
		/>
	);
};

export default ClientReportsTable;
