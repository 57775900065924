import { ComponentProps, Fragment, useCallback, useMemo, useRef, useState } from 'react';

import { css } from '@emotion/css';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AlertCircle, Trash2 } from 'lucide-react';
import { useToggle } from 'react-use';

import { ReactComponent as ArrowDownIcon } from '@carbonmaps/media/icons/chevron-1.svg';
import { IRole } from '@carbonmaps/shared/types/role.types';
import { IUser } from '@carbonmaps/shared/types/user.types';
import { roleLabels, ROLES_HIERARCHY } from '@carbonmaps/shared/utils/constants';
import {
	deleteSelectedRowAction,
	sendEmailSelectedRowAction,
	updateEmailSelectedRowAction,
	updateRoleSelectedRowAction,
} from '@carbonmaps/ui/actions/userRow';
import BButton from '@carbonmaps/ui/components/saas/BButton';
import BPopover from '@carbonmaps/ui/components/saas/BPopover';
import ChipsRole from '@carbonmaps/ui/components/saas/Table/ChipsRole';
import EditableEmailInput from '@carbonmaps/ui/components/saas/Table/EditableEmailInput';
import RoleCellNew from '@carbonmaps/ui/components/saas/Table/RoleCellNew';
import SearchAutocomplete from '@carbonmaps/ui/components/saas/Table/SearchAutocomplete';
import { TableComponent } from '@carbonmaps/ui/components/saas/Table/TableComponent';
import { getIsoCountryCode } from '@carbonmaps/ui/utils/utils';

import { updateSupplierUserAction } from '@carbonmaps/ui/actions/user.actions';
import { useRowTableSelection } from '@carbonmaps/ui/hooks/useRowTableSelection';
import SEO from '../../components/SEO';
import Skeleton from '../../components/Skeleton';
import AlertStatic from '../../components/Toast/AlertStatic';
import LoadingDialog from '../../components/dialogs/LoadingDialog';
import TableHeaderCell from '../../components/table/TableHeaderCell';
import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';
import { useFindSuppliersListOptions } from '../../lib/react-query/features/supplier/supplier.hooks';
import { FindUsersActionParams } from '../../lib/react-query/features/user/user.actions';
import { useCountUsersContributeur, useFindUsers } from '../../lib/react-query/features/user/user.hooks';

type DataCell = { value: Record<string, any>; row: Record<string, any>; callback: (...params: any[]) => void };

type UsersProps = {
	companyId?: string;
};

const styles = {
	dialog: css({
		'& .MuiAutocomplete-root': {
			marginBottom: '325px',
			width: '500px',
		},
	}),
};

const classes = (theme: any) => {
	return {
		table: {
			border: 'none!important',
			// width: '100%'
		},

		'.bordered td, .bordered th': {
			borderLeft: 'none!important',
			// background: theme.palette.common.white,
			borderBottom: `1px solid ${theme.palette.grey[400]}`,
		},
		'.bordered th': {
			background: theme.palette.common.white,
		},
		// hover states
		'& table tbody > tr:hover > td': {
			background: `${theme.palette.grey[200]} !important`,
		},
	};
};

const UsersList = ({ companyId }: UsersProps) => {
	const queryClient = useQueryClient();
	// theme
	const theme = useTheme();
	// styles
	const stylesTable = useMemo(() => {
		return classes(theme);
	}, [theme]);

	// translation
	const { t, i18n } = useTranslation();
	const languageCode = getIsoCountryCode(i18n.language);
	const [, setOpen] = useState(false);
	const fetchIdRef = useRef(0);
	const [pageCount, setPageCount] = useState(0);
	const [selectedRow, setSelectedRow] = useState<string[]>([]);
	const [queryParams, setQueryParams] = useState<FindUsersActionParams>({
		page: 1,
		size: 100,
		input: '',
		limit: 100,
		companyId: companyId!,
	});
	const [message, setMessage] = useState('');
	const [isOpenRowAdd, setIsOpenRowAdd] = useState(false);
	// state to toggle to edit user supplier
	const [isDialogOpen, toggleDialog] = useToggle(false);
	// state to edit an company
	const [userToEdit, setUserToEdit] = useState({});

	// for list option supplier
	const {
		result: { data: dataSuppliers, isLoading: isdataSuppliersLoading },
	} = useFindSuppliersListOptions({
		paramsTable: {
			page: 1,
			size: 10_000,
		},
	});

	// ---- action when click edit's column ---- //

	const {
		mutate: updateSupplierUser,
		isError,
		// isSuccess,
		isLoading: isLoadingUpdateSupplier,
	} = useMutation({
		mutationKey: ['updateSupplierUser'],
		mutationFn: updateSupplierUserAction,
		onSuccess: async () => {
			setMessage('Informations mises à jour');
			queryClient.invalidateQueries({ queryKey: ['findUserSuppliers'] });
			// toggle();
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const handleClickEdit = useCallback((values: any) => {
		updateSupplierUser(values);
	}, []);

	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	const {
		result: { data: usersData, isLoading: isGetUsersLoading, isSuccess: isGetUsersSuccess },
		key: findUsersQueryKey,
	} = useFindUsers({
		params: {
			...queryParams,
			companyId: authData?.session.company?.objectId ?? '',
		},
	});

	const usersTableColumns = useMemo(() => {
		return [
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('input-mail-label')} />;
				},
				accessor: 'email',
				editable: true,
				sortDescFirst: true,
				Cell: ({ value, row, callback }: DataCell) => {
					return <EditableEmailInput value={value} callback={callback} />;
				},
				disableSortBy: true,
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('last-name-label')} />;
				},
				accessor: 'fullName',
				editable: false,
				component: 'SimpleCell',
				disableSortBy: true,
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('role-label')} />;
				},
				accessor: 'role',
				editable: true,
				sortDescFirst: true,
				Cell: ({ value, row, callback }: DataCell) => {
					return <RoleCellNew row={row} value={value} callback={callback} />;
				},
				disableSortBy: true,
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('supplier-label')} />;
				},

				accessor: 'objectId',
				editable: true,
				sortDescFirst: true,
				component: 'EditSupplierUser',
				props: {
					t,
					supplierOptions: dataSuppliers || [],
					updateMyData: handleClickEdit,
					companyId: authData?.session.company?.objectId,
					isLoading: isLoadingUpdateSupplier,
				},
			},
			{
				Header: (props: any) => {
					return <TableHeaderCell valueType="string" column={props.column} label={t('actions-column')} />;
				},
				accessor: 'id',
				editable: true,
				component: 'Action',
				type: 'action',
				icon: <Trash2 color={theme.palette.grey[700]} />,
				withConfirm: true,
				callback: (value: any) => {
					confirmDelete(value);
				},
				// callbackEditSupplierUser: (value: any) => {
				// 	handleClickEdit(value);
				// },
				disableSortBy: true,
			},
		];
	}, [t, authData, isLoadingUpdateSupplier, usersData]);

	const updateQueryParams: ComponentProps<typeof TableComponent>['fetchData'] = useCallback(
		({ pageIndex, pageSize }: any) => {
			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setQueryParams({
					...queryParams,
					page: pageIndex + 1,
					size: pageSize,
				});
			}
		},
		[],
	);

	const { selectedRows } = useRowTableSelection();

	// count user contributeur

	const {
		result: { data: userContributor, isLoading: isGetUsersContributeurLoading },
		key: countUsersContributeur,
	} = useCountUsersContributeur({
		params: {
			companyId: authData?.session.company?.objectId,
		},
	});

	const data = useMemo(() => {
		return (usersData?.users ?? []).map((item: IUser) => {
			return {
				id: item,
				email: item.email,
				newEmail: item.newEmail,
				isActive: item.isActive,
				objectId: item.objectId,
				tokenChangeEmail: item.tokenChangeEmail,
				fullName: `${item.firstName || ''} ${item.lastName}`,
				role: item.role || 'xxx-no-value',
				supplierUids: item.supplierUids,
				actions: '',
			};
		});
	}, [usersData]);

	const confirmDelete = async (id: string) => {
		if (id) {
			await deleteSelectedRow({ ids: [id] });
		}
	};

	const {
		mutate: deleteSelectedRow,
		isLoading: isLoadingDelete,
		// isError,
		// isSuccess,
	} = useMutation({
		mutationKey: ['deleteSelectedRow'],
		mutationFn: deleteSelectedRowAction,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [findUsersQueryKey[0]] });
			queryClient.invalidateQueries({ queryKey: [countUsersContributeur[0]] });
			setMessage('');
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const confirmDeleteSelectedUser = async () => {
		if (selectedRows.length) {
			const ids = selectedRows.map((r: any) => {
				return r.original.objectId;
			});
			await deleteSelectedRow({ ids });
		}
	};

	const {
		mutate: updateRoleSelectedRow,
		isLoading,
		// isError,
		isSuccess,
	} = useMutation({
		mutationKey: ['updateRoleSelectedRow'],
		mutationFn: updateRoleSelectedRowAction,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [findUsersQueryKey[0]] });
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const updateRole = async (ev: any) => {
		const { key, user } = ev.currentTarget.dataset;

		if (key && user) {
			await updateRoleSelectedRow({ ids: [user], roleCode: key });
		}
	};

	const handleUpdateRoleSelectedRow = async (key: string) => {
		if (selectedRows.length) {
			const ids = selectedRows
				.filter((r: any) => {
					return r.original.role.label !== roleLabels.CONTRIBUTOR;
				})
				.map((r: any) => {
					console.log(r.original.role, r.original.role.label === roleLabels.CONTRIBUTOR);
					return r.original.objectId;
				});
			if (ids.length) {
				await updateRoleSelectedRow({ ids, roleCode: key });
			}
		}
	};

	const {
		mutate: updateEmailSelectedRow,
		isLoading: isLoadingEmail,
		// isError,
		isSuccess: isSuccessEmail,
	} = useMutation({
		mutationKey: ['updateEmailSelectedRow'],
		mutationFn: updateEmailSelectedRowAction,
		onSuccess: () => {
			return queryClient.invalidateQueries({ queryKey: [findUsersQueryKey[0]] });
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const {
		mutate: sendEmailSelectedRow,
		//isLoading,
		// isError,
		//	isSuccess,
	} = useMutation({
		mutationKey: ['sendEmailSelectedRow'],
		mutationFn: sendEmailSelectedRowAction,
		onSuccess: () => {
			return queryClient.invalidateQueries({ queryKey: [findUsersQueryKey[0]] });
		},
		onError: (error: any) => {
			if (error.message) {
				setMessage(error.message);
			}
		},
	});

	const updateEmail = async (values: any) => {
		const { currentEmail, email, type, roleLabel } = values;
		const companyId = authData?.session.company?.objectId;
		switch (type) {
			case 'sendEmailNewAccount':
				return await sendEmailSelectedRow({ email: currentEmail, type, languageCode, roleLabel, companyId });
			case 'sendEmailNewEmail':
				return await sendEmailSelectedRow({
					email: currentEmail,
					newEmail: email,
					type,
					languageCode,
					roleLabel,
					companyId,
				});
			case 'changeEmail':
				return await updateEmailSelectedRow({ email: currentEmail, newEmail: email, type, languageCode });
		}

		return;
	};
	console.log(data.length, data, 'data.length');
	return (
		<Box>
			<SEO title="Utilisateurs - Carbon Maps" />
			<Box sx={{ mb: '32px' }}>
				{isGetUsersLoading ? (
					<Skeleton>
						<Typography variant="h4" fontSize="32px" lineHeight="30px">
							XXX Utilisateurs
						</Typography>
					</Skeleton>
				) : (
					<>
						<Typography variant="h4" fontSize="32px" lineHeight="30px">
							{data.length - (userContributor?.total || 0)} / {authData?.session.company?.maxUsers} {t('users')}
						</Typography>
						<Typography variant="h2" fontSize="16px" lineHeight="30px" marginTop="16px">
							{userContributor?.total || 0} {t('contributors')}
						</Typography>
					</>
				)}
			</Box>

			<Box className="flex spaceBetween" sx={{ mb: '32px' }}>
				<Box className="flex spaceBetween">
					<BButton
						onClick={() => {
							return setIsOpenRowAdd(!isOpenRowAdd);
						}}
						iconLeft={!isOpenRowAdd ? <AddIcon /> : <RemoveIcon />}
						variant="primary"
						label={
							<Typography color="white" fontWeight={600}>
								{t('add-user-button')}
							</Typography>
						}
						addStyles={{ display: 'inline-flex', alignSelf: 'center', minWidth: 'fit-content' }}
					/>

					<Box
						onClick={() => {
							return setOpen(true);
						}}
						sx={{ margin: 0, padding: 0 }}
					>
						<BPopover
							anchorOriginVertical={'bottom'}
							anchorOriginHorizontal={'left'}
							transformOriginVertical={'top'}
							transformOriginHorizontal={'left'}
							content={(onClose) => {
								return (
									<List css={{ width: 250, margin: 0, padding: 0 }}>
										<Fragment key={'mr'}>
											<BPopover
												anchorOriginVertical={'top'}
												anchorOriginHorizontal={'right'}
												transformOriginVertical={'top'}
												transformOriginHorizontal={'left'}
												addStyles={{ height: '48px', paddingLeft: '16px' }}
												content={(onClose) => {
													return (
														<List css={{ width: 250 }}>
															{ROLES_HIERARCHY.map((role, index) => {
																return (
																	<Fragment key={role.label + index}>
																		<ListItemButton
																			onClick={() => {
																				onClose();
																				handleUpdateRoleSelectedRow(role.suffixName);
																			}}
																		>
																			<ChipsRole role={role} />
																		</ListItemButton>
																		{index < ROLES_HIERARCHY.length - 1 && <Divider />}
																	</Fragment>
																);
															})}
														</List>
													);
												}}
											>
												<BButton
													iconRight={<ArrowDownIcon />}
													variant="secondary"
													label={t('user-role-button')}
													addStyles={{
														display: 'flex',
														fontWeight: '500',
														fontSize: '16px',
														color: siteColors.text,
														justifyContent: 'space-between',
														width: '100%',
														background: 'none',
														padding: 0,
														'&:hover': { background: 'none' },
													}}
												/>
											</BPopover>
											<Divider />
										</Fragment>
										<Fragment key={'sup'}>
											<BPopover
												anchorOriginVertical={'top'}
												anchorOriginHorizontal={'right'}
												transformOriginVertical={'top'}
												transformOriginHorizontal={'left'}
												addStyles={{ height: '48px', paddingLeft: '16px' }}
												content={(onClose) => {
													return (
														<Box
															sx={{
																p: '24px',
																width: '294px',
																borderRadius: '8px',
																border: cn('1px solid', siteColors.grey500),
																background: '#FFFFFF',
																boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.4), 0px 1px 2px rgba(0, 0, 0, 0.45)',
															}}
														>
															<Typography
																textAlign={'left'}
																sx={{
																	fontWeight: 600,
																	fontSize: 16,
																}}
															>
																{t('delete-user-confirm-message-title')}
															</Typography>

															<Typography textAlign={'left'}>{t('delete-user-confirm-message-subtitle')}</Typography>

															<div
																css={{
																	display: 'flex',
																	flexDirection: 'column',
																	gap: 20,
																	width: '100%',
																	marginTop: '16px',
																}}
															>
																<BButton
																	onClick={() => {
																		onClose();
																		confirmDeleteSelectedUser();
																	}}
																	variant="warning"
																	label={
																		<Typography color="#C74600" fontWeight={600}>
																			{t('delete-user-confirm-button')}
																		</Typography>
																	}
																	addStyles={{
																		display: 'inline-flex',
																		alignSelf: 'left',
																	}}
																/>
															</div>
														</Box>
													);
												}}
											>
												<BButton
													variant="secondary"
													label={t('delete-button')}
													addStyles={{
														display: 'flex',
														fontWeight: '500',
														fontSize: '16px',
														color: siteColors.text,
														justifyContent: 'space-between',
														width: '100%',
														background: 'none',
														padding: 0,
														'&:hover': { background: 'none' },
													}}
												/>
											</BPopover>
										</Fragment>
									</List>
								);
							}}
						>
							<BButton
								iconRight={<ArrowDownIcon />}
								variant="secondary"
								label={
									<Typography color={theme.palette.common.black} fontWeight={600}>
										{t('update-button')}
									</Typography>
								}
								addStyles={{ display: 'inline-flex ', marginLeft: '8px' }}
							/>
						</BPopover>
					</Box>
				</Box>

				<Box sx={{ alignSelf: 'center' }}>
					<SearchAutocomplete
						setParamsAutocomplete={(params) => {
							setQueryParams({ ...queryParams, ...params });
						}}
						// params={queryParams}
						// data={data || []}
						isLoading={false}
						placeholder={t('user-search-placeholder')}
						options={
							data
								?.map((e: any) => {
									return [e.email, e.fullName];
								})
								.flat()
								.sort() || []
						}
					/>
				</Box>
			</Box>

			<TableComponent
				skeleton={isGetUsersLoading}
				tableName={'user'}
				fetchData={updateQueryParams}
				pageCount={pageCount}
				isSelectable
				columns={usersTableColumns}
				data={data || []}
				// confirmDelete={confirmDelete}
				pageSize={queryParams.size}
				updateRole={updateRole}
				updateEmail={updateEmail}
				isOpenRowAdd={isOpenRowAdd}
				setIsOpenRowAdd={setIsOpenRowAdd}
				addStyles={stylesTable}
			/>
			<LoadingDialog open={isLoadingDelete} content={`${t('Suppression en cours')}...`} />

			{message && <AlertStatic text={message} type="error" icon={<AlertCircle css={{ color: '#C74600' }} />} />}
		</Box>
	);
};

export default UsersList;
