import { FRONT_PATH_NAMES } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { pxToRem } from '@carbonmaps/ui/utils/styles';
import _ from 'lodash';
import { SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ITab } from '../../components/layout/page/SheetPageLayout';
import SEO from '../../components/SEO';
import TabsBar, { TabItem } from '../../components/TabsBar';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useTranslation } from '../../hooks/useTranslation';
import { TOP_BAR_HEIGHT } from '../../utils/constants';
import SuppliersHeader from './components/SuppliersHeader';
import Impacts from './tabs/impacts/Impacts';
import RsePerformance from './tabs/rse/RsePerformance';

const CONTENT_WIDTH = 'calc(100% - 4rem)';

const SuppliersGeneral = () => {
	const { t } = useTranslation();
	const { setBreadcrumbs } = useApp();

	// ---- breadCrumbs ---- //
	useEffect(() => {
		setBreadcrumbs([{ text: t('suppliers') }]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);

	const tabs: Record<string, ITab> = useMemo(() => {
		return {
			impacts: {
				title: t('impacts'),
				element: <Impacts />,
				tabName: 'Impacts',
			},
			// risks: {
			// 	title: t('risks'),
			// 	element: <h1>{t('risks')}</h1>,
			// 	disabled: true,
			// },
			rse: {
				title: t('rse-performance'),
				element: <RsePerformance isGeneralView={true} />,
				tabName: 'CSR Performance',
			},
		};
	}, [t]);

	const params = useParams();
	const currentTab = params.tab || _.keys(tabs)[0];

	const navigate = useNavigate();

	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(options: any) => {
			if (!analytics) return;
			analytics.track('Explore Suppliers Switch Tab', options, {
				context: { category: 'Explore' },
			});
		},
		[analytics],
	);

	const handleTabChange = (_: SyntheticEvent, value: string) => {
		if (analytics) {
			if ((tabs[value] as any)?.tabName === 'CSR Performance') {
				analytics.track(
					'Suppliers Performances Land',
					{},
					{
						context: { category: 'Explore' },
					},
				);
			}
		}
		trackEvent({
			SuppliersGeneralViewTabTarget: (tabs[value] as any)?.tabName,
		});
		navigate(`${FRONT_PATH_NAMES.suppliers.general}/${value}`);
	};

	const renderTabs = (
		<TabsBar
			value={currentTab}
			onChange={handleTabChange}
			sticky
			sx={{
				mb: pxToRem(50),
				width: CONTENT_WIDTH,
				mx: 'auto',
				top: TOP_BAR_HEIGHT,
				'&.sticky': { width: '100%', borderRadius: 0 },
				marginTop: 3,
				marginBottom: 0,
			}}
		>
			{Object.entries(tabs).map(([tabKey, { title, disabled }]) => {
				return <TabItem key={tabKey} value={tabKey} label={title} disabled={disabled} />;
			})}
		</TabsBar>
	);

	const renderTabContent = (
		<div
			style={{
				width: CONTENT_WIDTH,
				margin: 'auto',
			}}
		>
			{tabs[currentTab].element}
		</div>
	);

	return (
		<>
			<SEO title={t('Fournisseurs') + ' - CarbonMaps'} />
			<SuppliersHeader />
			{renderTabs}
			{renderTabContent}
		</>
	);
};

export default SuppliersGeneral;
