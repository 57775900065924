import { isWhitespace } from '@carbonmaps/shared/utils/utils';
import { displayValue } from '@carbonmaps/ui/utils/numberFormat';
import { Page, Text, View } from '@react-pdf/renderer';
import { TFunction } from 'i18next';
import { cn, siteColors } from '../../../../lib/colors';
import { GraphItem, PDFHorizontalGraph, stripEnd } from './FacetsPage';
import { FONT_FAMILY_STYLE, pdfStyles } from './pdfStyles';
import { ImpactKPICard, PRODUCT_TABLE_PRODUCT_LABEL_MAX_LENGTH, truncateText } from './SummaryPage';
import { UseQuoteReturn } from './useQuote';

const GroupPage = (props: UseQuoteReturn) => {
	const { Unit1, Unit3, color500, groupPage, orderedFacetsColors, t } = props;

	return groupPage.groupsData.map((group) => {
		const horizontalGraphData: GraphItem[] = group.preferredFacet.categories.map((categoryItem, index) => {
			return {
				name: categoryItem.name,
				percent: categoryItem.percent,
				color: orderedFacetsColors[index] || orderedFacetsColors[orderedFacetsColors.length - 1],
			};
		});

		return (
			<Page size="A4" style={pdfStyles.body}>
				<View style={{ marginBottom: 24 }}>
					<Text style={{ fontSize: 20, fontWeight: 'light', ...FONT_FAMILY_STYLE, marginBottom: 22 }}>
						{!isWhitespace(group.name) ? group.name : t('Sans nom')}
					</Text>
					<ImpactKPICard
						value={group.totalImpact}
						color500={color500}
						Unit1={Unit1}
						text={t('Impact carbone pour cette section')}
					/>
				</View>

				<View style={{ marginBottom: 25 }}>
					<SectionHead text1={t('Par') + ' ' + group.preferredFacet.name} text2={undefined} />
					<PDFHorizontalGraph graphData={horizontalGraphData} />
				</View>

				{group.preferredFacet.categories.map((categoryItem, index: number) => {
					return (
						<View key={index} style={{ marginBottom: 24 }}>
							<View style={{ marginBottom: 12 }} wrap={false}>
								<Text style={{ fontSize: 15, fontWeight: 'light', ...FONT_FAMILY_STYLE, marginBottom: 8 }}>
									{categoryItem.name}
								</Text>
								<Text style={{ fontSize: 8, ...FONT_FAMILY_STYLE, marginBottom: 8 }}>
									{t('Total')} : {stripEnd(displayValue(categoryItem.totalImpact))}{' '}
									<Unit1 style={{ color: siteColors.grey700 }} subFontSize={4} />
								</Text>
							</View>

							<View>
								{/* === */}
								{categoryItem.products.map((product, index: number) => {
									return (
										<View
											key={index}
											wrap={false}
											style={{
												borderTop: cn('1px solid', siteColors.grey300),
												paddingVertical: 9,
											}}
										>
											<View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 5 }}>
												<View style={{ /* flexGrow: 1, */ width: 250 /* backgroundColor: 'yellow' */ }}>
													<Text style={{ fontSize: 8, ...FONT_FAMILY_STYLE }}>
														{truncateText(product.name, PRODUCT_TABLE_PRODUCT_LABEL_MAX_LENGTH)}
													</Text>
												</View>

												<View style={{ width: 70, textAlign: 'right' }}>
													<Text
														style={{
															fontSize: 8,
															...FONT_FAMILY_STYLE,
															// backgroundColor: 'yellow',
														}}
													>
														{stripEnd(displayValue(product.units, undefined, 0))}{' '}
														<Text style={{ color: siteColors.grey600 }}>{t('unités')}</Text>
													</Text>
												</View>

												<View style={{ width: 100, textAlign: 'right' /* backgroundColor: 'yellow' */ }}>
													<Text
														style={{
															fontSize: 8,
															...FONT_FAMILY_STYLE,
														}}
													>
														{stripEnd(displayValue(product.intensity))}{' '}
														<Unit3 subFontSize={4} style={{ color: siteColors.grey600 }} t={t} />
													</Text>
												</View>

												<View style={{ width: 100, textAlign: 'right' /*  backgroundColor: 'yellow' */ }}>
													<Text
														style={{
															fontSize: 8,
															...FONT_FAMILY_STYLE,
														}}
													>
														{stripEnd(displayValue(product.impact))}{' '}
														<Unit1 subFontSize={4} style={{ color: siteColors.grey600 }} />
													</Text>
												</View>
											</View>
										</View>
									);
								})}
							</View>
						</View>
					);
				})}

				<PageFooter t={t} />
			</Page>
		);
	});
};

export default GroupPage;

const SectionHead = ({ text1, text2 }: { text1: string; text2: string | undefined }) => {
	return (
		<View style={{ marginBottom: 12 }}>
			<Text style={{ fontSize: 10, ...FONT_FAMILY_STYLE, marginBottom: text2 ? 8 : 0 }}>{text1}</Text>
			<Text style={{ fontSize: 8, color: siteColors.grey700, ...FONT_FAMILY_STYLE }}>{text2}</Text>
		</View>
	);
};

export const PageFooter = ({ t }: { t: TFunction }) => {
	return (
		<View
			style={{
				// ===
				position: 'absolute',
				bottom: 10,
				left: 21,
				right: 21,
				// ===
				fontSize: 9,
				...FONT_FAMILY_STYLE,
				// ===
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
			}}
			fixed
		>
			<Text
				// style={{
				// 	fontSize: 9,
				// 	...FONT_FAMILY_STYLE,
				// }}
				render={({ pageNumber, totalPages }) => {
					return t('page-numbering', { pageNumber, totalPages });
				}}
			/>
			<Text>&nbsp;&nbsp;&nbsp;&nbsp;</Text>
			<Text>&nbsp;&nbsp;&nbsp;&nbsp;</Text>
		</View>
	);
};
