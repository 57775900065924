import { ElementRef, useEffect, useMemo, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Box, Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ChevronDown, ChevronLeft, ChevronRight } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { CARBON_INDICATOR } from '@carbonmaps/shared/utils/constants';
import { STATUS_QUOTE } from '@carbonmaps/shared/utils/simulation';
import ChipsStatus from '@carbonmaps/ui/components/saas/Table/ChipsStatus';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';
import { displayValue, hasDecimal } from '@carbonmaps/ui/utils/numberFormat';

import Skeleton from '../../../../../../components/Skeleton';
import DashboardTooltip from '../../../../../../components/dashboard/DashboardTooltip';
import Unit from '../../../../../../components/units/Unit';
import { useSearchQueryParams } from '../../../../../../hooks/useSearchQueryParams';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import { cn, siteColors } from '../../../../../../lib/colors';

// type Props = {};

const styles = {
	container: css({
		// paddingTop: 8,
		// paddingBottom: 8,
		padding: 24,
		paddingTop: 16,
		// gap: 8,
		height: '246px',
		flex: '1 0 0',
		borderRadius: '16px',
		border: cn('1px solid', siteColors.grey500),
		// background: 'red',
		background: 'white',

		display: 'flex',
		flexDirection: 'column',
	}),

	graphContainer: css({
		display: 'flex',
		// justifyContent: 'space-between',
		gap: '16px',
		flex: '1 0 0',
		justifyContent: 'center', // Centre les éléments horizontalement
	}),

	text1: css({
		color: siteColors.grey900,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 600,
	}),
	textGraphPagination: css({
		color: siteColors.grey900,
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: 'normal',
	}),

	btn1: css({
		color: cn(siteColors.grey800, '!important'),
		textAlign: 'center',
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		fontFamily: 'Inter',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: '400!important',
		lineHeight: 'normal',
		textTransform: 'capitalize!important',
		padding: '0!important',
		'&:hover': {
			background: 'transparent!important',
		},
		// visibility: 'hidden',
		display: 'none!important',
	} as any),

	tooltipContainer: css({
		borderRadius: '8px',
		border: cn('1px solid', siteColors.grey500),
		background: siteColors.common.white,
		boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.45), 0px 4px 16px 0px rgba(0, 0, 0, 0.40)',
		padding: '16px',
		gap: 4,
		minWidth: '224px',
	}),
};

const QuoteGraph = (/* {}: Props */) => {
	const { indicator } = useApp();
	const params = useParams();

	const [searchQueryParams] = useSearchQueryParams();

	const clientId = params.clientId || '---';

	const { data: devisData, isLoading: isTableLoading } = useQuery({
		queryKey: [
			'findDevisForClientTable',
			{ clientId, input: searchQueryParams.input, indicator, isGraph: true },
		] as const,
		queryFn: async ({ queryKey }) => {
			const { clientId, input, indicator, isGraph } = queryKey[1];

			const result = await Parse.Cloud.run('findDevis', { clientId, input, isGraph, indicator });
			return result.data[0].graphQuoteSlices;
		},
	});

	const devisSlices = useMemo(() => {
		if (!devisData) return [[]];

		// const _devisData = devisData.slice(0);
		// // _devisData.sort((a: any, b: any) => b.amount - a.amount);

		// const slices = [];
		// const sliceSize = 10;

		// for (let i = 0; i < devisData.length; i += sliceSize) {
		// 	slices.push(devisData.slice(i, i + sliceSize));
		// }

		// return slices;
		const slices = devisData;

		return slices;
	}, [devisData]);

	const [page, setPage] = useState(0);

	const { t } = useTranslation();

	const [selectedItem, setSelectedItem] = useState<any>(null);

	const element = (
		<div className={cx('', styles.container)}>
			{/* controls */}
			<div className={cx('flexRow spaceBetween')} css={{ marginBottom: '16px' }}>
				<div>
					<div className={cx('', styles.text1)}>
						{t('Comparaison des devis')}
						&nbsp;&nbsp;
						<Unit measure={indicator === WATER_INDICATOR ? 'waterImpact' : 'carbonImpact'} />
					</div>

					<Button className={cx('', styles.btn1)} disableElevation disableRipple>
						Derniers modifiés <ChevronDown size={18} />
					</Button>
				</div>

				<div className="flexRow alignCenter">
					<ChevronLeft
						size={18}
						color={siteColors.grey700}
						css={{ cursor: 'pointer' }}
						onClick={() => {
							setPage((prev) => {
								if (prev === 0) return prev;
								return prev - 1;
							});
						}}
					/>
					<div className={cx(styles.textGraphPagination)}>
						{page + 1} {t('sur')} {devisSlices.length}
					</div>
					<ChevronRight
						size={18}
						color={siteColors.grey700}
						css={{ cursor: 'pointer' }}
						onClick={() => {
							setPage((prev) => {
								if (prev === devisSlices.length - 1) return prev;
								return prev + 1;
							});
						}}
					/>
					{/* <div>
			</div> */}
				</div>
			</div>

			{/* graph */}
			<div className={cx('', styles.graphContainer)} /* ref={svgRef as any} */>
				{(devisSlices[page] || []).map((e: any, index: number, arr: any) => {
					return (
						<BarItem
							key={index}
							label={e.name}
							value={e[indicator === WATER_INDICATOR ? 'estimatedImpactWater' : 'estimatedImpactCarbon']}
							percentValue={e.barChartPercentValueCarbon}
							sliceLength={arr.length}
							data={e}
							// onMouseEnter={onMouseEnter}
						/>
					);
				})}
			</div>
		</div>
	);

	return isTableLoading ? (
		// <div className="flexRow width100 bgGrey300--">
		<Skeleton sx={{ width: '100%', display: 'flex', maxWidth: 'unset' }}>{element}</Skeleton>
	) : (
		// </div>
		element
	);
};

export default QuoteGraph;

const barStyles = {
	container: css({
		width: '85px',
		// width: '8.5%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		height: '100%',

		position: 'relative',

		'&:hover .custom-tooltip': { display: 'block !important' },
	}),
	block: css({
		height: '90%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		// alignItems: 'flex-end',
	}),
	innerBlock: css({
		borderTop: cn('1px solid', `var(--indicator-color-a, ${siteColors.primary})`),
		background: `var(--indicator-color-b, ${siteColors.grey100})`,
	}),
	label: css({
		overflow: 'hidden',
		color: siteColors.grey900,
		textAlign: 'center',
		leadingTrim: 'both',
		textEdge: 'cap',
		fontVariantNumeric: 'lining-nums tabular-nums',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		fontFamily: 'Inter',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: 'normal',
	}),
};

type BarItemProps = {
	value?: number;
	label?: string;
	color?: string;
	percentValue?: number;
	sliceLength?: number;
	data?: any;
	barWidth?: number;
	// onMouseEnter?: (evt: any, data: any) => void;
};

const BarItem = ({
	value = 0,
	label = 'no label',
	/* color = siteColors.grey400, */ percentValue = 0,
	sliceLength = 1,
	data,
	barWidth,
}: // onMouseEnter,
BarItemProps) => {
	const { indicator } = useApp();

	return (
		<div
			className={cx('', barStyles.container)}
			style={
				{
					'--indicator-color-a': indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500,
					'--indicator-color-b': indicator === CARBON_INDICATOR ? siteColors.carbon100 : siteColors.water100,
					width: `calc(${100 / sliceLength}% - 10px)`,
					maxWidth: '85px',
				} as any
			}
		>
			<div className={cx('', barStyles.block)} /* ref={svgRef as any} */>
				<div className="width100" css={{ textAlign: 'center', color: 'var(--indicator-color-a)' }}>
					{displayValue(value, undefined, hasDecimal(value) ? 2 : 0)}
				</div>
				<div className={cx('', barStyles.innerBlock)} style={{ height: `calc(${percentValue}% - 8px)` }} />
			</div>
			<div className={cx('', barStyles.label)}>{label}</div>

			<Box
				className="custom-tooltip"
				/*  ref={tooltipRef} */
				sx={{ display: 'none', position: 'absolute', top: '30%', left: '50%', zIndex: 100 }}
			>
				<QuoteGraphToolTip data={data} />
			</Box>
		</div>
	);
};

const QuoteGraphToolTip = ({ data }: any) => {
	const { indicator } = useApp();
	const { t } = useTranslation();

	return (
		<div className={cx('flexColumn nowrap gap8', styles.tooltipContainer)}>
			<div
				css={{
					color: siteColors.text,
					leadingTrim: 'both',
					textEdge: 'cap',
					fontVariantNumeric: 'lining-nums tabular-nums',
					fontFamily: 'Inter',
					fontSize: '16px',
					fontStyle: 'normal',
					fontWeight: 600,
					lineHeight: 'normal',
					whiteSpace: 'nowrap',
				}}
			>
				{data?.name}
			</div>

			<div>{t('Impact total')}</div>
			<div
				css={{
					position: 'relative',
					top: '-10px',
				}}
			>
				<span
					css={{
						color: 'var(--indicator-color)',
						leadingTrim: 'both',
						textEdge: 'cap',
						fontVariantNumeric: 'lining-nums tabular-nums',
						fontFamily: 'Inter',
						fontSize: '14px',
						fontStyle: 'normal',
						fontWeight: 400,
						lineHeight: '21px',
					}}
					style={
						{
							'--indicator-color': indicator === WATER_INDICATOR ? siteColors.water500 : siteColors.carbon500,
						} as any
					}
				>
					{displayValue(
						data?.[indicator === WATER_INDICATOR ? 'estimatedImpactWater' : 'estimatedImpactCarbon'] || 0,
						undefined,
						0,
					)}
				</span>
				&nbsp;
				<Unit measure={indicator === WATER_INDICATOR ? 'waterImpact' : 'carbonImpact'} />
			</div>

			<ChipsStatus
				isSelect={false}
				row={{ status: data?.status }}
				callback={() => {
					/*  */
				}}
				t={t}
				statusDictionary={STATUS_QUOTE}
			/>
		</div>
	);
};
