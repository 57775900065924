import { css, Global } from '@emotion/react';
import type { Theme } from '@mui/material/styles/createTheme';
import { siteColors } from '../../../apps/front/src/lib/colors';

interface themeProps {
	theme: Theme;
}

const getGlobalStyles = (theme: Theme) => {
	return css`
		@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
		@import url('https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;500;700&display=swap');
		.center {
			align-items: center;
		}

		.flex {
			display: flex;
		}

		.textCenter {
			text-align: center;
		}

		.textLeft {
			text-align: left;
		}

		.errorColor {
			color: #ec554f;
		}

		.ellipsis {
			overflow: hidden;
			text-overflow: ellipsis;
			word-break: break-word;
		}

		.gridBorder {
			border-right: 1px solid #e2e2e2;
			border-bottom: 1px solid #e2e2e2;
		}

		.width100 {
			width: 100% !important;
		}

		.height100 {
			height: 100% !important;
		}

		.widthAuto {
			width: auto;
		}

		.borderNone {
			border: 0;
		}

		.pointer {
			cursor: pointer;
		}

		.overflowAuto {
			overflow: auto;
		}

		.positionRelative {
			position: relative;
		}

		.absolute {
			position: absolute;
		}

		.overParent {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.floatRight {
			float: right;
		}

		.hidden {
			opacity: 0;
		}

		.visible {
			opacity: 1;
		}

		.uppercase {
			text-transform: uppercase;
		}

		.transparent {
			background-color: transparent !important;
		}

		.transparent:hover {
			background-color: transparent !important;
			border: none;
			background-color: transparent !important;
		}

		.flexRow {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			flex-wrap: wrap;
		}
		.flexColumn {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			flex-wrap: nowrap;
		}
		.flexCenter {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: center;
		}
		.flexStretch {
			display: flex;
			flex-direction: column;
			align-items: stretch;
		}
		.stretch {
			align-items: stretch;
		}
		.alignBaseline {
			align-items: baseline;
		}
		.alignCenter {
			align-items: center;
		}
		.flexStart {
			align-items: flex-start;
		}
		.flexEnd {
			align-items: flex-end;
		}
		.spaceBetween,
		.justifyBetween {
			justify-content: space-between;
		}
		.spaceAround {
			justify-content: space-around;
		}
		.justifyStart {
			justify-content: flex-start !important;
		}
		.justifyEnd {
			justify-content: flex-end;
		}
		.justifyCenter {
			justify-content: center;
		}
		.stretchSelf {
			align-self: stretch;
		}
		.flexEndSelf {
			align-self: flex-end;
		}
		.centerSelf {
			align-self: center;
		}
		.textAlignCenter {
			text-align: center;
		}
		.textAlignLeft {
			text-align: left !important;
		}
		.textAlignRight {
			text-align: right !important;
		}
		.flex1 {
			flex: 1;
		}
		.mt-3 {
			margin-top: 1rem !important;
		}
		.mt-2 {
			margin-top: 0.5rem !important;
		}
		.whiteSpace {
			white-space: nowrap !important;
		}
		.wrap {
			flex-wrap: wrap;
		}
		.nowrap {
			flex-wrap: nowrap !important;
		}

		.shrink0 {
			flex-shrink: 0;
		}
		.shrink1 {
			flex-shrink: 1;
		}
		.fullWidth {
			width: 100%;
		}

		.flexItem {
			padding: 10px;
		}
		/* TOAST START*/

		.Toastify__toast {
			padding: 8px;
			align-items: center;
			border-radius: 0px 8px 8px 0px;
		}
		.Toastify__toast p {
			margin: 5px 0;
			font-family: 'Inter';
		}

		.Toastify__toast a {
			text-decoration: none;
			font-family: 'Inter';
			font-weight: 600;
			font-size: 16px;
		}

		.Toastify__toast.Toastify__toast--success {
			background: #f5f9ec;
			border: 1px solid ${siteColors.green500};
			box-shadow: inset 4px 0px 0px ${siteColors.green500};
		}

		.Toastify__toast.Toastify__toast--success .MuiSvgIcon-root {
			color: ${siteColors.green500};
		}

		.Toastify__toast.Toastify__toast--error {
			background: #ffe4cb;
			border: 1px solid #c74600;
			box-shadow: inset 4px 0px 0px #c74600;
		}
		.Toastify__toast.Toastify__toast--error .MuiSvgIcon-root {
			color: #c74600;
		}

		.Toastify__toast.Toastify__toast--info {
			background: #ffffff;
			border: 1px solid ${siteColors.primary};
			box-shadow: inset 4px 0px 0px ${siteColors.primary};
		}

		.Toastify__toast.Toastify__toast--info .MuiSvgIcon-root {
			color: ${siteColors.primary};
		}

		.circle-wrap {
			width: 186px;
			height: 186px;
			background: #fefcff;
			border-radius: 50%;
			border: 1px solid #cdcbd0;
		}

		.circle-wrap .circle .mask,
		.circle-wrap .circle .fill {
			width: 186px;
			height: 186px;
			position: absolute;
			border-radius: 50%;
		}

		.circle-wrap .circle .mask {
			clip: rect(0px, 186px, 186px, 75px);
		}

		.circle-wrap .inside-circle {
			width: 122px;
			height: 122px;
			border-radius: 50%;
			background: #d2eaf1;
			line-height: 120px;
			text-align: center;
			margin-top: 14px;
			margin-left: 14px;
			color: #1e51dc;
			position: absolute;
			z-index: 100;
			font-weight: 600;
			font-size: 2em;
		}

		/* color animation */

		/* 3rd progress bar */
		.mask .fill {
			clip: rect(0px, 75px, 186px, 0px);
			background-color: #227ded;
		}

		.mask.full,
		.circle .fill {
			animation: fill ease-in-out 3s;
			transform: rotate(135deg);
		}

		@keyframes fill {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(135deg);
			}
		}

		.Toastify__toast .title {
			font-weight: 600;
			font-size: 20px;
			color: ${siteColors.text};
		}
		.Toastify__toast .content {
			font-weight: 500;
			font-size: 16px;
			color: ${siteColors.text};
			margin-bottom: 15px;
			margin-top: 8px;
		}

		.Toastify__toast .footer {
			font-weight: 600;
			font-size: 16px;
			color: ${siteColors.primary};
		}
		.Toastify__toast .Toastify__toast-icon {
			display: none;
		}
		.Toastify__close-button {
			align-self: center;
			margin-right: 14px;
		}
		.not-closable .Toastify__close-button {
			display: none;
		}
		/* TOAST END */
		/* POPOVER */
		.MuiPopover-paper {
			border-radius: 8px !important;
		}
		/* POPOVER */

		.widthContainer {
			width: calc(100% - 64px);
		}

		.containerListWidth {
			/*width: calc(100% - 64px);*/
			position: relative;
			padding: 32px 32px;
		}

		.relative {
			position: relative;
		}
		.absolute {
			position: absolute;
		}

		.fixed {
			position: fixed !important;
		}
		.alignRight {
			text-align: right !important;
		}
		.mainColor {
			color: ${theme.palette.primary.main} !important;
		}

		.whiteColor {
			color: ${theme.palette.common.white} !important;
		}
		.black {
			color: ${theme.palette.common.black} !important;
		}
		.grey900 {
			color: ${theme.palette.grey[900]} !important;
		}
		.grey9 {
			color: ${siteColors.grey9} !important;
		}
		.grey7 {
			color: ${siteColors.grey7} !important;
		}
		.grey800 {
			color: ${theme.palette.grey[800]} !important;
		}
		.grey8 {
			color: ${siteColors.grey8} !important;
		}
		.grey700 {
			color: ${theme.palette.grey[700]} !important;
		}

		.bgGrey300 {
			background: ${theme.palette.grey[300]} !important;
		}
		.bgGrey400 {
			background: ${theme.palette.grey[400]} !important;
		}
		.bgGrey500 {
			background: ${theme.palette.grey[500]} !important;
		}
		.bgMain {
			background: ${theme.palette.primary.main} !important;
		}
		.bgSimulation {
			background: ${siteColors.teal10} !important;
		}
		.bgWhite {
			background: #fff !important;
		}

		.fontWeight500 {
			font-weight: 500 !important;
		}
		.fontWeight700 {
			font-weight: 700 !important;
		}
		.fontWeight400 {
			font-weight: 400 !important;
		}
		.fontWeight600 {
			font-weight: 600 !important;
		}
		.display-none {
			display: none !important;
		}
		.display-block {
			display: block !important;
		}
		.cursorPointer {
			cursor: pointer;
		}
		.italic {
			font-style: italic;
		}

		.borderGrey700 {
			border: 1px solid ${theme.palette.grey[700]} !important;
		}
		.borderGrey500 {
			border: 1px solid ${theme.palette.grey[500]} !important;
		}

		.borderBottomGrey500 {
			border-bottom: 1px solid ${theme.palette.grey[500]} !important;
		}

		.pointerEventsNone {
			pointer-events: none;
		}
		.pointerEventsAuto {
			pointer-events: auto;
		}
		.alignLeft {
			text-align: left !important;
		}
		.alignItemRight {
			align-items: right !important;
		}

		.noBorderRight {
			border-right: none !important;
		}
		.percent-progress {
			width: var(--width-box);
			background-color: var(--background-color-box);
			margin-left: var(--margin-left-box);
		}
		.gap4 {
			gap: 4px;
		}
		.gap6 {
			gap: 6px;
		}
		.gap8 {
			gap: 8px;
		}
		.gap10 {
			gap: 10px;
		}
		.gap12 {
			gap: 12px;
		}
		.gap16 {
			gap: 16px;
		}
		.gap18 {
			gap: 18px;
		}
		.gap22 {
			gap: 22px;
		}
		.gap20 {
			gap: 20px;
		}
		.gap24 {
			gap: 24px;
		}

		.gap32 {
			gap: 32px;
		}
		.gap48 {
			gap: 48px;
		}
		.gap40 {
			gap: 40px;
		}
		.gap56 {
			gap: 56px;
		}
		.backgroundWhite {
			background: ${theme.palette.common.white};
		}

		.textNoWrap {
			white-space: nowrap;
		}
		.lineHeight1 {
			line-height: 1 !important;
		}
		.capitalize {
			text-transform: capitalize;
		}
		.number {
			font-variant-numeric: lining-nums tabular-nums;
		}
		.lineHeight1 {
			line-height: 1 !important;
		}
		.underline {
			text-decoration: underline;
			text-underline-offset: 4px;
			text-decoration-style: dashed;
			text-decoration-color: ${siteColors.grey600};
			text-decoration-thickness: 1px;
		}
		.underline:hover {
			text-decoration-color: ${siteColors.grey800};
		}
		.height100 {
			height: 100%;
		}
		.gap7 {
			gap: 7px;
		}
		.fontSize16 {
			font-size: 16px;
		}
	`;
};

export const GlobalStyles = ({ theme }: themeProps) => {
	return <Global styles={getGlobalStyles(theme)} />;
};
