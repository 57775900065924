import { Fragment, ReactNode } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import type { TabsProps } from '@mui/material/Tabs';
import _ from 'lodash';

import { pxToRem } from '@carbonmaps/ui/utils/styles';

import { useTranslation } from '../../../hooks/useTranslation';
import { TOP_BAR_HEIGHT } from '../../../utils/constants';
import TabsBar, { TabItem } from '../../TabsBar';

import SheetPageHeader, { SheetPageHeaderProps } from './SheetPageHeader';

export type ITab = {
	title: string;
	element: ReactNode;
	disabled?: boolean;
	isCmapsModelization?: boolean;
};

type Props = {
	loading?: boolean;
	headerProps: SheetPageHeaderProps;
	currentTab?: string;
	tabs?: Record<string, ITab>;
	onTabChange?: TabsProps['onChange'];
	contentSingle?: ReactNode;
};

const CONTENT_WIDTH = 'calc(100% - 4rem)';
const CONTENT_MAX_WIDTH = '1200px';

const SheetPageLayout = ({ loading = false, headerProps, tabs, currentTab, onTabChange, contentSingle }: Props) => {
	const { t } = useTranslation();
	return (
		<>
			{/* Header */}
			{loading ? (
				<Skeleton
					variant="rectangular"
					height={pxToRem(150)}
					sx={{
						mb: pxToRem(16),
						borderRadius: '16px',
						width: CONTENT_WIDTH,
						maxWidth: CONTENT_MAX_WIDTH,
						mx: 'auto',
					}}
				/>
			) : (
				<SheetPageHeader
					{...headerProps}
					sx={_.merge(
						{
							width: CONTENT_WIDTH,
							maxWidth: CONTENT_MAX_WIDTH,
							mx: 'auto',
						},
						headerProps.sx ?? {},
					)}
				/>
			)}

			{/* Tabs bar */}
			{!contentSingle &&
				tabs &&
				(loading ? (
					<Skeleton
						variant="rectangular"
						height={pxToRem(62)}
						sx={{ borderRadius: pxToRem(16), width: CONTENT_WIDTH, mx: 'auto' }}
					/>
				) : (
					<TabsBar
						value={currentTab}
						onChange={onTabChange}
						sticky
						sx={{
							mb: pxToRem(50),
							width: CONTENT_WIDTH,
							maxWidth: CONTENT_MAX_WIDTH,
							mx: 'auto',
							top: TOP_BAR_HEIGHT,
							'&.sticky': { width: '100%', borderRadius: 0 },
						}}
					>
						{Object.entries(tabs).map(([tabName, { title, disabled }]) => {
							return (
								<TabItem
									key={tabName}
									label={t(title, { ingredients: t('ingredients') })}
									value={tabName}
									disabled={disabled}
								/>
							);
						})}
					</TabsBar>
				))}

			{/* Tab content */}
			{!contentSingle && tabs ? (
				<Box width={CONTENT_WIDTH} maxWidth={CONTENT_MAX_WIDTH} mx="auto">
					{Object.entries(tabs).map(([tabName, { element }]) => {
						return !loading && tabName === currentTab && <Fragment key={tabName}>{element}</Fragment>;
					})}
				</Box>
			) : (
				<Box width={CONTENT_WIDTH} mx="auto">
					{contentSingle}
				</Box>
			)}
		</>
	);
};

export default SheetPageLayout;
