import { useCallback, useMemo } from 'react';

import { css } from '@emotion/css';
import Typography from '@mui/material/Typography';
import { ChevronDown } from 'lucide-react';

import { ALL_YEAR_OPTION_VALUE } from '@carbonmaps/shared/utils/constants';
import { useImpactYear, useYearSelection } from '../../hooks/useImpactYear';
import { useJuneTrack } from '../../hooks/useJuneTrack';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';
import SelectField from '../form/fields/SelectField';

const styles = {
	container: css({
		minWidth: '100px',
		minHeight: '42px',
		backgroundColor: 'rgb(236, 233, 242) !important',
		'.MuiInputBase-root': {
			width: '150px',
			minHeight: '42px',
			backgroundColor: 'rgb(236, 233, 242) !important',
		},
	}),
	text: css({
		fontSize: '16px !important',
		fontWeight: '500 !important',
		paddingRight: 10,
	}),
};

const SelectYear = ({ disableAllYear }: { disableAllYear?: boolean }) => {
	const { t } = useTranslation();
	// fetch data
	const { data, isLoading, isSuccess, isError, error } = useImpactYear();
	const { selectedYear, setSelectedYear } = useYearSelection();
	const currentYear = new Date();

	const {
		result: { data: dataOptions },
	} = useGetClientAuthQuery();

	const years_options = useMemo(() => {
		if (!dataOptions) return [];

		const yearOptions = (dataOptions as any)?.yearOptions;

		if (!yearOptions?.length) {
			setSelectedYear(null);
			return [];
		}

		// set current year for the last year
		setSelectedYear(Math.max(...yearOptions));
		return yearOptions;
	}, [dataOptions, setSelectedYear]);

	const analytics = useJuneTrack();

	const trackEvent = useCallback(
		(name: string, options?: any) => {
			if (!analytics) return;
			analytics.track(name, options, {
				context: { category: 'Show all years' },
			});
		},
		[analytics],
	);

	// Show all years

	if (isSuccess && data?.length > 0) {
		return (
			<SelectField
				value={selectedYear || (currentYear.getFullYear() as any)}
				items={[
					...data?.map((item: any) => {
						return {
							...item,
							label: item?.toString(),
							value: item,
						};
					}),
					...(!disableAllYear ? [{ label: t('toutes les années'), value: ALL_YEAR_OPTION_VALUE }] : []),
				]}
				renderValue={(item: any) => {
					return <Typography className={styles.text}>{item?.label}</Typography>;
				}}
				onChange={(e: any) => {
					setSelectedYear(e);

					if (e === ALL_YEAR_OPTION_VALUE) {
						trackEvent('Show all years');
					}
				}}
				className={styles.container}
				paddingRight={24}
				ExpandMoreRoundedIcon={ChevronDown}
				iconColor={siteColors.grey700}
			/>
		);
	}

	return;
};

export default SelectYear;
