import { ReactComponent as CarboneImpactIcon } from '@carbonmaps/media/icons/carbon-impact.svg';
import { ReactComponent as IntensityIcon } from '@carbonmaps/media/icons/carbon-intensity.svg';
import { pxToRem } from '@carbonmaps/ui/utils/styles';

export const ACCUMULATOR_NUMBER_FACET = 25;


export const TOP_BAR_HEIGHT = pxToRem(58);
export const SIDEBAR_WIDTH = pxToRem(280);

// ----------------------------------------------------------- //
// ------------------- view mode options --------------------- //
// ----------------------------------------------------------- //

export const CARBONE_IMPACT_VIEW = 'carbonImpact';
export const INTENSITY_VIEW = 'intensity';
export const WATER_IMPACT_VIEW = 'waterImpact';

export const MODE_VIEW_OPTIONS = [
	{
		label: 'Impact total',
		extraLabel: 'Données ramenées au % du portefeuille',
		value: CARBONE_IMPACT_VIEW,
		icon: CarboneImpactIcon,
	},
	{
		label: 'Intensité',
		extraLabel: 'Données ramenées au kilo de produit',
		value: INTENSITY_VIEW,
		icon: IntensityIcon,
	},
];

export const CARBON_INDICATOR = 'carbon';
export const WATER_INDICATOR = 'water';

export const MAX_NUMBER_OF_PRODUCTS_TO_DISPLAY = 10_000;
export const MAX_NUMBER_OF_INGREDIENTS_TO_DISPLAY = 10_000;
export const MAX_NUMBER_OF_PACKAGING_TO_DISPLAY = 10_000;
export const MAX_NUMBER_OF_SUPPLIERS_TO_DISPLAY = 10_000;



let m_fr = 'Cher(e) directeur / directrice RSE \n';
m_fr += 'Nous espérons que ce message vous trouve bien. Je me permets de vous contacter car vous êtes fournisseur de notre organisation, et que nous lançons une photographie de l’état d’engagement RSE de nos fournisseurs.\n';
m_fr += 'Merci de compléter les informations requises d’ici au [VOTRE DATE].\n';
m_fr += 'Cordialement, [VOTRE NOM],\n';
m_fr += '[VOTRE POSTE]';

let m_en = 'Dear CSR Director, \n';
m_en += 'We hope this message finds you well. I am reaching out to you as a supplier to our organization because we are conducting an assessment of the CSR engagement status of our suppliers. Please complete the required information by [YOUR DATE].\n';
m_en += 'Best regards,  \n';
m_en += '[YOUR NAME]  \n';
m_en += '[YOUR POSITION]';

let m_it = 'Gentile Direttore/Direttrice CSR, \n';
m_it += 'Speriamo che questo messaggio vi trovi bene. Mi permetto di contattarvi in quanto fornitore della nostra organizzazione, poiché stiamo conducendo una valutazione dello stato di impegno CSR dei nostri fornitori. Vi preghiamo di completare le informazioni richieste entro il [LA VOSTRA DATA].\n';
m_it += 'Cordiali saluti,  \n';
m_it += '[IL VOSTRO NOME]  \n';
m_it += '[LA VOSTRA POSIZIONE]';

let m_es = 'Estimado/a Director/a de RSE: \n';
m_es += 'Esperamos que este mensaje le encuentre bien. Me pongo en contacto con usted en calidad de proveedor de nuestra organización, ya que estamos realizando una evaluación del estado de compromiso de RSE de nuestros proveedores. Le agradeceríamos que completara la información requerida antes del [SU FECHA].\n';
m_es += 'Atentamente,  \n';
m_es += '[SU NOMBRE]  \n';
m_es += '[SU CARGO]';

interface LangOption {
	lang: string;
	value: string;
	label: string;
	forbiddenWords: Array<string>;
}

export const DEFAULT_CUSTOM_MESSAGE_OPTIONS: Record<string, LangOption> = {
	fr: {
		lang: 'fr',
		value: m_fr,
		label: 'Français',
		forbiddenWords: ['[VOTRE DATE]', '[VOTRE NOM]', '[VOTRE POSTE]']
	},
	en:
	{
		lang: 'en',
		value: m_en,
		label: 'Anglais',
		forbiddenWords: ['[YOUR DATE]', '[YOUR NAME]', '[YOUR POSITION]']
	},
	it:
	{
		lang: 'it',
		value: m_it,
		label: 'Italien',
		forbiddenWords: ['[SU FECHA]', '[SU NOMBRE]', '[SU CARGO]']
	},
	es:
	{
		lang: 'es',
		value: m_es,
		label: 'Espagnol',
		forbiddenWords: ['[LA VOSTRA DATA]', '[IL VOSTRO NOME]', '[LA VOSTRA POSIZIONE]']
	},

}


