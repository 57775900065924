import { END_OF_LIFE_COLORS } from '@carbonmaps/shared/utils/constants';
import { siteColors } from '../lib/colors';

export const getRecycledArray = (value: number) => {
	return [
		{
			label: 'recycled',
			value,
			color: siteColors.green500,
		},
		{
			label: 'other-recycled',
			value: 100 - value,
			color: siteColors.orange500,
		},
	];
};

export const manageEndOfLife = (items: Array<any>) => {
	return items?.map((item: any, index: number) => {
		return {
			...item,
			value: item?.composition,
			color: END_OF_LIFE_COLORS[item?.type as any],
		};
	});
};
