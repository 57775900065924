import { css } from '@emotion/css';
import { Cloudy, Droplets } from 'lucide-react';

import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { CARBON_INDICATOR } from '@carbonmaps/ui/utils/constants';

import Unit from '../../../components/units/Unit';
import { useYearSelection } from '../../../hooks/useImpactYear';
import { useTranslation } from '../../../hooks/useTranslation';
import { siteColors } from '../../../lib/colors';

import { ALL_YEAR_OPTION_VALUE } from '@carbonmaps/shared/utils/constants';
import KPICard from './KPICard';
import KpiValue from './KpiValue';

const styles = {
	impactValue: css({
		color: 'var(--color)',
	}),
	impactUnit: css({ paddingLeft: 2 }),
};

type ImpactKpiProps = {
	isLoading: boolean;
	data: any;
	withHelp?: boolean;
};

const ImpactKpi = ({ isLoading, data, withHelp = false }: ImpactKpiProps) => {
	// ---- define hooks ---- //
	const { indicator } = useApp();
	const { selectedYear } = useYearSelection();
	const { t } = useTranslation();

	return (
		<KPICard
			className="flex1"
			icon={
				indicator === 'carbon' ? (
					<Cloudy size={30} color={siteColors.carbon500} />
				) : (
					<Droplets size={30} color={siteColors.water500} />
				)
			}
			isLoading={isLoading}
			value={
				<KpiValue
					value={data && data[`${indicator}Impact`]}
					withHelp={withHelp}
					unit={
						<Unit
							measure={indicator === CARBON_INDICATOR ? 'carbonImpactTon' : 'waterImpact'}
							color={siteColors.grey7}
							className={styles.impactUnit}
						/>
					}
					className={styles.impactValue}
					style={{ ['--color' as any]: indicator === CARBON_INDICATOR ? siteColors.carbon500 : siteColors.water500 }}
				/>
			}
			label={
				selectedYear !== ALL_YEAR_OPTION_VALUE ? t('impact_period', { period: selectedYear }) : t('impact_all_year')
			}
		/>
	);
};

export default ImpactKpi;
