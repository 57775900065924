import { useQuery } from '@tanstack/react-query';

import { functionName } from '@carbonmaps/shared/utils/constants';
import { useApp } from '@carbonmaps/ui/hooks/useApp';

import { useYearSelection } from '../../../../hooks/useImpactYear';

export const useFindSuppliersTable = ({ paramsTable }: { paramsTable: any }) => {
	const { selectedYear } = useYearSelection();
	const { indicator } = useApp();
	const key = ['findSupplierTable', {
		isTable: true, ...paramsTable,
		selectedYear: paramsTable?.selectedYear ?? selectedYear,
		viewMode: indicator
	}] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: async (context) => {
			const params = context.queryKey[1];


			if (!selectedYear) {
				const result = await Parse.Cloud.run('findSupplier', {
					view: 'general-suppliers-table',
					...params,
					skip: params?.page,
					limit: params?.size,
				});

				return {
					suppliers: result?.data,
					meta: {
						count: result?.count,
						last_page: Math.ceil(result?.count / (params.size || 100)),
					},
				};
			}

			return Parse.Cloud.run(functionName.temporality.findSupplier, {
				view: 'general-suppliers-table',
				...params,
				limit: params?.size,
				skip: (params?.page - 1) * params?.size,
			});
		},
	});

	return { result, key };
};

export const useFindSuppliersTradeoff = ({ params }: { params: any }) => {
	const { selectedYear } = useYearSelection();
	const key = ['findSupplierTradeOff', { ...params, selectedYear }] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: async (context) => {
			const params = context.queryKey[1];

			return Parse.Cloud.run(functionName.temporality.findSupplier, { view: 'general-suppliers-tradeoff', ...params });
		},
	});

	return { result, key };
};




export const useFindSuppliersListOptions = ({ paramsTable }: { paramsTable: any }) => {
	const { selectedYear } = useYearSelection();
	const { indicator } = useApp();
	const key = ['findSuppliersListOptions', { isTable: true, ...paramsTable, selectedYear }] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: async (context) => {
			const params = context.queryKey[1];

			return Parse.Cloud.run(functionName.temporality.findSuppliersListOptions, {
				...params,
				limit: params?.size,
				skip: (params?.page - 1) * params?.size,
			});
		},
	});

	return { result, key };
};



export const useFindUserSuppliers = ({ userId, companyId }: { userId: string, companyId: string }) => {
	const { selectedYear } = useYearSelection();
	const key = ['findUserSuppliers', { userId, companyId, period: selectedYear }] as const;

	const result = useQuery({
		queryKey: key,
		queryFn: async (context) => {
			const params = context.queryKey[1];

			return Parse.Cloud.run(functionName.temporality.findUserSuppliers, {
				...params,
			});
		},
	});

	return { result, key };
};
