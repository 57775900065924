import { Document, Font } from '@react-pdf/renderer';
import QuoteModel from '../../../../models/Quote.model';
import FacetsPage from './FacetsPage';
import GroupPage from './GroupPage';
import SummaryPage from './SummaryPage';
import { useQuote } from './useQuote';

Font.register({
	family: 'Inter',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf',
			fontWeight: 100,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf',
			fontWeight: 200,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf',
			fontWeight: 300,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
			fontWeight: 400,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf',
			fontWeight: 500,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf',
			fontWeight: 600,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
			fontWeight: 600,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf',
			fontWeight: 800,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf',
			fontWeight: 900,
		},
	],
});

export type PDFDocumentProps = {
	quote: QuoteModel | undefined;
	companyLogo: string | undefined;
	form: any | undefined;
	indicator?: string;
	t: any;
	companyCode: any;
	facetsConfig: any;
	preferredFacet: any;
};

export const PDFDocument = (props: PDFDocumentProps) => {
	const returnValue = useQuote(props);

	return (
		<Document>
			<SummaryPage {...returnValue} />
			<FacetsPage {...returnValue} />
			<GroupPage {...returnValue} />
		</Document>
	);
};
