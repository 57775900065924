import { ComponentProps, ReactNode, /* ElementRef,  */ useEffect, useRef, useState /* useLayoutEffect */ } from 'react';

import { css, cx } from '@emotion/css';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import useTheme from '@mui/material/styles/useTheme';
import { minHeight, type SxProps } from '@mui/system';
import _ from 'lodash';

import useSticky from '../hooks/useSticky';

import { HelpCircle, Lock } from 'lucide-react';
import { useTranslation } from '../hooks/useTranslation';
import { siteColors } from '../lib/colors';
import LightTooltipGlossary from './LightTooltipGlossary';

type Props = {
	children: ReactNode;
	value: any;
	onChange: ComponentProps<typeof Tabs>['onChange'];
	sticky?: boolean;
	offset?: number;
	sx?: SxProps;
	className?: string;
	withLock?: boolean;
};

const TabsBar = ({ children, onChange, value, sticky, className, sx = {} }: Props) => {
	const theme = useTheme();
	const tabsBarRef = useRef<any>(null);
	const [offsetY, setOffsetY] = useState<number>(0);
	const isSTicky = useSticky({ offset: offsetY });

	useEffect(() => {
		setOffsetY(tabsBarRef.current?.getBoundingClientRect().y || 0);
	}, []);

	return (
		<Tabs
			ref={tabsBarRef}
			value={value}
			onChange={onChange}
			className={cx(className, isSTicky ? 'sticky' : '')}
			sx={_.merge(
				{
					backgroundColor: theme.palette.grey['300'],
					borderRadius: '16px',
					transition: 'all .05s ease-in-out',
					zIndex: 100,
					'& .MuiTabs-indicator': {
						display: 'none',
					},
					'& .MuiTabs-flexContainer': {
						gap: '4px',
						padding: '6px',
					},
					...(sticky
						? {
								position: 'sticky',
								top: 0,
						  }
						: {}),
					// ...sx,
				},
				sx,
			)}
		>
			{children}
		</Tabs>
	);
};

export default TabsBar;

export type TabItemProps = {
	glossary?: string;
	g?: any;
	withLock?: boolean;
	isCmapsModelization?: boolean;
} & ComponentProps<typeof Tab>;

const tabItemStyle = {
	container: css({
		'&.Mui-disabled': {
			pointerEvents: 'auto',
			cursor: 'pointer !important',
		},
	}),
};
export const TabItem = ({
	label,
	value,
	glossary,
	g,
	withLock = false,
	isCmapsModelization,
	...props
}: TabItemProps) => {
	const theme = useTheme();

	const { t } = useTranslation();

	const sx = {
		borderRadius: '8px',
		padding: '12px 20px',
		color: theme.palette.grey[700],
		textTransform: 'unset',
		fontWeight: '600',
		'&.Mui-selected': {
			boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(93, 17, 230, 0.08)',
			color: theme.palette.primary.main,
			bgcolor: 'white',
			'&:hover': {
				bgcolor: 'white',
			},
		},
		'&:hover': {
			bgcolor: theme.palette.grey[400],
		},
		'&:active': {
			bgcolor: theme.palette.grey[500],
			color: theme.palette.grey[900],
		},
		'&.Mui-disabled': {
			pointerEvents: 'auto',
			cursor: 'not-allowed',
		},
	};

	if (withLock && !isCmapsModelization) {
		return (
			<LightTooltipGlossary title={t('Vous devez passer à la nouvelle version pour avoir cette information')}>
				<Tab
					{...props}
					disableRipple
					label={
						<div className="flexRow alignCenter gap4">
							{label} <Lock color={siteColors.grey7} size={16} />
						</div>
					}
					value={value}
					className={cx('underline', tabItemStyle.container)}
					sx={sx}
					disabled={true}
				/>
			</LightTooltipGlossary>
		);
	}

	if (glossary && g) {
		return (
			<LightTooltipGlossary title={g(glossary)}>
				<Tab {...props} disableRipple label={label} value={value} className="underline" sx={sx} />
			</LightTooltipGlossary>
		);
	}

	return <Tab {...props} disableRipple label={label} value={value} sx={sx} />;
};
