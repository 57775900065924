import { css } from '@emotion/css';
import useTheme from '@mui/material/styles/useTheme';
import { ClipboardCheck } from 'lucide-react';

import IconContainer from '../../components/layout/list/IconContainer';
import ListHeader from '../../components/layout/list/ListHeader';
import { useTranslation } from '../../hooks/useTranslation';
import { cn, siteColors } from '../../lib/colors';
type AuditHeaderProps = {
	title?: string;
	subtitle?: string;
};

const default_title = 'Jeu de données';

const bg = css({
	background: cn(siteColors.blueGray10, '!important'),
});

const AuditHeader = ({ title = default_title, subtitle }: AuditHeaderProps) => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<ListHeader
			title={t(title)}
			subtitle={subtitle}
			icon={
				<IconContainer
					element={<ClipboardCheck size={32} color={theme.palette.common.white} />}
					color={siteColors.blueGray500}
				/>
			}
			// className={bg}
		/>
	);
};

export default AuditHeader;
