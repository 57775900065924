import { useEffect, useMemo, useRef, useState } from 'react';

import type { Theme } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { Cloud, Cloudy, Droplet, Droplets, ShoppingCart } from 'lucide-react';
import ReactDOMServer from 'react-dom/server';
import { useNavigate } from 'react-router-dom';

import {
	ALL_YEAR_OPTION_VALUE,
	classNames,
	ITypeModelizationIngredientKey,
	typeNames,
	WORDING_TERMS,
} from '@carbonmaps/shared/utils/constants';
import { getIngredientCount } from '@carbonmaps/ui/actions/ingredient.actions';
import { StaticContent, StaticContentLoading } from '@carbonmaps/ui/components/saas/charts/ScatterChart';
import { useApp } from '@carbonmaps/ui/hooks/useApp';
import { toLowerCase } from '@carbonmaps/ui/utils/utils';

import { WATER_INDICATOR } from '@carbonmaps/ui/utils/constants';
import TradeOffChart from '../../components/TradeOffChart';
import Unit from '../../components/units/Unit';
import { useYearSelection } from '../../hooks/useImpactYear';
import { useSkeleton } from '../../hooks/useSkeleton';
import { useTranslation } from '../../hooks/useTranslation';
import { siteColors } from '../../lib/colors';
import { useGetClientAuthQuery } from '../../lib/react-query/features/auth/auth.hooks';
import { useFindIngredientFamilyTradeoff } from '../../lib/react-query/features/ingredient/ingredient.hooks';
import { CARBON_INDICATOR } from '../../utils/constants';

interface IngredientChartProps {
	filterOptions?: any;
}

const classes = {
	text: {
		color: siteColors.primary,
		fontWeight: 600,
	},
};

const formatDataGraph = (data: any, useValue: any, switchValue: any, theme: any, t: any) => {
	const finalArray = (data || [])?.map((ingredient: any, index: number) => {
		return {
			...ingredient,
			y: (ingredient as any)[useValue.y.value] || 0,
			x: (ingredient as any)[useValue.x.value] || 0,
			//name: ingredient.labelCmaps,
			value: (ingredient as any)[useValue.x.value],
			[useValue.x.value]: (ingredient as any)[useValue.x.value] || 0,
			[useValue.y.value]: (ingredient as any)[useValue.y.value] || 0,
			key: ingredient.originalId,
			path: 'ingredients',
			color: switchValue === WATER_INDICATOR ? siteColors.water500 : siteColors.carbon500,
		};
	});

	return finalArray;
};

const getIndicators = (theme: Theme, t: any, period: number) => {
	return [
		{
			label: t(WORDING_TERMS.INTENSITY),
			value: 'carbonIntensity',
			unity: <Unit measure="carbonIntensityKg" color={theme.palette.grey[700]} fontWeight={500} />,
			precision: 2,
			popoverKeys: [
				{
					key: 'carbonImpact',
					label:
						period === ALL_YEAR_OPTION_VALUE ? t(WORDING_TERMS.IMPACT_ALL_YEAR) : t(WORDING_TERMS.IMPACT, { period }),
					icon: <Cloudy color={theme.palette.primary.main} />,
					unit: <Unit measure="carbonImpact" color={theme.palette.grey[700]} fontWeight={500} />,
					style: classes.text,
					precision: 0,
				},
				{
					key: 'carbonIntensity',
					label: t(WORDING_TERMS.INTENSITY),
					icon: <Cloud color={theme.palette.primary.main} />,
					unit: <Unit measure="carbonIntensityKg" color={theme.palette.grey[700]} fontWeight={500} />,
					style: classes.text,
					precision: 2,
				},
			],
		},
		{
			label: t('water'),
			value: 'waterIntensity',
			unity: <Unit measure="waterIntensity" color={theme.palette.grey[700]} fontWeight={500} />,
			precision: 2,
			popoverKeys: [
				{
					key: 'waterImpact',
					label:
						period === ALL_YEAR_OPTION_VALUE ? t(WORDING_TERMS.IMPACT_ALL_YEAR) : t(WORDING_TERMS.IMPACT, { period }),
					icon: <Droplets color={siteColors.water500} />,
					unit: <Unit measure="waterImpact" color={theme.palette.grey[700]} fontWeight={500} />,
					style: { ...classes.text, color: siteColors.water500 },
					precision: 0,
				},
				{
					key: 'waterIntensity',
					label: t(WORDING_TERMS.INTENSITY),
					icon: <Droplet color={siteColors.water500} />,
					unit: <Unit measure="waterIntensity" color={theme.palette.grey[700]} fontWeight={500} />,
					style: { ...classes.text, color: siteColors.water500 },
					precision: 2,
				},
			],
		},
		{
			label: t('volume'),
			value: 'tonnage',
			precision: 0,
			unity: <Unit measure="massTon" color={theme.palette.grey[700]} fontWeight={500} />,
			popoverKeys: [
				{
					key: 'tonnage',
					label: t('volume'),
					icon: <ShoppingCart />,
					unit: <Unit measure="massTon" color={theme.palette.grey[700]} fontWeight={500} />,
					precision: 0,
				},
			],
		},
	];
};

const IngredientTradeOff = ({ filterOptions }: IngredientChartProps) => {
	const { selectedYear } = useYearSelection();
	const {
		result: { data: authData },
	} = useGetClientAuthQuery();

	// translation
	const { t } = useTranslation();

	// ---- all ingredients for a company ---- //
	const theme = useTheme();
	const { indicator } = useApp();

	const indicators = useMemo(() => {
		return getIndicators(theme, t, selectedYear);
	}, [theme, t]);

	// ---- value graph by indicator ---- //
	const useValue = useMemo(() => {
		if (indicator === CARBON_INDICATOR) {
			return {
				x: indicators[2],
				y: indicators[0],
			};
		} else {
			return {
				x: indicators[2],
				y: indicators[1],
			};
		}
	}, [indicators, indicator]);

	// ---- fetch data ---- //
	// use this count will appear in graph legend
	const { data: ingredientCount } = useQuery({
		queryKey: ['getIngredientCount', { period: selectedYear }],
		queryFn: getIngredientCount,
	});

	const {
		result: { data, isLoading },
	} = useFindIngredientFamilyTradeoff({
		params: {
			input: filterOptions.input,
			facetFilters: filterOptions.facetFilters,
			categoryFilters: filterOptions.categoryFilters,
			supplierIds: filterOptions.supplierIds,
			viewMode: indicator,
		},
	});

	const navigate = useNavigate();
	const { skeleton } = useSkeleton({ condition: isLoading });

	const countRef = useRef(0);
	const tooltipFormatter: any = _.debounce(function (this: any, anotherTooltip: any) {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const tooltip = this;
		// eslint-disable-next-line prefer-const, no-var
		var ret = ReactDOMServer.renderToString(
			<StaticContentLoading
				t={t}
				/* tooltip={tooltip} */ xAxis={useValue.x}
				yAxis={useValue.y}
				withTypeModelization={false}
			/>,
		);
		countRef.current = countRef.current + 1;

		findOneScatterPointData({
			codeCmaps: tooltip.point.codeCmaps,
			period: selectedYear,
			count: countRef.current,
			update: authData?.currentUpdate,
			company: authData?.session?.company,
		}).then((joinedResult: Record<string, any> | undefined) => {
			console.log(joinedResult, 'joinedResult');

			if (countRef.current === joinedResult?.count) {
				tooltip.point.labelTypeModelizationIngredient = t(
					(joinedResult?.typeModelizationIngredient?.toLowerCase() || '') as ITypeModelizationIngredientKey,
					{ recipe: t('recipe'), recipe_lowercase: toLowerCase(t('recipe')) },
				);
				tooltip.point.name = joinedResult?.label;
				tooltip.point.uid = joinedResult?.codeCmaps;
				tooltip.point.carbonImpact = joinedResult?.carbonImpact;
				tooltip.point.waterImpact = joinedResult?.waterImpact;
				tooltip.point.isChecked = joinedResult?.tagAdvancedModelization === 'yes';
				anotherTooltip.label.attr({
					text: ReactDOMServer.renderToString(
						<StaticContent t={t} tooltip={tooltip} xAxis={useValue.x} yAxis={useValue.y} />,
					),
				});
			}
		});

		return ret;
	}, 500);

	return (
		<TradeOffChart
			datas={(data ? { data: formatDataGraph(data || [], useValue, indicator, theme, t) } : {}) as any}
			displayLine={`${data?.length || 0} ${t('sur')} ${ingredientCount || 0} ${t('reference_ingredients', {
				ingredients: t('ingredients'),
			})}`}
			indicators={indicators}
			useValue={useValue}
			loading={isLoading}
			skeleton={skeleton}
			toggleImpact={false}
			hcOptions={{
				tooltip: {
					formatter: tooltipFormatter,
				},
				plotOptions: {
					scatter: {
						events: {
							click: function (event: any) {
								// console.log('AAAAAAAAAAAAAA', this);
								if (event.point.path) {
									navigate(
										`/${event.point.path}/${event.point.key}?codeIngCmaps=${event.point?._id || event.point?.objectId}`,
									);
								}
							},
						},
					},
				},
			}}
		/>
	);
};

export default IngredientTradeOff;

// =============================

const findOneScatterPointData = async ({
	codeCmaps,
	period,
	update,
	count,
	company,
}: {
	codeCmaps?: string;
	period?: number;
	update?: Parse.Object;
	count?: number;
	company?: { code?: string };
}) => {
	const ingredientCollectionName = `${classNames.INGREDIENT}_${company?.code}`;
	const ingredientQuery = new Parse.Query(ingredientCollectionName)
		.equalTo('codeIngCmaps', codeCmaps)
		.containedIn('impactYear', [period])
		.equalTo('update', update)
		.exclude(['objectId', 'createdAt', 'updatedAt'] as never)
		.select(['labelCmaps']);

	const impactQuery = new Parse.Query(classNames.IMPACT_YEAR)
		.equalTo('codeCmaps', codeCmaps)
		.equalTo('year', period)
		.equalTo('type', typeNames.INGREDIENT)
		.equalTo('update', update)
		.exclude(['objectId', 'createdAt', 'updatedAt'] as never)
		.select(['carbonImpact', 'waterImpact', 'carbonIntensity', 'waterIntensity']);

	const promise1 = ingredientQuery.first({ json: true });
	const promise2 = impactQuery.find({ json: true });

	const [ingredient, impact]: [any, any] = await Promise.all([promise1, promise2]);
	return {
		label: ingredient?.labelCmaps,
		count,
		codeCmaps,
		carbonImpact: _.sumBy(impact, 'carbonImpact') * 1000, // en kg
		waterImpact: _.sumBy(impact, 'waterImpact'),
		carbonIntensity: _.meanBy(impact, 'carbonIntensity'),
		waterIntensity: _.meanBy(impact, 'waterIntensity'),
	};
};
